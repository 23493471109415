import {ModuleWithProviders, NgModule} from '@angular/core';
import {sharedServices} from '../services/services';
import {EloquentManagerService} from '../eloquent/providers/eloquent-manager.provider';
import {Gatekeeper} from '../guards/gatekeeper.guard';
import {PaginationModule} from '../components/pagination/pagination.module';
import {SharedImportsModule} from './shared-imports.module';
import {LogHistoryModule} from '../components/log-history/log-history.module';
import {DynamicComponentModule} from '../components/dynamic-component/dynamic-component.module';
import {UploaderModule} from '../components/uploader/uploader.module';
import {ShipmentHistoryModule} from '../components/shipment-history/shipment-history.module';
import {ConfirmationModule} from '../components/confirmation/confirmation.module';
import {ToastModule} from '../services/toast/toast.module';
import {PurchaseOrderModule} from '../components/purchase-order/purchase-order.module';
import {HolosenPdfViewerModule} from '../components/holosen-pdf-viewer/holosen-pdf-viewer.module';
import {QualityControlModule} from '../components/quality-control/quality-control.module';
import {QualityControlConfigModule} from '../components/quality-control-config/quality-control-config.module';
// import {SocketIoConfig, SocketIoModule} from 'ng-socket-io';
import {StartStopLogHistoryModule} from '../components/start-stop-log-history/start-stop-log-history.module';


// const socketIoConfig: SocketIoConfig = {url: 'http://api.planbu.bo-tek.com.tr:6001', options: {}};

@NgModule({
    declarations: [],
    imports: [
        // SocketIoModule.forRoot(socketIoConfig)
    ],
    exports: [
        SharedImportsModule,
        PaginationModule,
        DynamicComponentModule,
        LogHistoryModule,
        ShipmentHistoryModule,
        ConfirmationModule,
        UploaderModule,
        ToastModule,
        PurchaseOrderModule,
        HolosenPdfViewerModule,
        QualityControlModule,
        QualityControlConfigModule,
        StartStopLogHistoryModule,
        // SocketIoModule
    ],
    providers: []
})
export class SharedModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [
                Gatekeeper,
                EloquentManagerService,
                ...sharedServices,
            ]
        };
    }
}



