import {NgModule} from '@angular/core';
import {WorkOrderComponent} from './work-order.component';
import {SharedModule} from '../../../shared/modules/shared.module';
import {WorkOrderOperationComponent} from '../work-order-operation/work-order-operation.component';
import {PurchaseOrderComponent} from '../../../shared/components/purchase-order/purchase-order.component';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [WorkOrderComponent],
    exports: [WorkOrderComponent],
    // entryComponents: [
    //     WorkOrderOperationComponent,
    //     PurchaseOrderComponent
    // ]
})
export class WorkOrderModule {
}

