<div class="login-container">
    <div class="form-container">
        <div class="ui card">
            <div class="content">
                <div class="header">Giriş Yap</div>
            </div>
            <div class="content">
                <form class="ui form" (ngSubmit)='login($event)'>

                    <div class="field">
                        <div class="ui left icon input">
                            <i class="user icon"></i>
                            <input placeholder="Kullanıcı Adı" [(ngModel)]="username" [ngModelOptions]="{standalone: true}">
                        </div>
                    </div>
                    <div class="field">
                        <div class="ui left icon input">
                            <i class="lock icon"></i>
                            <input type="password" placeholder="Şifre" [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
                        </div>
                    </div>
                    <button type="submit" class="ui fluid large submit button">Giriş</button>


                    <div class="ui error message"></div>
                </form>
            </div>
        </div>
    </div>
</div>