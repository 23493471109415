import {BaseModel} from './base.model';
import {EloquentSerializable} from '../interfaces/interfaces';


export class WorkOrderStatus extends BaseModel implements EloquentSerializable<WorkOrderStatus> {


    public static readonly NEW = 'NEW';
    public static readonly PLANNED = 'PLANNED';
    public static readonly ONGOING = 'ONGOING';
    public static readonly COMPLETED = 'COMPLETED';
    public static readonly CANCELLED = 'CANCELLED';
    public static readonly READY_TO_SHIPMENT = 'READY_TO_SHIPMENT';

    public static readonly API_PREFIX: string = 'work-order-statuses';
    public id: number = null;
    public name = '';
    public description = '';
    public color = '';
    public seq_num: number = null;
    public slug = WorkOrderStatus.NEW;

    public validation: Array<any>;

    public getApiPrefix(): string {
        return WorkOrderStatus.API_PREFIX;
    }


    public deserialize(input: Object): WorkOrderStatus {
        this.id = input['id'];
        this.name = input['name'];
        this.description = input['description'];
        this.color = input['color'];
        this.seq_num = input['seq_num'];
        this.slug = input['slug'];

        return this;
    }




}
