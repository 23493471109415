import {BaseModel} from './base.model';
import {EloquentSerializable} from '../interfaces/interfaces';

export class UserRole extends BaseModel implements EloquentSerializable<UserRole> {

    public static readonly API_PREFIX: string = 'user-roles';
    public id: number;
    public name: string;
    public description: string;
    public slug: string;
    public validation: Array<any>;

    public getApiPrefix(): string {
        return UserRole.API_PREFIX;
    }


    deserialize(input: JSON): UserRole {
        this.id = input['id'];
        this.name = input['name'];
        this.description = input['description'];
        this.slug = input['slug'];

        return this;
    }


}
