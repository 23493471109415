<div class="ui container padding-bottom-75 content-background very padded basic segment">
    <h2 class="ui dividing header margin-bottom-30">
        Hesabım
    </h2>

    <div class="ui grid equal width relaxed">
        <div class="column">
            <h3 class="ui dividing header margin-bottom-30">
                Kullanıcı Bilgileri
            </h3>
            <h4>{{user?.name}}</h4>
            <div><b>Kullanıcı Adı: </b>{{user?.username}}</div>
            <div class="margin-top-10"><b>E-Posta: </b>{{user?.email}}</div>
            <div class="ui info message margin-top-30 fluid">
                <i class="icon info"></i>
                Bu bilgileri değiştirmek için sistem yöneticisine başvurunuz.
            </div>
        </div>
        <div class="column">
            <h3 class="ui dividing header margin-bottom-30">
                Şifre Değiştir
            </h3>
            <div class="ui warning large message margin-top-30 fluid">
                <i class="warning circle icon"></i>
                İlk kez giriş yaptığınız için şifrenizi değiştirmelisiniz.
            </div>
            <form class="ui form" [formGroup]="passwordForm" (submit)="changePassword($event)">
                <div class="field"
                     [ngClass]="{'error': passwordForm.get('oldPassword').hasError('required') && (passwordForm.get('oldPassword').dirty ||passwordForm.get('oldPassword').touched)}">
                    <label>Mevcut Şifre</label>
                    <input type="password"
                           placeholder=""
                           [(ngModel)]="passwordFormData.oldPassword"
                           formControlName="oldPassword"
                    >
                    <div class="ui pointing red basic label"
                         *ngIf="passwordForm.get('oldPassword').hasError('required') && (passwordForm.get('oldPassword').dirty || passwordForm.get('oldPassword').touched)">
                        Boş Bırakılamaz
                    </div>
                </div>
                <div class="field"
                     [ngClass]="{'error': passwordForm.get('newPassword').hasError('passwordStrength') && (passwordForm.get('newPassword').dirty ||passwordForm.get('newPassword').touched)}">
                    <label>Yeni Şifre</label>
                    <input type="password"
                           placeholder=""
                           [(ngModel)]="passwordFormData.newPassword"
                           formControlName="newPassword"
                    >
                    <small><i class="icon info"></i>Parola en az 8 karakter uzunluğunda olmalı ve 1 BÜYÜK harf, 1 küçük
                        harf, 1 rakam içermelidir.
                    </small>
                    <div class="ui pointing red basic label"
                         *ngIf="passwordForm.get('newPassword').hasError('passwordStrength') && (passwordForm.get('newPassword').dirty || passwordForm.get('newPassword').touched)">
                        Parola Yeterince Güçlü Değil
                    </div>
                </div>
                <div class="field"
                     [ngClass]="{'error': passwordForm.get('newPasswordConfirm').hasError('confirmPassword') && (passwordForm.get('newPasswordConfirm').dirty ||passwordForm.get('newPasswordConfirm').touched)}">
                    <label>Yeni Şifre (Tekrar)</label>
                    <input type="password"
                           placeholder=""
                           [(ngModel)]="passwordFormData.newPasswordConfirm"
                           formControlName="newPasswordConfirm"
                    >
                    <div class="ui pointing red basic label"
                         *ngIf="passwordForm.get('newPasswordConfirm').hasError('confirmPassword') && (passwordForm.get('newPasswordConfirm').dirty || passwordForm.get('newPasswordConfirm').touched)">
                        Şifreler Eşleşmiyor
                    </div>
                </div>
                <div class="field">
                    <button type="submit" class="ui button positive">Değiştir</button>
                </div>
            </form>
        </div>
    </div>


</div>



<div class="ui small basic modal" id="firstLoginModal">
    <div class="ui icon header red">
        <i class="warning circle icon"></i>
        Şifrenizi Değiştirmeniz Gerekiyor
    </div>
    <div class="content" style="text-align: center !important">
        Sisteme ilk defa giriş yaptığınız için sistem tarafından otomatik atanan şifrenizi değiştirmeniz gerekiyor.
    </div>
    <div class="actions">
        <div class="ui green ok inverted button">
            <i class="checkmark icon"></i>
            Tamam
        </div>
    </div>
</div>