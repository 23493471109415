import {NgModule} from '@angular/core';
import {OperatorComponent} from './operator.component';
import {SharedModule} from '../../../shared/modules/shared.module';
import {UploaderComponent} from '../../../shared/components/uploader/uploader.component';
import {QualityControlComponent} from '../../../shared/components/quality-control/quality-control.component';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        OperatorComponent
    ],
    exports: [
        OperatorComponent
    ]
})
export class OperatorModule {
}
