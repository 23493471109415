<div class="ui small basic modal" #modal>
    <div class="ui icon header">
        <i class="archive icon"></i>
        {{title}}
    </div>
    <div class="content">
        {{description}}
    </div>
    <div class="actions">
        <div class="ui red basic cancel inverted button">
            <i class="remove icon"></i>
            Vazgeç
        </div>
        <div class="ui green ok inverted button">
            <i class="checkmark icon"></i>
            Devam Et
        </div>
    </div>
</div>