import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';

declare let $: any;

@Component({
    selector: 'app-public-wrapper',
    templateUrl: './public-wrapper.component.html',
    styleUrls: ['./public-wrapper.component.scss']
})
export class PublicWrapperComponent implements OnInit, OnDestroy {

    private routerSubscripton: Subscription;

    constructor(private router: Router) {
        this.subscribeRouterEvents();
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        this.unsubscribeRouterEvents();
    }

    private subscribeRouterEvents() {
        this.routerSubscripton = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((val) => {
            $('#sidebar').sidebar('hide');
        });
    }

    private unsubscribeRouterEvents() {
        this.routerSubscripton.unsubscribe();
    }
}
