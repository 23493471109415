<div class="uploader-container">
    <div class="ui large header dividing margin-bottom-30 padding-left-30 padding-right-30">
        {{title}}
    </div>
    <div *ngIf="canUpload">
        <button class="ui button blue icon" (click)="fileInput.click()">
            <i class="upload icon"></i>
            Dosya Seç
        </button>
        <form #uploaderForm>
            <input type="file" (change)="onFileInputChange($event)" [hidden]="true" #fileInput multiple>
        </form>
    </div>
    <fui-dimmer class="inverted" [(isDimmed)]="isAttachmentsLoading" [isClickable]="false">
        <div class="ui text loader">Yükleniyor</div>
    </fui-dimmer>
    <div class="uploader-drop-area margin-top-20"
         (drop)="onDrop($event)"
         [ngClass]="{'dragging': isDragging}"
         #dropArea
    >

        <div *ngIf="attachable.attachments?.length == 0 && uploadableList.length == 0">
            <div class="drop-area-placeholder">
                <h2 class="ui icon header grey">
                    <i class="cloud upload icon"></i>

                    <div class="content">
                        Eklenmiş Dosya Yok
                        <div class="sub header">
                            Dosyaları Buraya sürükleyip bırakabilirsin
                        </div>
                    </div>
                </h2>
            </div>
        </div>

        <div class="drag-overlay" [ngClass]="{'dragging': isDragging}">
            <div class="drag-overlay-content">
                <div class="ui grid centered">
                    <h2 class="ui icon header">
                        <i class="cloud upload icon"></i>

                        <div class="content">
                            Sürükle & Bırak
                            <div class="sub header">
                                Dosyaları Buraya Sürükleyip Bırakabilirsin
                            </div>
                        </div>
                    </h2>
                </div>
            </div>
        </div>

        <div class="ui padded segment attached attachment" *ngFor="let uploadable of uploadableList"
             [ngClass]="{'error': uploadable.hasError}">
            <div class="ui grid">
                <div class="row">
                    <div class="two wide column  extension-icon">
                        <i class="big icon"
                           [ngClass]="getIconClass(uploadable.file.type)"
                           *ngIf="!uploadable.hasError"></i>
                        <i class="big warning sign icon error" *ngIf="uploadable.hasError"></i>
                    </div>
                    <div class="eleven wide column  middle aligned">
                        <div class="title">
                            {{uploadable.file.name}}
                        </div>
                        <div class="details" *ngIf="uploadable.hasError">
                            Ek yüklenirken bir hata oluştu.
                        </div>
                    </div>
                    <div class="three wide column right aligned">
                        <a class="ui mini button icon basic grey"
                           [ngClass]="{'red': uploadable.hasError}"
                           (click)="uploadable.abortUpload()"
                           fuiPopup
                           [popupText]="uploadable.hasError ? 'Listeden Kaldır' : 'İptal Et'"
                           popupPlacement="bottom"
                        >
                            <i class="remove icon"></i>
                        </a>
                    </div>
                </div>
            </div>
            <fui-progress class="bottom attached indicating active" [value]="uploadable.uploadPercent"></fui-progress>
        </div>

        <div class="ui padded segment attached attachment" *ngFor="let attachment of attachable.attachments">
            <div class="ui grid">
                <div class="row">
                    <div class="two wide column  extension-icon">
                        <i class="big icon" [ngClass]="getIconClass(attachment.mimetype)"></i>
                    </div>
                    <div class="eleven wide column">
                        <div class="title">
                            <a href="{{attachment.full_url}}" (click)="preview($event, attachment)" target="_blank">{{attachment.filename}}</a>

                        </div>
                        <div class="details">
                            {{attachment.created_at | date: 'dd.MM.yyyy H:mm'}} | {{attachment.uploaded_by.name}}
                            tarafından
                        </div>
                    </div>
                    <div class="three wide column right aligned">
                        <div class="ui mini buttons">
                            <a class="ui button icon basic grey"
                               [href]="attachment.full_url"
                               fuiPopup
                               popupText="İndir"
                               popupPlacement="bottom"
                               download
                            >
                                <i class="download icon"></i>
                            </a>
                            <a class="ui button icon basic grey"
                               fuiPopup
                               popupText="Sil"
                               popupPlacement="bottom"
                               (click)="detach(attachment)"
                            >
                                <i class="close icon"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>

</div>


<div class="ui small basic modal" #confirmModal>
    <div class="ui icon header">
        <i class="attach icon"></i>
        Ekteki Dosyayı Sil
    </div>
    <div class="content" style="text-align: center !important">
        Bu işlem geri alınamaz. Ekteki dosyayı silmek istediğinize emin misiniz?
    </div>
    <div class="actions">
        <div class="ui red basic cancel inverted button">
            <i class="remove icon"></i>
            Vazgeç
        </div>
        <div class="ui green ok inverted button">
            <i class="checkmark icon"></i>
            Devam Et
        </div>
    </div>
</div>


<div class="preview-container" id="previewContainer">
    <ng-container *ngIf="previewURL">
        <app-holosen-pdf-viewer
                [title]="previewTitle"
                [url]="previewURL"
                [close]="closePreview"
        ></app-holosen-pdf-viewer>
    </ng-container>

    <!--<ng-container *ngIf="previewURL">-->
    <!--<object [data]="previewURL | safe" type="application/pdf" width="100%" height="100%">-->
    <!--<iframe [src]="previewURL | safe" width="100%" height="100%" style="border: none;">-->
    <!--This browser does not support PDFs. Please download the PDF to view it: <a href="{{previewURL}}"-->
    <!--target="_blank">Download-->
    <!--PDF</a>-->
    <!--</iframe>-->
    <!--</object>-->
    <!--</ng-container>-->
</div>
