<app-dynamic-component #workOrderDynamicComponent></app-dynamic-component>

<div class="ui container padding-bottom-75  content-background very padded basic segment">

    <form novalidate [formGroup]="order.form_group" class="ui form">

        <div class="field clearing" *ngIf="order.id">
            <div class="ui buttons right floated">
                <!--<button class="ui button basic secondary" (click)="logCurrentOrder()">-->
                <!--<i class="print icon "></i>LOG-->
                <!--</button>-->
                <button class="ui button basic blue" (click)="openUploader()">
                    <i class="upload icon "></i>Ekleri Yönet
                </button>

                <button class="ui button basic green"
                        (click)="setWorkOrderStatus(STATUS_PLANNED)"
                        *ngIf="order.work_order_status.slug === STATUS_NEW">
                    <i class="check icon "></i>Planlandı
                </button>
            </div>
        </div>
        <div class="clearfix"></div>
        <div class="two fields">
            <div class="field"
                 [ngClass]="{'error': order.form('proforma_id').hasError('required') && (order.form('proforma_id').dirty || order.form('proforma_id').touched)}"
            >
                <label>Satış Teklif Numarası</label>
                <input
                        type="text"
                        placeholder="Satış Teklif Numarası"
                        formControlName="proforma_id"
                        [(ngModel)]="order.proforma_id"
                >
                <div class="ui pointing red basic label"
                     *ngIf="order.form('proforma_id').hasError('required') && (order.form('proforma_id').dirty || order.form('proforma_id').touched)">
                    Boş Bırakılamaz
                </div>
                <small class="hint" *ngIf="order.id">
                    Teklif numarasını değiştirmek için sistem yöneticisine başvurun
                </small>
            </div>

            <div class="field"
                 [ngClass]="{'error': order.form('proforma_price').hasError('required') && (order.form('proforma_price').dirty || order.form('proforma_price').touched)}"
            >
                <label>Satış Teklif Birim Fiyatı
                    <small style="font-weight: 300 !important">(KDV Hariç)</small>
                </label>
                <div class="ui right labeled input">
                    <input
                            type="text"
                            placeholder="Satış Teklif Birim Fiyatı (KDV Hariç)"
                            formControlName="proforma_price"
                            [(ngModel)]="order.proforma_price"
                    >
                    <div class="ui label basic">TL</div>
                </div>
                <div class="ui pointing red basic label"
                     *ngIf="order.form('proforma_price').hasError('required') && (order.form('proforma_price').dirty || order.form('proforma_price').touched)">
                    Boş Bırakılamaz
                </div>
                <small class="hint" *ngIf="order.id">
                    Teklif tutarını değiştirmek için sistem yöneticisine başvurun
                </small>
            </div>

        </div>
        <div class="field" *ngIf="!order.customer.id || isCustomerChanging">
            <label>Müşteri Ara
                <a class="mini ui label basic red margin-left-20" (click)="changeCustomer(false)"
                   *ngIf="isCustomerChanging">
                    Vazgeç <i class="icon close"></i>
                </a>
            </label>
            <div class="ui grid clearing margin-top-15">
                <div
                        [ngClass]="{
                            'sixteen': !customerNoResult,
                            'twelve': customerNoResult,
                            'wide' : true,
                            'column' : true
                        }
                ">
                    <fui-search
                            class="ui fluid input"
                            placeholder="Yazmaya başlayın..."
                            [optionsLookup]="searchCustomers"
                            optionsField="title"
                            (resultSelected)="selectCustomer($event)"
                            [retainSelectedResult]="false"
                            [searchDelay]="200"
                    >
                    </fui-search>
                </div>
                <div class="four wide column" *ngIf="customerNoResult">
                    <button class="ui button green fluid" (click)="openCustomerModal()">
                        <i class="icon plus"></i>Yeni Müşteri Ekle
                    </button>
                </div>
            </div>
        </div>


        <div class="field" *ngIf="order.customer.id && !isCustomerChanging">
            <label>Müşteri Bilgileri</label>
            <div class="ui card customer-card">

                <div class="content">
                    <div class="header">
                        {{order.customer.title}}
                    </div>
                    <div class="description">
                        <div>
                            <i class="icon envelope"></i>
                            {{order.customer.email}}
                        </div>
                        <div>
                            <i class="icon phone"></i>
                            {{order.customer.phone}}
                        </div>
                    </div>
                </div>
                <div class="content">
                    <a class="mini ui label basic icon right floated red" (click)="changeCustomer(true)">
                        Değiştir <i class="close icon"></i>
                    </a>

                </div>
            </div>


        </div>

        <div class="ui icon visible warning message small" *ngIf="!order.customer.id">
            <i class="exclamation icon"></i>
            <div class="content">
                <div class="header">
                    Müşteri Seçilmedi
                </div>
                <p>Müşteri seçmek için yukarıdaki arama kutusuna müşterinin ünvanını yazmaya başlayın ve gelen
                    menüden müşteriyi seçin.</p>
            </div>
        </div>


        <div class="ui divider"></div>

        <div class="field"
             [ngClass]="{'error': order.form('part_name').hasError('required') && (order.form('part_name').dirty || order.form('part_name').touched)}">
            <label>Parça Adı</label>
            <input
                    type="text"
                    placeholder="Parça Adı"
                    formControlName="part_name"
                    [(ngModel)]="order.part_name"
            >
            <div class="ui pointing red basic label"
                 *ngIf="order.form('part_name').hasError('required') && (order.form('part_name').dirty || order.form('part_name').touched)">
                Boş Bırakılamaz
            </div>
        </div>

        <div class="fields equal width">
            <div class="field"
                 [ngClass]="{'error': order.form('material').hasError('required') && (order.form('material').dirty || order.form('material').touched)}">
                <label>Malzeme</label>
                <input
                        type="text"
                        placeholder="Malzeme"
                        formControlName="material"
                        [(ngModel)]="order.material"
                >
                <div class="ui pointing red basic label"
                     *ngIf="order.form('material').hasError('required') && (order.form('material').dirty || order.form('material').touched)">
                    Boş Bırakılamaz
                </div>
            </div>
            <div class="field"
                 [ngClass]="{'error': order.form('quantity').hasError('requiredNumber') && (order.form('quantity').dirty || order.form('quantity').touched)}">
                <label>Miktarı</label>
                <input
                        type="number"
                        placeholder="Parça Adedi"
                        formControlName="quantity"
                        [(ngModel)]="order.quantity"
                >
                <div class="ui pointing red basic label"
                     *ngIf="order.form('quantity').hasError('requiredNumber') && (order.form('quantity').dirty || order.form('quantity').touched)">
                    Boş Bırakılamaz
                </div>
            </div>

            <div class="field">
                <label>Birim</label>
                <fui-select class="selection fluid"
                            [(ngModel)]="order.unit"
                            formControlName="unit"
                            [options]="[{value: 'pcs', name: 'Adet'}, {value: 'kg', name: 'kg'}]"
                            [optionTemplate]="unitOptionTemplate"
                            [isSearchable]="false"
                            valueField="value"
                            #unitOptionMenu>
                    <fui-select-option *ngFor="let o of unitOptionMenu.filteredOptions"
                                       [value]="o"></fui-select-option>
                </fui-select>
                <ng-template let-option #unitOptionTemplate>
                    {{option.name}}
                </ng-template>
            </div>
        </div>
        <div class="grouped fields margin-top-25">
            <div class="field">
                <div class="ui checkbox">
                    <input
                            type="checkbox"
                            [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="order.material_supplied_by_us"
                    >
                    <label>Malzeme Bizden</label>
                </div>
            </div>
            <div class="field">
                <div class="ui checkbox">
                    <input
                            type="checkbox"
                            [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="order.is_service"
                    >
                    <label>Yarı Ürün/Hizmet</label>
                </div>
            </div>
        </div>

        <div class="field">
            <label>Tahmini Toplam İşlem Süresi</label>
            <div class="ui fluid message">
                {{order.estimated_operating_time}} dk.
            </div>
            <div class="hint">
                İş emri altındaki operasyonların toplam sürelerini gösterir.
            </div>
        </div>

        <div class="ui divider"></div>


        <div class="field"
             [ngClass]="{'error': order.form('entry_datetime').hasError('required') && (order.form('entry_datetime').dirty || order.form('entry_datetime').touched)}">
            <label>Giriş Tarihi</label>
            <button class="ui basic blue button"
                    fuiDatepicker
                    [pickerMode]="'date'"
                    [(ngModel)]="order.entry_datetime"
                    [ngModelOptions]="{standalone: true}"
                    [pickerFirstDayOfWeek]="1"
            >
                <i class="calendar icon"></i> {{order.entry_datetime | date: 'dd.MM.yyyy'}}
            </button>

            <div class="ui pointing red basic label"
                 *ngIf="order.form('entry_datetime').hasError('required') && (order.form('entry_datetime').dirty || order.form('entry_datetime').touched)">
                Boş Bırakılamaz
            </div>
        </div>


        <div class="field"
             [ngClass]="{'error': order.form('deadline').hasError('required') && (order.form('deadline').dirty || order.form('deadline').touched)}">
            <label>Termin Tarihi</label>
            <button class="ui basic blue button"
                    fuiDatepicker
                    [pickerMode]="'date'"
                    [(ngModel)]="order.deadline"
                    [ngModelOptions]="{standalone: true}"
                    [pickerFirstDayOfWeek]="1"
            >
                <i class="calendar icon"></i> {{order.deadline | date: 'dd.MM.yyyy'}}
            </button>

            <div class="ui pointing red basic label"
                 *ngIf="order.form('deadline').hasError('required') && (order.form('deadline').dirty || order.form('deadline').touched)">
                Boş Bırakılamaz
            </div>
        </div>


        <div class="ui divider"></div>


        <div class="grouped fields">
            <label>Gerekli Belgeler</label>
            <div class="field" *ngFor="let document of order.required_documents.document_list">
                <div class="ui checkbox">
                    <input
                            type="checkbox"
                            [(ngModel)]="document.required"
                            [ngModelOptions]="{standalone: true}"
                    >
                    <label>{{document.name}}</label>
                </div>
            </div>
            <div class="field">
                        <textarea
                                rows="6"
                                placeholder="Diğer Belgeler"
                                [(ngModel)]="order.required_documents.other"
                                [ngModelOptions]="{standalone: true}"></textarea>
            </div>
        </div>

        <div class="ui divider"></div>

        <div class="field">
            <label>
                Ek Notlar
            </label>
            <textarea rows="6" [(ngModel)]="order.additional_notes"
                      [ngModelOptions]="{standalone: true}"></textarea>
        </div>

        <div class="field margin-top-30 margin-bottom-30">
            <div class="ui clearing">
                <div class="ui right floated">
                    <button class="ui green button" (click)="saveOrUpdateOrder()" [disabled]="order.form_group.invalid">
                        Kaydet
                    </button>
                    <small class="hint error" *ngIf="order.form_group.invalid">
                        <i class="warning sign icon"></i>Doldurulması Gereken Alanlar Var!
                    </small>
                </div>
            </div>
        </div>

    </form>
    <!--<div class="" [dragula]="'test-bag'">-->
        <!--<div class="" *ngFor="let op of order.work_order_operations">-->
            <!--{{op.id}}-->
        <!--</div>-->
    <!--</div>-->
    <ng-container *ngIf="order.id">
        <div class="ui large header dividing margin-top-40 margin-bottom-20">Operasyonlar</div>
        <div class="clearfix margin-bottom-30">
            <div class="ui buttons right floated">
                <button class="ui button basic blue" (click)="openOperation()">
                    <i class="sitemap icon "></i>Operasyon Ekle
                </button>
                <button class="ui button basic green" (click)="openQualityControlConfig()">
                    <i class="check icon "></i>Kalite Kontrol Konfigürasyonu
                </button>
            </div>
        </div>
        <div class="ui segments" [dragula]="'operation-bag'" [dragulaModel]="order.work_order_operations">
            <div class="ui very padded segment operation-segment"
                 *ngFor="let operation of order.work_order_operations">
                <div class="ui button icon grey basic tiny operation-drag-handle">
                    <i class="sort icon"></i>
                </div>
                <a class="ui left corner label black" *ngIf="operation.has_open_log">
                    <i class="icon loading setting big"></i>
                </a>
                <div class="ui tiny operation menu borderless fitted">
                    <div class="item">

                        <div class="ui labels">
                            <a class="ui label"
                               [ngClass]="{'loading': operation.isStatusLoading}"
                               [style.color]="'#ffffff'"
                               [style.background-color]="operation.work_order_operation_status.color"
                            >
                                {{operation.work_order_operation_status.name}}
                            </a>
                            <div class="ui circular large icon basic  grey label"
                                 *ngIf="!operation.is_internal">
                                <i class="truck icon"></i>DT
                            </div>
                        </div>
                    </div>
                    <div class="right menu">
                        <div class="ui small buttons">
                            <button class="ui basic blue button dropdown" fuiDropdown
                                    *ngIf="operation.is_internal">
                                <div class="text">
                                    <i class="sticky note outline icon"></i>
                                    Günlük
                                </div>
                                <i class="dropdown icon"></i>
                                <div class="menu" fuiDropdownMenu>
                                    <div class="item" (click)="openLogHistory(operation, order)">
                                        <i class="unhide icon"></i>
                                        Kayıtları Gör
                                    </div>
                                    <div class="item" (click)="openLogHistory(operation, order, true)">
                                        <i class="add icon"></i>
                                        Yeni Kayıt Ekle
                                    </div>
                                </div>
                            </button>
                            <button class="ui basic blue button"
                                    *ngIf="!operation.is_internal"
                                    (click)="openPurchaseOrder(operation)"
                                    fuiPopup
                                    popupText="Satınalma Siparişi"
                                    popupPlacement="bottom">
                                <i class="in cart icon"></i>Sipariş
                            </button>
                            <div class="ui icon button basic grey dropdown" fuiDropdown>
                                <i class="ellipsis vertical icon"></i>
                                <div class="menu" fuiDropdownMenu>
                                    <div class="item"
                                         (click)="openOperation(operation)">
                                        <i class="edit icon"></i>
                                        Düzenle
                                    </div>
                                    <div class="item"
                                         (click)="deleteWorkOrderOperation(order, operation)">
                                        <i class="remove icon"></i>
                                        Sil
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="ui grid clearing padding-top-15 padding-bottom-20">
                    <div class="row">
                        <div class="four wide column">
                            <div class="operation-type-name">
                                {{operation.work_order_operation_type.name}}
                            </div>
                            <div class="margin-top-10">
                                {{operation.description}}
                            </div>
                        </div>
                        <div class="five wide column">
                            <ng-container *ngIf="operation.is_internal">
                                <div class="operation-detail-label">İş İstasyonu</div>
                                <div>
                                    {{operation.work_station.code}} -
                                    {{operation.work_station.name}}
                                </div>
                                <div class="operation-detail-label margin-top-10">Personel</div>
                                <div>
                                    {{operation.user.name}}
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!operation.is_internal">
                                <div class="operation-detail-label">Tedarikçi</div>
                                <div>
                                    {{operation.supplier?.title}}
                                </div>
                            </ng-container>
                        </div>
                        <div class="four wide column">
                            <div class="operation-detail-label">Giriş Tarihi</div>
                            <div>
                                {{operation.entry_datetime | date: 'dd.MM.yyyy'}}
                            </div>
                            <div class="operation-detail-label margin-top-10">Termin Tarihi</div>
                            <div>
                                {{operation.deadline | date: 'dd.MM.yyyy'}}
                            </div>
                        </div>
                        <div class="three wide column">
                            <ng-container *ngIf="operation.is_internal">
                                <div class="operation-detail-label">Tahmini İşlem Süresi / Adet</div>
                                <div>
                                    {{operation.estimated_operating_time}} dk.
                                </div>
                                <div class="ui grid equal width">
                                    <div class="column">
                                        <div class="operation-detail-label margin-top-10">Tamamlanan
                                        </div>
                                        <div class="stats completed">
                                            <i class="check small icon margin-right-5"></i>{{operation.completed_num_of_parts}}
                                        </div>
                                    </div>
                                    <div class="column">
                                        <div class="operation-detail-label margin-top-10">Fire Verilen
                                        </div>
                                        <div class="stats wasted">
                                            <i class="remove small icon margin-right-5"></i>{{operation.wasted_num_of_parts}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!operation.is_internal">
                                <div class="ui grid equal width">
                                    <div class="column">
                                        <div class="operation-detail-label margin-top-10">Gelen</div>
                                        <div>
                                            <div class="stats completed">
                                                <i class="check small icon margin-right-5"></i>{{operation.incoming_completed_num_of_parts}}
                                            </div>
                                            <div class="stats wasted">
                                                <i class="remove small icon margin-right-5"></i>{{operation.incoming_wasted_num_of_parts}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column">
                                        <div class="operation-detail-label margin-top-10">Giden</div>
                                        <div>
                                            <div class="stats completed">
                                                <i class="check small icon margin-right-5"></i>{{operation.outgoing_completed_num_of_parts}}
                                            </div>
                                            <div class="stats wasted">
                                                <i class="remove small icon margin-right-5"></i>{{operation.outgoing_wasted_num_of_parts}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <fui-progress class=" small margin-bottom-10"
                              [ngClass]="{'active': operation.has_open_log}"
                              [value]="operation.completion_percentage"></fui-progress>
            </div>
        </div>

    </ng-container>
</div>


<!----------- MODALS ----------->
<div class="ui modal" id="addNewCustomerModal">
    <i class="close icon"></i>
    <div class="header">
        Müşteri Ekle
    </div>
    <div class="scrolling content">
        <form class="ui form" novalidate [formGroup]="newCustomer.form_group">
            <div class="field"
                 [ngClass]="{'error': newCustomer.form('title').hasError('required') && (newCustomer.form('title').dirty || newCustomer.form('title').touched)}">
                <label>Müşteri Unvanı</label>
                <input type="text"
                       [(ngModel)]="newCustomer.title"
                       formControlName="title"
                >
                <div class="ui pointing red basic label"
                     *ngIf="newCustomer.form('title').hasError('required') && (newCustomer.form('title').dirty || newCustomer.form('title').touched)">
                    Boş Bırakılamaz
                </div>
            </div>
            <div class="field"
                 [ngClass]="{'error': newCustomer.form('phone').hasError('required') && (newCustomer.form('phone').dirty || newCustomer.form('phone').touched)}">
                <label>Telefon Numarası</label>
                <input type="text"
                       [(ngModel)]="newCustomer.phone"
                       formControlName="phone">
                <div class="ui pointing red basic label"
                     *ngIf="newCustomer.form('phone').hasError('required') && (newCustomer.form('phone').dirty || newCustomer.form('phone').touched)">
                    Boş Bırakılamaz
                </div>
            </div>
            <div class="field"
                 [ngClass]="{'error': newCustomer.form('email').hasError('required') && (newCustomer.form('email').dirty || newCustomer.form('email').touched)}">
                <label>E-posta Adresi</label>
                <input type="text"
                       [(ngModel)]="newCustomer.email"
                       formControlName="email">
                <div class="ui pointing red basic label"
                     *ngIf="newCustomer.form('email').hasError('required') && (newCustomer.form('email').dirty || newCustomer.form('email').touched)">
                    Boş Bırakılamaz
                </div>
            </div>
        </form>
    </div>
    <div class="actions">
        <div class="ui black deny button">
            Vazgeç
        </div>
        <div class="ui positive labeled icon button">
            <i class="checkmark icon"></i>
            Kaydet
        </div>
    </div>
</div>


<div class="ui small basic modal" #confirmModal>
    <div class="ui icon header">
        <i class="remove from calendar icon"></i>
        Silme İşlemini Onayla
    </div>
    <div class="content" style="text-align: center !important">
        Bu işlem geri alınamaz. Silmek istediğinize emin misiniz?
    </div>
    <div class="actions">
        <div class="ui red basic cancel inverted button">
            <i class="remove icon"></i>
            Vazgeç
        </div>
        <div class="ui green ok inverted button">
            <i class="checkmark icon"></i>
            Devam Et
        </div>
    </div>
</div>
