<div class="quality-control-config-container">
    <div class="ui large header dividing margin-bottom-30 padding-left-30 padding-right-30">
        Kalite Kontrol Kofigürasyonu
    </div>

    <ng-container *ngFor="let operation of order?.work_order_operations | filter:'is_internal':true">
        <div class="ui menu top attached inverted grey borderless">
            <div class="item">
                Operasyon: {{operation.work_order_operation_type.name}}
            </div>
            <div class="right menu">
                <div class="item">
                    <button class="ui icon button green small white" (click)="addQualityConfigItem(operation)">
                        <i class="plus icon "></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="ui segment attached">
            <b>Operasyon Açıklaması:</b> {{operation.description}}
        </div>
        <div class="ui segment attached padded">
            <h4 class="ui center aligned icon header grey"
                *ngIf="operation.quality_control_config.quality_control_config_items.length === 0">
                <i class="warning icon"></i>
                Kontrol Noktası Eklenmemiş
            </h4>
            <form [formGroup]="operation.quality_control_config.form_group" class="ui form" novalidate
                  *ngIf="operation.quality_control_config.quality_control_config_items.length > 0">
                <div class="field"
                     [ngClass]="{'error': operation.quality_control_config.form('required_control_num').hasError('requiredNumber') && (operation.quality_control_config.form('required_control_num').dirty || operation.quality_control_config.form('required_control_num').touched)}">
                    <label>
                        Kaç Defa Tekrarlansın?
                    </label>
                    <input type="number"
                           [(ngModel)]="operation.quality_control_config.required_control_num"
                           formControlName="required_control_num">
                    <div class="ui pointing red basic label"
                         *ngIf="operation.quality_control_config.form('required_control_num').hasError('requiredNumber') && (operation.quality_control_config.form('required_control_num').dirty || operation.quality_control_config.form('required_control_num').touched)">
                        Boş Bırakılamaz
                    </div>
                </div>
            </form>

            <ng-container *ngFor="let controlPoint of operation.quality_control_config.quality_control_config_items; let i = index">
                <div class="ui header small">Kontrol Noktası {{i+1}}</div>
                <form [formGroup]="controlPoint.form_group" class="ui form" novalidate>
                    <div class="four fields">
                        <div class="field"
                             [ngClass]="{'error': controlPoint.form('section').hasError('required') && (controlPoint.form('section').dirty || controlPoint.form('section').touched)}">
                            <label>
                                Bölüm
                            </label>
                            <input type="text" [(ngModel)]="controlPoint.section" formControlName="section">


                            <div class="ui pointing red basic label"
                                 *ngIf="controlPoint.form('section').hasError('required') && (controlPoint.form('section').dirty || controlPoint.form('section').touched)">
                                Boş Bırakılamaz
                            </div>
                        </div>
                        <div class="field"
                             [ngClass]="{'error': controlPoint.form('dimension').hasError('requiredNumber') && (controlPoint.form('dimension').dirty || controlPoint.form('dimension').touched)}">
                            <label>
                                Ölçü
                                <small><i>(mm)</i></small>
                            </label>

                            <input type="number" [(ngModel)]="controlPoint.dimension" formControlName="dimension">

                            <div class="ui pointing red basic label"
                                 *ngIf="controlPoint.form('dimension').hasError('requiredNumber') && (controlPoint.form('dimension').dirty || controlPoint.form('dimension').touched)">
                                Boş Bırakılamaz
                            </div>
                        </div>
                        <div class="field"
                             [ngClass]="{'error': controlPoint.form('tolerance_lower').hasError('requiredNumber') && (controlPoint.form('tolerance_lower').dirty || controlPoint.form('tolerance_lower').touched)}">
                            <label>
                                Alt Tol.
                                <small><i>(mm)</i></small>
                            </label>
                            <input type="number"
                                   [(ngModel)]="controlPoint.tolerance_lower"
                                   formControlName="tolerance_lower">

                            <div class="ui pointing red basic label"
                                 *ngIf="controlPoint.form('tolerance_lower').hasError('requiredNumber') && (controlPoint.form('tolerance_lower').dirty || controlPoint.form('tolerance_lower').touched)">
                                Boş Bırakılamaz
                            </div>
                        </div>
                        <div class="field"
                             [ngClass]="{'error': controlPoint.form('tolerance_upper').hasError('requiredNumber') && (controlPoint.form('tolerance_upper').dirty || controlPoint.form('tolerance_upper').touched)}">
                            <label>
                                Üst Tol.
                                <small><i>(mm)</i></small>
                            </label>

                            <input type="number"
                                   [(ngModel)]="controlPoint.tolerance_upper"
                                   formControlName="tolerance_upper">

                            <div class="ui pointing red basic label"
                                 *ngIf="controlPoint.form('tolerance_upper').hasError('requiredNumber') && (controlPoint.form('tolerance_upper').dirty || controlPoint.form('tolerance_upper').touched)">
                                Boş Bırakılamaz
                            </div>
                        </div>
                        <div class="field">
                            <label>&nbsp;</label>
                            <button class="ui icon button circular basic red tiny"
                                    (click)="removeQualityConfigItem(operation, i)">
                                <i class="icon close"></i>
                            </button>
                        </div>
                    </div>
                </form>
            </ng-container>
        </div>
        <button class="ui button green fluid bottom attached"
                *ngIf="operation.quality_control_config.quality_control_config_items.length > 0"
                (click)="updateOperation(operation)">
            <i class="icon check"></i>Kaydet
        </button>
    </ng-container>
</div>
