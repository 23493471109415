import {NgModule} from '@angular/core';
import {HolosenTemplateDirective, HolosenTemplateWrapperDirective} from './holosen-template.directive';


@NgModule({
    imports: [],
    declarations: [
        HolosenTemplateDirective,
        HolosenTemplateWrapperDirective
    ],
    exports: [
        HolosenTemplateDirective,
        HolosenTemplateWrapperDirective
    ],
    providers: [],
})
export class DirectivesModule {
}
