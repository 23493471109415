
import {of as observableOf, Subject, BehaviorSubject, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {AuthHttpService} from '../http/auth-http.service';
import {ConfigService} from '../config/config.service';
import {StorageService} from '../storage/storage.service';
import {Router} from '@angular/router';
import {User} from '../../eloquent/models/user.model';
import {map, catchError} from 'rxjs/operators';
import {MrpResponse} from '../../eloquent/interfaces/interfaces';


@Injectable()
export class AuthenticationService {


    /*
     * RESPONSE MESSAGE
     */

    public static readonly RESPONSE = {
        LOGIN_SUCCESSFUL: 'login-successful',
        INVALID_CREDENTIALS: 'invalid-credentials',
        LOGOUT_SUCCESSFUL: 'logout-successful'
    };

    public static readonly ROLES = {
        ADMIN: 'admin',
        USER: 'user',
        VIEWER: 'viewer',
        OPERATOR: 'operator',
        PLANNER: 'planner',
        QUALITY_CONTROLLER: 'quality-controller',
    };


    private isLoggedInSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isLoggedIn: Observable<boolean>;
    public user: BehaviorSubject<User> = new BehaviorSubject<User>(new User());

    constructor(private router: Router,
                private config: ConfigService,
                private authHttp: AuthHttpService,
                private storage: StorageService) {
        this.checkLogin().subscribe();

        this.isLoggedIn = this.isLoggedInSubject.asObservable();
    }

    public checkLogin(): Observable<any> {
        return this.authHttp.authPost(this.config.get('apiBaseUrl') + 'auth/check-login', {}).pipe(
            map((response: MrpResponse) => {
                    if (response.ok) {
                        this.setIsLoggedIn(true);
                        this.setUser(this.getUser().deserialize(response.body));
                        return response;
                    } else {
                        this.setIsLoggedIn(false);
                        return response;
                    }
                }
            ),
            catchError((error) => {
                this.setIsLoggedIn(false);
                return observableOf(false);
            })
        );
    }

    public setIsLoggedIn(isLoggedIn: boolean): void {
        this.isLoggedInSubject.next(isLoggedIn);
    }

    public userHasRole(roles: Array<string>) {
        const userRoles = this.getUser().roles;
        if (!(userRoles instanceof Array)) {
            return false;
        }

        for (let i = 0; i < roles.length; i++) {
            for (let j = 0; j < userRoles.length; j++) {
                if (userRoles[j].slug === roles[i]) {
                    return true;
                }
            }
        }
        return false;
    }

    public getUser(): User {
        return this.user.value;
    }

    public getCurrentUserFromDd() {
        return this.authHttp.authGet(this.config.get('apiBaseUrl') + 'me').pipe(
            map((response: MrpResponse) => {
                    if (response.ok) {
                        this.setUser(this.getUser().deserialize(response.body));
                        return response.body;
                    } else {
                        this.setIsLoggedIn(false);
                        return false;
                    }
                }
            ),
            catchError((error) => {
                this.setIsLoggedIn(false);
                return observableOf(false);
            })
        );
    }

    public setUser(userData) {
        this.user.next(userData);
        this.storage.set(StorageService.VARS.USER_DATA, userData);
    }

    public login(username: string, password: string) {
        const requestBody = {
            grant_type: 'password',
            client_id: this.config.get('clientId'),
            client_secret: this.config.get('clientSecret'),
            username: username,
            password: password,
            scope: '*'

        };
        this.storage.clear();
        return new Promise((resolve, reject) => {
            this.authHttp.authPost(this.config.get('loginUrl'), requestBody).pipe(
                map((response) => {
                    if (
                        response.hasOwnProperty('token_type') &&
                        response.hasOwnProperty('access_token') &&
                        response.hasOwnProperty('refresh_token') &&
                        response.hasOwnProperty('token_type')
                    ) {

                        const tokenData = {
                            token_type: response['token_type'],
                            expires_in: response['expires_in'],
                            access_token: response['access_token'],
                            refresh_token: response['refresh_token']
                        };

                        this.storage.set(StorageService.VARS.TOKEN_DATA, tokenData);
                        this.setIsLoggedIn(true);
                        setTimeout(() => {
                            this.checkLogin().subscribe((checkLoginResponse) => {
                                setTimeout(() => {
                                    if (checkLoginResponse.ok) {
                                        resolve(AuthenticationService.RESPONSE.LOGIN_SUCCESSFUL);
                                    }
                                    reject(AuthenticationService.RESPONSE.INVALID_CREDENTIALS);
                                }, 1);
                            });
                        }, 1);


                    } else {
                        this.setIsLoggedIn(false);
                        reject(AuthenticationService.RESPONSE.INVALID_CREDENTIALS);
                    }
                }),
                catchError(error => {
                    reject(error);
                    return observableOf(false);
                })
            ).subscribe();
        });

    }

    public logout() {
        return this.authHttp.authPost(this.config.get('apiBaseUrl') + 'auth/logout', {}).pipe(
            map((response) => {
                this.storage.clear();
                this.setIsLoggedIn(false);
                return AuthenticationService.RESPONSE.LOGOUT_SUCCESSFUL;
            }),
            catchError(error => {
                this.storage.clear();
                this.setIsLoggedIn(false);
                return observableOf(AuthenticationService.RESPONSE.LOGOUT_SUCCESSFUL);
            })
        );
    }


}

export class RoleData {
    private _id: number;
    private _name: string;
    private _description: string;

    constructor(id: number, name: string, description: string) {
        this._id = id;
        this._name = name;
        this._description = description;
    }

    public get id() {
        return this._id;
    }

    public get name() {
        return this._name;
    }

    public get description() {
        return this._description;
    }


}

