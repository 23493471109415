import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class LayoutService {

    private defaultTitle = 'BO-TEK | İmalat Planlama Platformu';
    private titleSubject: BehaviorSubject<string> = new BehaviorSubject(this.defaultTitle);
    public title: Observable<string>;

    constructor(private titleService: Title) {
        this.title = this.titleSubject.asObservable();
    }

    public setTitle(title: string) {
        const newTitle = title + ' | ' + this.defaultTitle;
        this.titleSubject.next(title);
        this.titleService.setTitle(newTitle);
    }

    public getTitle() {
        return this.titleSubject.value;
    }


}
