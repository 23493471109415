import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WorkOrderModule} from './work-order/work-order.module';
import {AuthenticatedWrapperModule} from './authenticated-wrapper/authenticated-wrapper.module';
import {PlanningModule} from './planning/planning.module';
import {PurchaseModule} from './purchase/purchase.module';
import {OperatorModule} from './operator/operator.module';
import {UserManagementModule} from './user-management/user-management.module';
import {WorkOrderOperationModule} from './work-order-operation/work-order-operation.module';
import {WorkStationModule} from './work-station/work-station.module';
import {MeModule} from './me/me.module';
import {ReportingModule} from './reporting/reporting.module';
import {CurrentWorkModule} from './current-work/current-work.module';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  exports: [
    AuthenticatedWrapperModule,
    WorkOrderModule,
    WorkOrderOperationModule,
    PlanningModule,
    PurchaseModule,
    OperatorModule,
    UserManagementModule,
    WorkStationModule,
    MeModule,
    ReportingModule,
    CurrentWorkModule
  ]
})
export class AuthenticatedModule {
}
