import {WorkOrderStatus} from './work-order-status.model';
import {Customer} from './customer.model';
import {Attachable} from './attachable.model';
import {RequiredDocuments} from './required-documents.model';
import {WorkOrderOperation} from './work-order-operation.model';
import {EloquentSerializable} from '../interfaces/interfaces';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HolosenValidators} from '../validators/validators';
import {BaseModel} from './base.model';
import {Attachment} from './attachment.model';
import {Uploadable} from './uploadable.model';
import {Mixin, notEmpty} from '../utils/utils';
import {Shippable} from './shippable.model';
import {Shipment} from './shipment.model';

@Mixin([Shippable])
export class WorkOrder extends BaseModel implements EloquentSerializable<WorkOrder>, Shippable {


    public static readonly API_PREFIX: string = 'work-orders';

    public id: number = null;
    public work_order_status: WorkOrderStatus = new WorkOrderStatus();
    public sales_dispatch_number = '';
    public proforma_price = '';
    public purchase_dispatch_number = '';
    public proforma_id = '';
    public customer: Customer = new Customer();
    public part_name = '';
    public quantity: number = null;
    public entry_datetime: Date = new Date();
    public deadline: Date = null;
    public estimated_operating_time = 0;
    public actual_operating_time: number = null;
    public additional_notes = '';
    public required_documents: RequiredDocuments = new RequiredDocuments();
    public material_supplied_by_us = true;
    public is_service = false;
    public work_order_operations: Array<WorkOrderOperation> = [];
    public internal_completed = 0;
    public external_completed = 0;
    public has_external_operations = false;
    public has_internal_operations = false;
    public is_delayed = false;
    public unit = 'pcs';
    public material = null;
    public has_partial_shipment = false;
    public added_by = '';
    public quality_control_configured = false;



    public total_actual_time = 0;
    public total_actual_time_formatted = 0;

    public total_actual_time_per_part = 0;
    public total_actual_time_per_part_formatted = 0;

    public total_cost = 0;
    public total_cost_per_part = 0;

    public total_overtime = 0;
    public total_overtime_formatted = 0;

    public has_notes = false;

    /*
     ATTACHABLE
     */
    public attachments: Array<Attachment> = [];


    public attach = (attachment: Attachment) => {
        this.attachments.push(attachment);
    };

    public detach = (attachment: Attachment) => {
        console.log('attachable detach');
        console.log(attachment);
        console.log(this.attachments);
        console.log(this.attachments);
        this.attachments = this.attachments.filter(item => {
            console.log(item.id, attachment.id);
            return item.id !== attachment.id;
        });
        console.log(this.attachments);
    };

    public uploadableToAttachment = (uploadable: Uploadable) => {
        const attachment = Object.assign(uploadable.attachment);
        console.log(this.attachments);
        this.attachments.unshift(attachment);

    };

    public deserializeAttachments(input: any) {
        this.attachments = [];
        if (notEmpty(input['attachments'])) {
            for (let i = 0; i < input['attachments'].length; i++) {
                this.attachments.push(new Attachment().deserialize(input['attachments'][i]));
            }
        }
    }


    /*
    SHIPPABLE
    */


    shippable_status;

    getShippableStatusName: () => void;
    shippable_status_name;
    incoming_shipments: Array<Shipment> = [];
    outgoing_shipments: Array<Shipment> = [];
    incoming_percentage = 0;
    outgoing_percentage = 0;
    total_incoming_completed_quantity = 0;
    total_incoming_wasted_quantity = 0;
    total_outgoing_completed_quantity = 0;
    total_outgoing_wasted_quantity = 0;
    addIncomingShipment: (shipment: Shipment) => void;
    addOutgoingShipment: (shipment: Shipment) => void;
    deserializeShippableAttributes: (input: any) => void;
    deserializeIncomingShipments: (input: any) => void;
    deserializeOutgoingShipments: (input: any) => void;


    form_group = new FormGroup({
        proforma_id: new FormControl(
            this.proforma_id,
            Validators.compose([
                Validators.required
            ])
        ),
        proforma_price: new FormControl(
            this.proforma_price,
            Validators.compose([
                Validators.required
            ])
        ),
        customer: new FormControl(
            this.customer,
            Validators.compose([
                HolosenValidators.requiredModel,
            ])
        ),
        part_name: new FormControl(
            this.part_name,
            Validators.compose([
                Validators.required,
            ])
        ),
        quantity: new FormControl(
            this.quantity,
            Validators.compose([
                HolosenValidators.requiredNumber
            ])
        ),
        entry_datetime: new FormControl(
            this.entry_datetime,
            Validators.compose([
                Validators.required,
            ])
        ),
        deadline: new FormControl(
            this.entry_datetime,
            Validators.compose([
                Validators.required,
            ])
        ),
        unit: new FormControl(
            this.unit,
            Validators.compose([
                Validators.required,
            ])
        ),
        material: new FormControl(
            this.material,
            Validators.compose([
                Validators.required,
            ])
        )
    });

    constructor() {
        super();
        this.work_order_status.id = 1;

    }


    public getApiPrefix(): string {
        return WorkOrder.API_PREFIX;
    }

    deserialize(input: Object): WorkOrder {
        this.id = input['id'];
        this.sales_dispatch_number = input['sales_dispatch_number'];
        this.purchase_dispatch_number = input['purchase_dispatch_number'];
        this.proforma_id = input['proforma_id'];

        this.part_name = input['part_name'];
        this.quantity = input['quantity'];
        this.entry_datetime = new Date(input['entry_datetime']);
        this.deadline = new Date(input['deadline']);
        this.estimated_operating_time = input['estimated_operating_time'];
        this.actual_operating_time = input['actual_operating_time'];
        this.additional_notes = input['additional_notes'];
        this.material_supplied_by_us = input['material_supplied_by_us'];
        this.is_service = input['is_service'];
        this.internal_completed = input['internal_completed'];
        this.external_completed = input['external_completed'];
        this.has_external_operations = input['has_external_operations'];
        this.has_internal_operations = input['has_internal_operations'];
        this.is_delayed = input['is_delayed'];
        this.unit = input['unit'];
        this.has_partial_shipment = input['has_partial_shipment'];
        this.material = input['material'];
        this.proforma_price = input['proforma_price'];
        this.added_by = input['added_by'];
        this.quality_control_configured = input['quality_control_configured'];


        this.work_order_status = new WorkOrderStatus().deserialize(input['work_order_status']);
        this.customer = new Customer().deserialize(input['customer']);
        this.required_documents = new RequiredDocuments().deserialize(input['required_documents']);


        if (notEmpty(input['total_actual_time_formatted'])) {
            this.total_actual_time_formatted = input['total_actual_time_formatted'];
        }

        if (notEmpty(input['total_actual_time_per_part_formatted'])) {
            this.total_actual_time_per_part_formatted = input['total_actual_time_per_part_formatted'];
        }

        if (notEmpty(input['total_actual_time'])) {
            this.total_actual_time = input['total_actual_time'];
        }

        if (notEmpty(input['total_actual_time_per_part'])) {
            this.total_actual_time_per_part = input['total_actual_time_per_part'];
        }

        if (notEmpty(input['total_cost'])) {
            this.total_cost = input['total_cost'];
        }

        if (notEmpty(input['total_cost_per_part'])) {
            this.total_cost_per_part = input['total_cost_per_part'];
        }

        if (notEmpty(input['total_overtime'])) {
            this.total_overtime = input['total_overtime'];
        }

        if (notEmpty(input['total_overtime_formatted'])) {
            this.total_overtime_formatted = input['total_overtime_formatted'];
        }


        if (notEmpty(input['has_notes'])) {
            this.has_notes = input['has_notes'];
        }


        if (notEmpty(input['work_order_operations'])) {
            this.work_order_operations = [];
            for (let i = 0; i < input['work_order_operations'].length; i++) {
                this.work_order_operations.push(new WorkOrderOperation().deserialize(input['work_order_operations'][i]));
            }
        }

        this.deserializeAttachments(input);
        this.deserializeShippableAttributes(input);
        return this;


    }

    public addOperation(operation: WorkOrderOperation) {
        this.work_order_operations.push(operation);
    }
}
