<div class="ui container not-found-container">
    <div class="ui grid middle aligned ">
        <div class="column">
            <div class="text-center">
                <h2 class="ui center aligned icon header grey">
                    <i class="meh icon"></i>
                    Sayfa Bulunamadı
                </h2>
                <a [routerLink]="['/']" class="ui button positive basic margin-top-50">
                    Anasayfa'ya Git
                </a>
            </div>
        </div>
    </div>
</div>