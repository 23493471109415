import {BaseModel} from './base.model';
import {EloquentSerializable} from '../interfaces/interfaces';
import {Personnel} from './personnel.model';
import {User} from './user.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HolosenValidators} from '../validators/validators';
import {StartStopLog} from './start-stop-log.model';
import {notEmpty} from '../utils/utils';

export class WorkStation extends BaseModel implements EloquentSerializable<WorkStation> {

    public static readonly API_PREFIX: string = 'work-stations';


    public id: number = null;
    public name = '';
    public code = '';
    public users: Array<User> = [];
    public user_id_list: Array<number> = [];
    public open_log: StartStopLog = null;


    form_group = new FormGroup({
        name: new FormControl(
            this.name,
            Validators.compose([
                Validators.required
            ])
        ),
        code: new FormControl(
            this.code,
            Validators.compose([
                Validators.required
            ])
        )
    });

    public getApiPrefix(): string {
        return WorkStation.API_PREFIX;
    }


    deserialize(input: JSON): WorkStation {
        this.id = input['id'];
        this.name = input['name'];
        this.code = input['code'];
        this.user_id_list = input['user_id_list'];

        if (notEmpty(input['users'])) {
            for (let i = 0; i < input['users'].length; i++) {
                this.users.push(new User().deserialize(input['users'][i]));
            }
        }

        if (notEmpty(input['open_log'])) {
            this.open_log = new StartStopLog().deserialize(input['open_log']);
        }


        return this;
    }
}
