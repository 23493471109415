import {NgModule} from '@angular/core';
import {SharedModule} from '../../../shared/modules/shared.module';
import {PlanningComponent} from './planning.component';
import {LogHistoryComponent} from '../../../shared/components/log-history/log-history.component';
import {UploaderComponent} from '../../../shared/components/uploader/uploader.component';
import {ShipmentHistoryComponent} from '../../../shared/components/shipment-history/shipment-history.component';
import {ConfirmationComponent} from '../../../shared/components/confirmation/confirmation.component';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [PlanningComponent],
    exports: [
        PlanningComponent
    ],
    // entryComponents: [
    //     LogHistoryComponent,
    //     ShipmentHistoryComponent,
    //     UploaderComponent,
    //     ConfirmationComponent
    // ]
})
export class PlanningModule {
}

