import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {EloquentManagerService} from '../../../shared/eloquent/providers/eloquent-manager.provider';
import {LayoutService} from '../../../shared/services/layout/layout.service';
import {WorkStation} from '../../../shared/eloquent/models/work-station.model';
import {User} from '../../../shared/eloquent/models/user.model';

declare const $: any;

@Component({
    selector: 'app-work-station',
    templateUrl: './work-station.component.html',
    styleUrls: ['./work-station.component.scss']
})
export class WorkStationComponent implements OnInit, AfterViewInit {

    pageTitle = 'İş İstasyonları';

    stationList: Array<WorkStation> = [];
    private userList: Array<User> = [];
    isStationsLoading = true;

    editingStation: WorkStation = new WorkStation();

    @ViewChild('stationAccordion', {static: false})
    stationAccordion: ElementRef;

    constructor(private eloquentManager: EloquentManagerService,
                private layoutService: LayoutService) {

        this.layoutService.setTitle(this.pageTitle);
    }

    ngOnInit() {
        this.getWorkStationList();
        this.getUserList();
    }


    ngAfterViewInit(): void {

        $(this.stationAccordion.nativeElement).accordion(
            {
                selector: {
                    trigger: '.title .accordion-handle'
                },
                onOpening: function () {
                    console.log('onOpening');
                },
                onOpen: (event) => {
                    const openIndex = $('.ui.accordion .title.active');

                    $('html,body').animate({
                            scrollTop: openIndex.offset().top - 75
                        },
                        450);
                }
            }
        );
    }

    getWorkStationList = () => {
        this.eloquentManager.list(WorkStation).subscribe((response) => {
            if (response) {
                this.stationList = response;
                console.log(this.stationList);
            }
            this.isStationsLoading = false;
        });
    };


    getUserList = () => {
        this.eloquentManager.list(User).subscribe((response) => {
            if (response) {
                this.userList = response;
                console.log(this.userList);
            }
            this.isStationsLoading = false;
        });
    };

    togglePersonnelStation = (station, user_id) => {
        if (station.user_id_list.includes(user_id)) {
            station.user_id_list = station.user_id_list.filter(item => item !== user_id);
        } else {
            station.user_id_list.push(user_id);
        }
    };

    updateStation = (station: WorkStation) => {
        this.eloquentManager.update(station).subscribe();
    };

    openStationModal = (station?: WorkStation) => {
        if (station) {
            this.editingStation = station;
        } else {
            this.editingStation = new WorkStation();
        }

        $('#stationModal').modal({
            closable: false
        }).modal('show');
    };


    saveStation = () => {
        if (this.editingStation.id) {
            this.eloquentManager.update(this.editingStation).subscribe((response) => {
                if (response) {
                    $('#stationModal').modal('hide');
                }
            });
        } else {
            this.eloquentManager.save(this.editingStation).subscribe((response) => {
                console.log(response);
                if (response) {
                    this.stationList.push(response);
                    $('#stationModal').modal('hide');
                }
            });
        }
    };
}
