<div class="shipment-history-container">
    <div class="ui large header dividing margin-bottom-30 padding-left-30 padding-right-30">
        {{title}}
    </div>

    <fui-dimmer class="inverted" [(isDimmed)]="isShipmentsLoading" [isClickable]="false">
        <div class="ui text loader">Yükleniyor</div>
    </fui-dimmer>

    <div *ngIf="shipmentList && shipmentList.length == 0">
        <div class="ui grid centered margin-top-75">
            <h2 class="ui icon header grey">
                <i class="warning circle icon"></i>
                <div class="content">
                    Kayıt Bulunamadı
                    <div class="sub header">Kayıt eklemek için <b>Sevkiyat -> Yeni Kayıt Ekle</b> bağlantısını
                        kullanabilirsiniz.
                    </div>
                </div>
            </h2>
        </div>
    </div>
    <ng-container *ngIf="action == ADD_SHIPMENT_HISTORY">
        <form novalidate [formGroup]="newShipment.form_group">

            <div class="ui form">

                <div class="field">
                    <fui-checkbox
                            [(ngModel)]="newShipment.quality_checked"
                            formControlName="quality_checked"
                    >
                        Kalite Kontrol Yapıldı
                    </fui-checkbox>
                    <div class="clearfix"></div>
                    <div class="ui pointing red basic label"
                         *ngIf="newShipment.form('quality_checked').hasError('requiredTrue') && (newShipment.form('quality_checked').dirty || newShipment.form('quality_checked').touched)">
                        Kalite Kontrol Yapılmadan Sevkiyat Eklenemez!
                    </div>
                </div>
                <div class="ui divider"></div>

                <div class="field">
                    <label>Sevkiyat Yönü</label>
                    <fui-select class="selection"
                                [(ngModel)]="newShipment.is_incoming"
                                formControlName="is_incoming"
                                [options]="[{value: true, name: 'Gelen Sevkiyat'},{value: false, name: 'Giden Sevkiyat'}]"
                                [optionTemplate]="isIncomingOptionTemplate"
                                [isSearchable]="false"
                                valueField="value"
                                labelField="name"
                                #isIncomingMenu>
                        <fui-select-option *ngFor="let o of isIncomingMenu.filteredOptions"
                                           [value]="o"></fui-select-option>
                    </fui-select>
                    <ng-template let-option #isIncomingOptionTemplate>
                        {{option.name}}
                    </ng-template>
                </div>

                <div class="field"
                     [ngClass]="{'error': newShipment.form('dispatch_number').hasError('required') && (newShipment.form('dispatch_number').dirty || newShipment.form('dispatch_number').touched)}">
                    <label>
                        {{(newShipment.is_incoming ? "Alış" : "Satış")}} İrsaliyesi Numarası
                    </label>
                    <input type="text"
                           formControlName="dispatch_number"
                           [(ngModel)]="newShipment.dispatch_number">

                    <div class="ui pointing red basic label"
                         *ngIf="newShipment.form('dispatch_number').hasError('required') && (newShipment.form('dispatch_number').dirty || newShipment.form('dispatch_number').touched)">
                        Boş Bırakılamaz
                    </div>
                </div>


                <div class="field"
                     [ngClass]="{'error': newShipment.form('completed_quantity').hasError('requiredNumber') && (newShipment.form('completed_quantity').dirty || newShipment.form('completed_quantity').touched)}">
                    <label>Toplam Tamamlanan</label>
                    <div class="ui right labeled input">
                        <input type="number"
                               formControlName="completed_quantity"
                               [(ngModel)]="newShipment.completed_quantity">
                        <div class="ui label basic">
                            {{newShipment.unit_display}}
                        </div>
                    </div>
                    <div class="ui pointing red basic label"
                         *ngIf="newShipment.form('completed_quantity').hasError('requiredNumber') && (newShipment.form('completed_quantity').dirty || newShipment.form('completed_quantity').touched)">
                        Boş Bırakılamaz
                    </div>
                    <small class="hint">
                        Bir önceki kayıttan itibaren tamamlanan
                    </small>

                </div>

                <div class="field"
                     [ngClass]="{'error': newShipment.form('wasted_quantity').hasError('requiredNumber') && (newShipment.form('wasted_quantity').dirty || newShipment.form('wasted_quantity').touched)}">
                    <label>Toplam Fire Verilen</label>
                    <div class="ui right labeled input">
                        <input type="number"
                               formControlName="wasted_quantity"
                               [(ngModel)]="newShipment.wasted_quantity">
                        <div class="ui label basic">
                            {{newShipment.unit_display}}
                        </div>
                    </div>
                    <div class="ui pointing red basic label"
                         *ngIf="newShipment.form('wasted_quantity').hasError('requiredNumber') && (newShipment.form('wasted_quantity').dirty || newShipment.form('wasted_quantity').touched)">
                        Boş Bırakılamaz
                    </div>
                    <small class="hint">
                        Bir önceki kayıttan itibaren fire verilen
                    </small>

                </div>

                <div class="field"
                     [ngClass]="{'error': newShipment.form('shipping_date').hasError('required') && (newShipment.form('shipping_date').dirty || newShipment.form('shipping_date').touched)}">
                    <label>Sevkiyat Tarihi</label>
                    <button class="ui basic blue button"
                            fuiDatepicker
                            [pickerMode]="'date'"
                            [(ngModel)]="newShipment.shipping_date"
                            [ngModelOptions]="{standalone: true}"
                            [pickerFirstDayOfWeek]="1"
                    >
                        <i class="calendar icon"></i> {{newShipment.shipping_date | date: 'dd.MM.yyyy'}}
                    </button>

                    <div class="ui pointing red basic label"
                         *ngIf="newShipment.form('shipping_date').hasError('required') && (newShipment.form('shipping_date').dirty || newShipment.form('shipping_date').touched)">
                        Boş Bırakılamaz
                    </div>
                </div>

                <div class="field"
                     [ngClass]="{'error': newShipment.form('additional_notes').hasError('required') && (newShipment.form('additional_notes').dirty || newShipment.form('additional_notes').touched)}">
                    <label>Ek Notlar</label>
                    <textarea
                            rows="6"
                            placeholder="Ek Notlar"
                            formControlName="additional_notes"
                            [(ngModel)]="newShipment.additional_notes"
                    ></textarea>
                    <div class="ui pointing red basic label"
                         *ngIf="newShipment.form('additional_notes').hasError('required') && (newShipment.form('additional_notes').dirty || newShipment.form('additional_notes').touched)">
                        Boş Bırakılamaz
                    </div>
                </div>


                <div class="field margin-top-20">
                    <button class="ui button positive right floated" (click)="saveShipment()">
                        Kaydet
                    </button>
                </div>
            </div>
        </form>
    </ng-container>
    <ng-container *ngIf="action == LIST_SHIPMENT_HISTORY">
        <div *ngIf="shipmentList && shipmentList.length > 0">
            <fui-tabset>
                <div class="ui top attached tabular menu">
                    <a class="item" fuiTabHeader="1">
                        Gelen Sevkiyatlar (%{{shippable.incoming_percentage | number:'.0-1'}})
                    </a>
                    <a class="item" fuiTabHeader="2">
                        Giden Sevkiyatlar (%{{shippable.outgoing_percentage | number:'.0-1'}})
                    </a>
                </div>
                <div class="ui bottom attached segment" fuiTabContent="1">
                    <div class="ui segment basic fluid">
                        <div class="ui statistics margin-top-40 margin-bottom-40">

                            <div class="statistic">
                                <div class="label">
                                    Toplam
                                </div>
                                <div class="value">
                                    {{shippable.quantity}}
                                </div>
                                <div class="label">
                                    {{shippable.unit_display}}
                                </div>
                            </div>
                            <div class="statistic green">
                                <div class="label">
                                    Toplam Tamamlanan
                                </div>
                                <div class="value">
                                    <i class="check icon"></i>
                                    {{shippable.total_incoming_completed_quantity}}
                                </div>
                                <div class="label">
                                    {{shippable.unit_display}}
                                </div>
                            </div>
                            <div class="statistic red">
                                <div class="label">
                                    Toplam Fire
                                </div>
                                <div class="value">
                                    <i class="close icon"></i>
                                    {{shippable.total_incoming_wasted_quantity}}
                                </div>
                                <div class="label">
                                    {{shippable.unit_display}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ui small feed">
                        <div class="event" *ngFor="let shipment of shipmentList | filter: 'is_incoming':true">
                            <div class="label">
                                <img src="./assets/images/joe.jpg">
                            </div>
                            <div class="content">
                                <div class="summary">
                                    <a class="user">
                                        {{shipment.shipped_by}}
                                    </a> tarafından eklendi
                                    <div class="date">
                                        {{shipment.created_at | date: 'dd.MM.yyyy'}}
                                    </div>
                                    <div class="ui right floated">
                                        <div class="ui button mini red" (click)="deleteShipment(shipment)">
                                            Sil
                                        </div>
                                    </div>
                                </div>
                                <div class="extra text" *ngIf="shipment.additional_notes">
                                    {{shipment.additional_notes}}
                                </div>
                                <div class="extra">
                                    <div fuiPopup
                                         [popupText]="shipment.is_incoming ? 'Gelen Sevkiyat' : 'Giden Sevkiyat'"
                                    >
                                        <i class="industry icon large grey"></i>
                                        <i class="angle double icon"
                                           [ngClass]="{'left': shipment.is_incoming  , 'right': !shipment.is_incoming}"></i>
                                        <i class="truck icon large grey"
                                           [ngClass]="{'flipped': !shipment.is_incoming}"></i>

                                    </div>
                                    <div class="ui tiny statistics">
                                        <div class="green statistic">
                                            <div class="value">
                                                {{shipment.completed_quantity}}
                                            </div>
                                            <div class="label">
                                                {{shippable.unit_display}} Tamamlandı
                                            </div>
                                        </div>
                                        <div class="red mini statistic">
                                            <div class="value">
                                                {{shipment.wasted_quantity}}
                                            </div>
                                            <div class="label">
                                                {{shippable.unit_display}} Fire Verildi
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ui bottom attached segment" fuiTabContent="2">
                    <div class="ui segment basic fluid">
                        <div class="ui statistics margin-top-40 margin-bottom-40">

                            <div class="statistic">
                                <div class="label">
                                    Toplam
                                </div>
                                <div class="value">
                                    {{shippable.quantity}}
                                </div>
                                <div class="label">
                                    {{shippable.unit_display}}
                                </div>
                            </div>
                            <div class="statistic green">
                                <div class="label">
                                    Toplam Tamamlanan
                                </div>
                                <div class="value">
                                    <i class="check icon"></i>
                                    {{shippable.total_outgoing_completed_quantity}}
                                </div>
                                <div class="label">
                                    {{shippable.unit_display}}
                                </div>
                            </div>
                            <div class="statistic red">
                                <div class="label">
                                    Toplam Fire
                                </div>
                                <div class="value">
                                    <i class="close icon"></i>
                                    {{shippable.total_outgoing_wasted_quantity}}
                                </div>
                                <div class="label">
                                    {{shippable.unit_display}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ui small feed">
                        <div class="event" *ngFor="let shipment of shipmentList | filter: 'is_incoming':false">
                            <div class="label">
                                <img src="./assets/images/joe.jpg">
                            </div>
                            <div class="content">
                                <div class="summary">
                                    <a class="user">
                                        {{shipment.shipped_by}}
                                    </a> tarafından eklendi
                                    <div class="date">
                                        {{shipment.created_at | date: 'dd.MM.yyyy'}}
                                    </div>
                                    <div class="ui right floated">
                                        <div class="ui button mini red"  (click)="deleteShipment(shipment)">
                                            Sil
                                        </div>
                                    </div>
                                </div>
                                <div class="extra text" *ngIf="shipment.additional_notes">
                                    {{shipment.additional_notes}}
                                </div>
                                <div class="extra">
                                    <div fuiPopup
                                         [popupText]="shipment.is_incoming ? 'Gelen Sevkiyat' : 'Giden Sevkiyat'"
                                    >
                                        <i class="industry icon large grey"></i>
                                        <i class="angle double icon"
                                           [ngClass]="{'left': shipment.is_incoming  , 'right': !shipment.is_incoming}"></i>
                                        <i class="truck icon large grey"
                                           [ngClass]="{'flipped': !shipment.is_incoming}"></i>

                                    </div>
                                    <div class="ui tiny statistics">
                                        <div class="green statistic">
                                            <div class="value">
                                                {{shipment.completed_quantity}}
                                            </div>
                                            <div class="label">
                                                {{shippable.unit_display}} Tamamlandı
                                            </div>
                                        </div>
                                        <div class="red mini statistic">
                                            <div class="value">
                                                {{shipment.wasted_quantity}}
                                            </div>
                                            <div class="label">
                                                {{shippable.unit_display}} Fire Verildi
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fui-tabset>

        </div>
    </ng-container>
</div>






<div class="ui small basic modal" #deleteShipmentConfirmModal>
    <div class="ui icon header">
        <i class="remove from calendar icon"></i>
        Silme İşlemini Onayla
    </div>
    <div class="content" style="text-align: center !important">
        Bu işlem geri alınamaz. Silmek istediğinize emin misiniz?
    </div>
    <div class="actions">
        <div class="ui red basic cancel inverted button">
            <i class="remove icon"></i>
            Vazgeç
        </div>
        <div class="ui green ok inverted button">
            <i class="checkmark icon"></i>
            Devam Et
        </div>
    </div>
</div>
