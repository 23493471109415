import {Injectable} from '@angular/core';
import Echo from 'laravel-echo';
import {StorageService} from '../storage/storage.service';
import {Observable, Subject, Subscription} from 'rxjs';
import {AuthenticationService} from '../authentication/authentication.service';

declare const io: any;


@Injectable()
export class WebSocketService {


    echo: any;
    private onErrorSubject: Subject<any> = new Subject<any>();
    onError: Observable<any>;

    private onEloquentEventSubject: Subject<any> = new Subject<any>();
    onEloquentEvent: Observable<any>;

    private authLoggedInSubscripton: Subscription;

    private isListening = false;

    constructor(private storageService: StorageService,
                private authenticationService: AuthenticationService) {

        this.onError = this.onErrorSubject.asObservable();
        this.onEloquentEvent = this.onEloquentEventSubject.asObservable();


        this.subscribeAuthenticationService();

    }


    listen = () => {

        if (typeof io === 'undefined') {

            this.onErrorSubject.next(
                {
                    message: 'Socket.io cannot be loaded.'
                }
            );

            console.log('Socket.io cannot be loaded.');
            return;
        }
        const tokenData = this.storageService.get(StorageService.VARS.TOKEN_DATA);

        try {
            this.echo = new Echo({
                broadcaster: 'socket.io',
                host: 'http://api.planbu.bo-tek.com.tr:6001',
                auth: {
                    headers: {
                        Accept: 'application/json',
                        Authorization: tokenData.token_type + ' ' + tokenData.access_token
                    }
                },
            });


            this.echo.private('eloquent-channel')
                .listen('.eloquent', (e) => {
                    this.onEloquentEventSubject.next(e);
                });

            this.isListening = true;
        } catch (e) {
            console.log('Cannot connect to Socket.io server.');
        }


    };


    quit = () => {
        if (typeof io !== 'undefined') {
            if (this.echo) {
                this.echo.leave('eloquent-channel');
                this.isListening = false;
            }
        }
    };


    subscribeAuthenticationService = () => {
        this.authLoggedInSubscripton = this.authenticationService.isLoggedIn.subscribe((isLoggedIn) => {
            if (isLoggedIn) {
                if (!this.isListening) {
                    this.listen();
                }
            } else {
                if (this.isListening) {
                    this.quit();
                }

            }
        });
    };
}
