import {MeComponent} from './me.component';


export const MeRoutes = [
    {
        path: 'me',
        component: MeComponent,
        data: {
        }
    }
];