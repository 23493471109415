<app-dynamic-component #dynamicComponent></app-dynamic-component>

<div class="ui container padding-bottom-75 content-background very padded basic segment">

    <fui-tabset>
        <div class="ui pointing secondary menu">
            <a class="item" fuiTabHeader="1">İmalat Raporları</a>
            <a class="item" fuiTabHeader="2">Kalite Kontrol Raporu</a>
            <a class="item" fuiTabHeader="3">Ortalamalar</a>
        </div>
        <div class="ui segment" fuiTabContent="1">
            <fui-dimmer class="inverted" [(isDimmed)]="isPaginationLoading" [isClickable]="false">
                <div class="ui text loader">Yükleniyor</div>
            </fui-dimmer>
            <div class="ui attached top">
                <div class="ui grid divided">
                    <div class="ui row">
                        <div class="four wide column">
                            <div class="ui icon input fluid">
                                <input type="text"
                                       placeholder="Parça adı ya da Teklif Numarası..."
                                       [formControl]="searchBarCtrl"
                                       [value]="pagination.keyword"
                                       #searchBarInput
                                >
                                <i class="search icon"
                                   *ngIf="paginateSubscription?.closed && (searchBarInput.value.length == 0)"></i>
                                <i class="refresh loading icon"
                                   *ngIf="!paginateSubscription?.closed"></i>
                                <i class="circular remove link icon"
                                   *ngIf="paginateSubscription?.closed && (searchBarInput.value.length > 0)"
                                   (click)="resetSearchValue()"></i>

                            </div>
                        </div>

                        <div class="four wide column">
                            <div class="ui dropdown basic button fluid" fuiDropdown [(isOpen)]="isCustomerDropdownOpen">
                                <div class="text truncate">
                                    {{pagination.filter.customer ? (pagination.filter.customer.title | slice: 0:7) :
                                    'Müşteri'}}
                                </div>
                                <i class="dropdown icon"></i>
                                <div class="menu" fuiDropdownMenu>
                                    <div class="padding-15">
                                        <button class="ui button basic red fluid" (click)="filterByCustomer(null);">
                                            Filtreyi Kaldır
                                            <i class="remove icon"></i>
                                        </button>
                                    </div>
                                    <div class="padding-15">
                                        <fui-search
                                                class="ui fluid input"
                                                placeholder="Yazmaya başlayın..."
                                                [optionsLookup]="searchCustomers"
                                                optionsField="title"
                                                (resultSelected)="filterByCustomer($event)"
                                                [retainSelectedResult]="false"
                                        >
                                        </fui-search>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="three wide column">
                            <div class="ui dropdown basic button fluid" fuiDropdown>
                                Sırala
                                <i class="dropdown icon"></i>
                                <div class="menu" fuiDropdownMenu>
                                    <div class="item" *ngFor="let orderType of orderList" (click)="orderBy(orderType)"
                                         [ngClass]="{'active': pagination.order === orderType}">
                                        {{orderType.name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="five wide column">
                            <app-pagination [pagination]="pagination" (pageChange)="onPageChange()"></app-pagination>
                        </div>
                    </div>
                </div>

            </div>
            <div class="ui segments accordion" #operationAccordion>
                <div class="ui right rail">
                    <div class="ui segment">
                        <div>
                            <b>Sayfa Toplamı:</b>
                        </div>
                        <div>
                            <i class="lira sign icon"></i>{{filteredSum | number: '1.2-2'}}
                        </div>

                    </div>
                </div>
                <ng-container *ngFor="let order of workOrderList">
                    <div class="ui segment padding-0 title">
                        <table class="ui table very padded fluid">
                            <tr>
                                <td class="order-status-cell center-vertical center-horizontal">
                                    <div class="ui mini label"
                                         [style.color]="'#ffffff'"
                                         [style.background-color]="order.work_order_status.color">
                                        {{order.work_order_status.name}}
                                    </div>
                                    <div class="order-id margin-top-20">
                                        #{{order.id}}
                                    </div>
                                </td>
                                <td class="order-id-cell center-vertical">
                                    <div class="detail-label margin-top-5">
                                        Müşteri
                                    </div>
                                    <div class="truncate">
                                        <b>{{order.customer.title}}</b>
                                    </div>
                                    <div class="detail-label margin-top-10">
                                        Parça Adı
                                    </div>
                                    <div class="truncate">
                                        <b>{{order.part_name}}</b>
                                    </div>
                                </td>
                                <td class="deadline-cell center-vertical">
                                    <div class="detail-label">
                                        Toplam Parça
                                    </div>
                                    <div class="truncate">
                                        <b>{{order.quantity}} Adet</b>
                                    </div>
                                    <div class="detail-label margin-top-5">
                                        Termin Tarihi
                                    </div>
                                    <div class="truncate">
                                        <b>{{order.deadline | date: 'dd.MM.yyyy'}}</b>
                                    </div>
                                </td>
                                <td class="actual-time-cell">
                                    <div class="detail-label">
                                        Ort. Süre / Adet
                                    </div>
                                    <div class="truncate">
                                        <b>{{order.total_actual_time_per_part_formatted}}</b>
                                    </div>
                                    <div class="detail-label margin-top-5">
                                        Toplam Süre
                                        <a class="ui mini red label" *ngIf="order.total_overtime > 0">F.M.</a>
                                        <a class="ui mini yellow label icon" *ngIf="order.has_notes"><i
                                                class="comment icon"></i> Yorum</a>
                                    </div>
                                    <div class="truncate">
                                        <b>{{order.total_actual_time_formatted}}</b>
                                        <div *ngIf="order.total_overtime > 0">
                                            <small>(F.M.: {{order.total_overtime_formatted}})</small>
                                        </div>
                                    </div>
                                </td>
                                <td class="actual-time-cell">
                                    <div class="detail-label">
                                        Ort. Mal. / Adet
                                    </div>
                                    <div class="truncate">
                                        <b><i class="lira sign icon"></i>{{(order.total_actual_time_per_part * 120 /
                                            3600) | number:'1.2-2'}}</b>
                                    </div>
                                    <div class="detail-label margin-top-5">
                                        Toplam Maliyet
                                    </div>
                                    <div class="truncate">
                                        <b><i class="lira sign icon"></i>{{(order.total_actual_time * 120 / 3600) |
                                            number:'1.2-2'}}</b>
                                    </div>
                                </td>
                                <td class="accordion-handle-cell">
                                    <div class="accordion-handle">
                                        <i class="chevron down icon"></i>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="ui segment padded content">
                        <div class="ui header margin-bottom-15 grey">
                            Operasyonlar
                        </div>
                        <table class="ui celled table">
                            <thead>
                            <tr>
                                <th class="op-status-cell">Durum</th>
                                <th>Tipi</th>
                                <th>Açıklama</th>
                                <th>İstasyon</th>
                                <th>Operatör</th>
                                <th>Ort. Süre / Adet</th>
                                <th>Toplam Süre (F.M. Dahil)</th>
                                <th>Toplam F.M.</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let operation of order.work_order_operations">
                                <td class="op-status-cell center-vertical center-horizontal">
                                    <div class="ui mini label"
                                         [style.color]="'#ffffff'"
                                         [style.background-color]="operation.work_order_operation_status.color">
                                        {{operation.work_order_operation_status.name}}
                                    </div>
                                    <a class="ui mini yellow label icon" *ngIf="operation.has_notes"><i
                                            class="comment icon"></i> Yorum</a>
                                </td>
                                <td class="op-type-cell">
                                    {{operation.work_order_operation_type.name}}
                                </td>
                                <td class="op-desc-cell truncate">
                                    {{operation.description}}
                                </td>
                                <td class="op-station-cell">
                                    {{operation.work_station.code}}
                                </td>
                                <td class="op-operator-cell">
                                    {{operation.user.name}}
                                </td>
                                <td class="op-completion-cell">
                                    {{operation.total_actual_time_per_part_formatted}}
                                </td>
                                <td class="op-start-cell">
                                    {{operation.total_actual_time_formatted}}
                                </td>
                                <td class="op-deadline-cell"
                                    [ngClass]="{'negative': operation.total_overtime > 0}"
                                    [style.font-weight]="(operation.total_overtime > 0) ? 'bold' : ''">
                                    {{operation.total_overtime_formatted}}
                                </td>
                                <td class="op-deadline-cell">
                                    <div class="ui button mini blue" (click)="getStartStopLogsByOperation(operation)">
                                        Detaylar
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </ng-container>
            </div>

        </div>
        <div class="ui segment" fuiTabContent="2">

            <div class="ui form">
                <div class="field">
                    <label>Raporlama Türü</label>
                    <fui-select class="selection"
                                [(ngModel)]="qcFilter.mode"
                                [options]="qcFilter.modes"
                                valueField="value"
                                [optionTemplate]="qcReportModeSelectOptionsTemplate"
                                #qcReportModeSelect>
                        <fui-select-option *ngFor="let o of qcReportModeSelect.filteredOptions"
                                           [value]="o"></fui-select-option>
                    </fui-select>
                    <ng-template let-option #qcReportModeSelectOptionsTemplate>
                        {{option.name}}
                    </ng-template>
                </div>

                <div class="two fields" *ngIf="qcFilter.mode === 'date-range' || qcFilter.mode === 'customer'">
                    <div class="field">
                        <label>Başlangıç Tarihi</label>
                        <div class="input">
                            <button class="ui basic blue button"
                                    fuiDatepicker
                                    [pickerMode]="'date'"
                                    [(ngModel)]="qcFilter.dates.start"
                                    [pickerMaxDate]="qcFilter.dates.end"
                                    [pickerFirstDayOfWeek]="1"
                            >
                                <i class="calendar icon"></i> {{qcFilter.dates.start | date: 'dd.MM.yyyy'}}
                            </button>
                        </div>
                    </div>
                    <div class="field">
                        <label>Bitiş Tarihi</label>
                        <div class="input">
                            <button class="ui basic blue button"
                                    fuiDatepicker
                                    [pickerMode]="'date'"
                                    [(ngModel)]="qcFilter.dates.end"
                                    [pickerMinDate]="qcFilter.dates.start"
                                    [pickerFirstDayOfWeek]="1"
                            >
                                <i class="calendar icon"></i> {{qcFilter.dates.end | date: 'dd.MM.yyyy'}}
                            </button>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="qcFilter.mode === 'customer'">
                    <div class="field"
                         *ngIf="(!qcFilter.customer?.id || isCustomerChanging)">
                        <label>Müşteri Ara:
                            <a class="mini ui label basic red margin-left-20" (click)="changeCustomer(false)"
                               *ngIf="isCustomerChanging">
                                Vazgeç <i class="icon close"></i>
                            </a></label>
                        <fui-search
                                class="ui fluid input"
                                placeholder="Yazmaya başlayın..."
                                [optionsLookup]="searchCustomers"
                                optionsField="title"
                                (resultSelected)="customerSelected($event)"
                        >
                        </fui-search>
                    </div>
                    <div class="field" *ngIf="qcFilter.customer?.id && !isCustomerChanging">
                        <label>Müşteri Bilgileri</label>
                        <div class="ui card customer-card fluid">

                            <div class="content">
                                <div class="header">
                                    {{qcFilter.customer.title}}
                                </div>
                                <div class="description">
                                    <div>
                                        <i class="icon envelope"></i>
                                        {{qcFilter.customer.email}}
                                    </div>
                                    <div>
                                        <i class="icon phone"></i>
                                        {{qcFilter.customer.phone}}
                                    </div>
                                </div>
                            </div>
                            <div class="content">
                                <a class="mini ui label basic icon right floated red" (click)="changeCustomer(true)">
                                    Değiştir <i class="close icon"></i>
                                </a>

                            </div>
                        </div>


                    </div>
                </ng-container>
                <div class="field" *ngIf="qcFilter.mode === 'single'">
                    <label>İş Emri Numarası</label>
                    <input type="number" [(ngModel)]="qcFilter.work_order_id">
                </div>
            </div>
            <div class="ui button green" (click)="createQualityControlReport()">QC Rapor Al</div>


        </div>
        <div class="ui segment" fuiTabContent="3">
            <fui-dimmer class="inverted" [(isDimmed)]="isAverageLoading" [isClickable]="false">
                <div class="ui text loader">Yükleniyor</div>
            </fui-dimmer>
            <div class="ui form">
                <div class="inline field">
                    <label>Son</label>
                    <fui-select class="selection"
                                [(ngModel)]="averageTimeRange"
                                [options]="[1,5,7,10,15,30,60,90]"
                                (selectedOptionChange)="getAverageTimes()"
                                #averageTimeRangeMenu
                    >

                        <fui-select-option *ngFor="let o of averageTimeRangeMenu.filteredOptions"
                                           [value]="o"></fui-select-option>
                    </fui-select>
                    <label>gün</label>
                </div>
            </div>
            <div class="divider"></div>
            <div class="ui grid equal width relaxed">
                <div class="ui column">
                    <div class="ui header dividing">
                        İş İstasyonu Bazlı
                        <small>Son {{averageTimeRange}} Gün</small>
                    </div>
                    <table class="ui table stripped padded">
                        <tr *ngFor="let ws of averageTimes.work_stations">
                            <td>{{ws.code}} | {{ws.work_station}}</td>
                            <td>{{ws.average}}</td>
                        </tr>
                    </table>
                </div>
                <div class="ui column">
                    <div class="ui header dividing">
                        Personel Bazlı
                        <small>Son {{averageTimeRange}} Gün</small>
                    </div>
                    <table class="ui table stripped padded">
                        <tr *ngFor="let usr of averageTimes.users">
                            <td>{{usr.user}}</td>
                            <td>{{usr.average}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </fui-tabset>


</div>
