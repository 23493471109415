import {NgModule} from '@angular/core';
import {HolosenPdfViewerComponent} from './holosen-pdf-viewer.component';
import {SharedImportsModule} from '../../modules/shared-imports.module';
import {PdfViewerModule} from 'ng2-pdf-viewer';

@NgModule({
    imports: [
        SharedImportsModule,
        PdfViewerModule
    ],
    declarations: [
        HolosenPdfViewerComponent
    ],
    exports: [
        HolosenPdfViewerComponent
    ]
})
export class HolosenPdfViewerModule {
}
