<div class="log-history-container">
    <div class="ui large header dividing margin-bottom-30 padding-left-30 padding-right-30">
        {{title}}
    </div>

    <fui-dimmer class="inverted" [(isDimmed)]="isLogsLoading" [isClickable]="false">
        <div class="ui text loader">Yükleniyor</div>
    </fui-dimmer>

    <ng-container *ngIf="action == ADD_LOG_HISTORY">
        <form novalidate [formGroup]="newLog.form_group">

            <div class="ui form">
                <div class="field"
                     [ngClass]="{'error': newLog.form('completed_num_of_parts').hasError('requiredNumber') && (newLog.form('completed_num_of_parts').dirty || newLog.form('completed_num_of_parts').touched)}">
                    <label>Tamamlanan Parça Sayısı</label>
                    <input type="number"
                           formControlName="completed_num_of_parts"
                           [(ngModel)]="newLog.completed_num_of_parts">
                    <div class="ui pointing red basic label"
                         *ngIf="newLog.form('completed_num_of_parts').hasError('requiredNumber') && (newLog.form('completed_num_of_parts').dirty || newLog.form('completed_num_of_parts').touched)">
                        Boş Bırakılamaz
                    </div>
                    <small class="hint">
                        Bir önceki kayıttan itibaren tamamlanan parça sayısı
                    </small>

                </div>

                <div class="field"
                     [ngClass]="{'error': newLog.form('wasted_num_of_parts').hasError('requiredNumber') && (newLog.form('wasted_num_of_parts').dirty || newLog.form('wasted_num_of_parts').touched)}">
                    <label>Fire Verilen Parça Sayısı</label>
                    <input type="number"
                           formControlName="wasted_num_of_parts"
                           [(ngModel)]="newLog.wasted_num_of_parts">
                    <div class="ui pointing red basic label"
                         *ngIf="newLog.form('wasted_num_of_parts').hasError('requiredNumber') && (newLog.form('wasted_num_of_parts').dirty || newLog.form('wasted_num_of_parts').touched)">
                        Boş Bırakılamaz
                    </div>
                    <small class="hint">
                        Bir önceki kayıttan itibaren fire verilen parça sayısı
                    </small>

                </div>

                <div class="field"
                     [ngClass]="{'error': newLog.form('additional_notes').hasError('required') && (newLog.form('additional_notes').dirty || newLog.form('additional_notes').touched)}">
                    <label>Ek Notlar</label>
                    <textarea
                            rows="6"
                            placeholder="Ek Notlar"
                            formControlName="additional_notes"
                            [(ngModel)]="newLog.additional_notes"
                    ></textarea>
                    <div class="ui pointing red basic label"
                         *ngIf="newLog.form('additional_notes').hasError('required') && (newLog.form('additional_notes').dirty || newLog.form('additional_notes').touched)">
                        Boş Bırakılamaz
                    </div>
                </div>

                <div class="field margin-top-20">
                    <button class="ui button positive right floated" (click)="saveLog()">
                        Kaydet
                    </button>
                </div>
            </div>
        </form>
    </ng-container>

    <ng-container *ngIf="action === LIST_LOG_HISTORY">
        <div *ngIf="logList && logList.length == 0">
            <div class="ui grid centered margin-top-75">
                <h2 class="ui icon header grey">
                    <i class="warning circle icon"></i>
                    <div class="content">
                        Kayıt Bulunamadı
                        <div class="sub header">Kayıt eklemek için <b>Günlük -> Yeni Kayıt Ekle</b> bağlantısını
                            kullanabilirsiniz.
                        </div>
                    </div>
                </h2>
            </div>
        </div>
        <div *ngIf="logList && logList.length > 0">
            <div class="ui segment basic fluid">
                <div class="ui two statistics">
                    <div class="statistic green">
                        <div class="value">
                            <i class="check icon"></i>
                            {{operation.completed_num_of_parts}}
                        </div>
                        <div class="label">
                            Parça Tamamlandı
                        </div>
                    </div>
                    <div class="statistic red">
                        <div class="value">
                            <i class="close icon"></i>
                            {{operation.wasted_num_of_parts}}
                        </div>
                        <div class="label">
                            Fire Verildi
                        </div>
                    </div>
                </div>
            </div>
            <div class="ui small feed">
                <div class="event" *ngFor="let log of logList">
                    <div class="label">
                        <img src="./assets/images/joe.jpg">
                    </div>
                    <div class="content">
                        <div class="summary">
                            <a class="user">
                                {{log.logged_by}}
                            </a> tarafından eklendi
                            <div class="date">
                                {{log.created_at | date: 'dd.MM.yyyy'}}
                            </div>
                        </div>
                        <div class="extra text" *ngIf="log.additional_notes">
                            {{log.additional_notes}}
                        </div>
                        <div class="extra">
                            <div class="ui tiny statistics">
                                <div class="green statistic">
                                    <div class="value">
                                        {{log.completed_num_of_parts}}
                                    </div>
                                    <div class="label">
                                        Tamamlandı
                                    </div>
                                </div>
                                <div class="red mini statistic">
                                    <div class="value">
                                        {{log.wasted_num_of_parts}}
                                    </div>
                                    <div class="label">
                                        Fire Verildi
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
