<div class="work-order-operation-container">
    <div class="ui large header dividing margin-bottom-30 padding-left-30 padding-right-30">
        {{title}}
    </div>

    <fui-dimmer class="inverted" [(isDimmed)]="isPageReady" [isClickable]="false">
        <div class="ui text loader">Yükleniyor</div>
    </fui-dimmer>

    <ng-container *ngIf="!isPageReady">
        <form novalidate [formGroup]="operation.form_group">

            <div class="ui form">

                <!--<div class="field"-->
                <!--*ngIf="operation.id"-->
                <!--[ngClass]="{'error': operation.form('work_order_operation_status').hasError('requiredModel') && (operation.form('work_order_operation_status').dirty || operation.form('work_order_operation_status').touched)}">-->
                <!--<label>Operasyon Durumu</label>-->
                <!--<fui-select class="selection"-->
                <!--[(ngModel)]="operation.work_order_operation_status"-->
                <!--formControlName="work_order_operation_status"-->
                <!--[options]="statusList"-->
                <!--[optionTemplate]="statusOptionTemplate"-->
                <!--[isSearchable]="false"-->
                <!--#operationStatusMenu>-->
                <!--<fui-select-option *ngFor="let o of operationStatusMenu.filteredOptions"-->
                <!--[value]="o"></fui-select-option>-->
                <!--</fui-select>-->
                <!--<ng-template let-option #statusOptionTemplate>-->
                <!--<div class="status-dropdown-color-box margin-right-20"-->
                <!--[style.background-color]="option.color"></div>-->
                <!--{{option.name}}-->
                <!--</ng-template>-->
                <!--</div>-->


                <div class="field"
                     [ngClass]="{'error': operation.form('is_internal').hasError('required') && (operation.form('is_internal').dirty || operation.form('is_internal').touched)}">
                    <label>İç/Dış Operasyon</label>
                    <fui-select class="selection"
                                [(ngModel)]="operation.is_internal"
                                formControlName="is_internal"
                                [options]="[{name: 'İç Operasyon', value: true},{name: 'Dış Operasyon', value: false}]"
                                [optionTemplate]="operationIsInternalTemplate"
                                valueField="value"
                                [isSearchable]="false"
                                #operationIsInternalMenu>
                        <fui-select-option *ngFor="let o of operationIsInternalMenu.filteredOptions"
                                           [value]="o"></fui-select-option>
                    </fui-select>
                    <ng-template let-option #operationIsInternalTemplate>
                        {{option.name}}
                    </ng-template>
                </div>


                <div class="field"
                     [ngClass]="{'error': operation.form('work_order_operation_type').hasError('requiredModel') && (operation.form('work_order_operation_type').dirty || operation.form('work_order_operation_type').touched)}">
                    <label>Operasyon Tipi</label>
                    <fui-select class="selection"
                                [(ngModel)]="operation.work_order_operation_type"
                                formControlName="work_order_operation_type"
                                [options]="typeList | filter : 'is_internal':operation.is_internal"
                                [optionTemplate]="typeOptionTemplate"
                                [isSearchable]="true"
                                labelField="name"
                                #operationTypeMenu>
                        <fui-select-option *ngFor="let o of operationTypeMenu.filteredOptions"
                                           [value]="o"></fui-select-option>
                    </fui-select>
                    <ng-template let-option #typeOptionTemplate>
                        {{option.name}}
                    </ng-template>
                </div>


                <ng-container *ngIf="!operation.is_internal">
                    <div class="field" *ngIf="!operation.supplier.id || isSupplierChanging"
                         [ngClass]="{'error': operation.form('supplier').hasError('requiredModel') && (operation.form('supplier').dirty || operation.form('supplier').touched)}">
                        <label>Tedarikçi Ara
                            <a class="mini ui label basic red margin-left-20" (click)="changeSupplier(false)"
                               *ngIf="isSupplierChanging">
                                Vazgeç <i class="icon close"></i>
                            </a>
                        </label>
                        <fui-search
                                class="ui fluid input"
                                placeholder="Yazmaya başlayın..."
                                [optionsLookup]="searchSuppliers"
                                optionsField="title"
                                (resultSelected)="setSupplier($event)"
                                [retainSelectedResult]="false"
                                [retainSelectedResult]="false"
                        >
                        </fui-search>
                    </div>
                    <div class="field" *ngIf="operation.supplier.id && !isSupplierChanging">
                        <label>Tedarikçi Bilgileri</label>
                        <div class="ui card fluid">

                            <div class="content">
                                <div class="header">
                                    {{operation.supplier.title}}
                                </div>
                                <div class="description">
                                    <div>
                                        <i class="icon envelope"></i>
                                        {{operation.supplier.email}}
                                    </div>
                                    <div>
                                        <i class="icon phone"></i>
                                        {{operation.supplier.phone}}
                                    </div>
                                </div>
                            </div>
                            <div class="content">
                                <a class="mini ui label basic icon right floated red" (click)="changeSupplier(true)">
                                    Değiştir <i class="close icon"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="ui icon visible warning message small" *ngIf="!operation.supplier.id">
                        <i class="exclamation icon"></i>
                        <div class="content">
                            <div class="header">
                                Tedarikçi Seçilmedi
                            </div>
                            <p>Tedarikçi seçmek için satınalma siparişinin tedarikçisini değiştirmeniz gerekiyor.</p>
                        </div>
                    </div>

                </ng-container>
                <div class="ui divider margin-top-30 margin-bottom-30"></div>

                <div class="field"
                     [ngClass]="{'error': operation.form('description').hasError('required') && (operation.form('description').dirty || operation.form('description').touched)}">
                    <label>Operasyon Açıklaması</label>
                    <input
                            type="text"
                            placeholder="Operasyon Açıklaması"
                            formControlName="description"
                            [(ngModel)]="operation.description"
                    >
                </div>

                <div class="field"
                     [ngClass]="{'error': operation.form('description').hasError('required') && (operation.form('description').dirty || operation.form('description').touched)}"
                     *ngIf="operation.is_internal">
                    <label>İş İstasyonu</label>
                    <fui-select class="selection"
                                [(ngModel)]="operation.work_station"
                                formControlName="work_station"
                                [options]="workStationList"
                                [optionTemplate]="stationOptionTemplate"
                                [isSearchable]="false"
                                #stationOptionMenu>
                        <fui-select-option *ngFor="let o of stationOptionMenu.filteredOptions"
                                           [value]="o"></fui-select-option>
                    </fui-select>
                    <ng-template let-option #stationOptionTemplate>
                        {{option.code}} - {{option.name}}
                    </ng-template>
                </div>

                <div class="field"
                     [ngClass]="{'error': operation.form('user').hasError('requiredModel') && (operation.form('user').dirty || operation.form('user').touched)}"
                     *ngIf="operation.is_internal">
                    <label>Personel {{operation.user.name}}</label>
                    <fui-select class="selection"
                                [(ngModel)]="operation.user"
                                formControlName="user"
                                [options]="operation.work_station.id ? operation.work_station.users : []"
                                [optionTemplate]="userOptionTemplate"
                                [isSearchable]="false"
                                #pseronnelOptionMenu>
                        <fui-select-option *ngFor="let o of pseronnelOptionMenu.filteredOptions"
                                           [value]="o"></fui-select-option>
                    </fui-select>
                    <ng-template let-option #userOptionTemplate>
                        {{option.name}}
                    </ng-template>
                </div>


                <div class="ui divider margin-top-30 margin-bottom-30" *ngIf="operation.is_internal"></div>

                <div class="field"
                     *ngIf="operation.is_internal"
                     [ngClass]="{'error': operation.form('estimated_operating_time').hasError('requiredNumber') && (operation.form('estimated_operating_time').dirty || operation.form('estimated_operating_time').touched)}">
                    <label>Tahmini Operasyon Süresi / Adet</label>
                    <div class="ui right labeled input">

                        <input
                                type="number"
                                placeholder="Tahmini Operasyon Süresi"
                                formControlName="estimated_operating_time"
                                [(ngModel)]="operation.estimated_operating_time"
                        >
                        <div class="ui label basic">
                            dk
                        </div>
                    </div>
                </div>

                <div class="field"
                     [ngClass]="{'error': operation.form('deadline').hasError('required') && (operation.form('deadline').dirty || operation.form('deadline').touched)}">
                    <label>Termin Tarihi</label>
                    <button class="ui basic blue button"
                            fuiDatepicker
                            [pickerMode]="'date'"
                            [(ngModel)]="operation.deadline"
                            formControlName="deadline"
                            [pickerFirstDayOfWeek]="1"
                    >
                        <i class="calendar icon"></i> {{operation.deadline | date: 'dd.MM.yyyy'}}
                    </button>
                </div>

                <div class="field margin-top-20">
                    <button class="ui button positive right floated" (click)="saveOperation()">
                        Kaydet
                    </button>
                </div>
            </div>
        </form>
    </ng-container>

</div>
