import {EloquentSerializable} from '../interfaces/interfaces';
import {User} from './user.model';
import {QualityControl} from './quality-control.model';
import {Attachment} from './attachment.model';
import {notEmpty} from '../utils/utils';

export class QualityControlGroup implements EloquentSerializable<QualityControlGroup> {


    public static readonly API_PREFIX: string = 'quality-controls';

    public id = null;
    public created_at = new Date();
    public measured_by = null;
    public work_order_operation_id = null;
    public quality_controls: Array<QualityControl> = [];
    public is_out_of_tolerance = false;


    deserialize(input: JSON): QualityControlGroup {
        this.id = input['id'];
        this.work_order_operation_id = input['work_order_operation_id'];
        this.measured_by = input['measured_by'];
        this.is_out_of_tolerance = input['is_out_of_tolerance'];
        this.created_at = new Date(input['created_at']);

        this.quality_controls = [];
        if (notEmpty(input['quality_controls'])) {
            for (let i = 0; i < input['quality_controls'].length; i++) {
                this.quality_controls.push(new QualityControl().deserialize(input['quality_controls'][i]));
            }
        }
        return this;
    }
}
