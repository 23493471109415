import {ValidatorFn, FormControl, Validators} from '@angular/forms';
import {isNumber} from 'util';
import {BaseModel} from '../models/base.model';


export class HolosenValidators {


    public static requiredModel = (control: FormControl) => {
        return (!(control && control.value.id && control.value instanceof BaseModel)) ? {requiredModel: true} : null;
    };


    public static requiredArray = (control: FormControl) => {
        return (!(control && control.value)) ? {requiredModel: true} : null;
    };


    public static requiredNumber = (control: FormControl) => {
        const value = parseFloat(control.value);
        return (!control || isNaN(value) || !isNumber(value)) ? {requiredNumber: true} : null;
    };

    public static requiredTrue = (control: FormControl) => {
        return (control.value !== true) ? {requiredTrue: true} : null;
    };

    public static requiredIf = (property: string, value: any, rule: Function) => {
        return (control: FormControl): { [key: string]: any } => {
            if (control.parent) {
                if (control.parent.get(property).value === value) {
                    return rule(control);
                }
            }

            return null;
        };
    };


    public static confirmPassword = (control: FormControl): { [key: string]: any } => {
        if (control.parent) {
            if (control.parent.get('newPassword').value !== control.parent.get('newPasswordConfirm').value) {
                return {confirmPassword: true};
            }
        }
        return null;
    };


    public static passwordStrength(control: FormControl): { [key: string]: any } {
        const hasNumber = /\d/.test(control.value);
        const hasUpper = /[A-Z]/.test(control.value);
        const hasLower = /[a-z]/.test(control.value);
        const hasLength = control.value.length >= 8;
        // console.log('Num, Upp, Low', hasNumber, hasUpper, hasLower);
        const valid = hasNumber && hasUpper && hasLower && hasLength;
        if (!valid) {
            // return what´s not valid
            return {passwordStrength: true};
        }
        return null;
    }


    public static requiredIfNumber = (property: string, rule: Function) => {
        return (control: FormControl): { [key: string]: any } => {
            if (control.parent) {

                if (!HolosenValidators.requiredNumber(<FormControl>control.parent.get(property))) {
                    return rule(control);
                }
            }
            return null;
        };
    };

    public static requiredIfNumberGreaterThan = (property: string, value: number, rule: Function) => {
        return (control: FormControl): { [key: string]: any } => {
            if (control.parent) {

                if (!HolosenValidators.requiredNumber(<FormControl>control.parent.get(property))) {
                    if (control.parent.get(property).value > value) {
                        return rule(control);
                    }
                }
            }
            return null;
        };
    };


    public static test = (value: any): ValidatorFn => {

        return (control: FormControl): { [key: string]: any } => {
            return null;
        };
    };

}
