import {Component, OnInit} from '@angular/core';
import {EloquentManagerService} from '../../../shared/eloquent/providers/eloquent-manager.provider';
import {WorkStation} from '../../../shared/eloquent/models/work-station.model';
import {StartStopLog} from '../../../shared/eloquent/models/start-stop-log.model';
import {ConfigService} from '../../../shared/services/config/config.service';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {LayoutService} from '../../../shared/services/layout/layout.service';

@Component({
    selector: 'app-current-work',
    templateUrl: './current-work.component.html',
    styleUrls: ['./current-work.component.scss']
})
export class CurrentWorkComponent implements OnInit {

    title = 'Anlık İş Durumu';

    workStationList: Array<WorkStation> = [];
    currentWorkSubscription: Subscription;

    constructor(private eloquentManager: EloquentManagerService,
                private configService: ConfigService,
                private layoutService: LayoutService) {
        this.layoutService.setTitle(this.title);
    }

    ngOnInit() {

        this.subscribeEloquentEvents();
        this.loadCurrentWork();

    }

    subscribeEloquentEvents = () => {
        this.eloquentManager.onUpdated.pipe(
            filter((response) => {

                return response.className === 'StartStopLog' || response.className === 'WorkOrderOperation' || response.className === 'Log';
            })
        ).subscribe((response) => {
            this.loadCurrentWork();
        });


        this.eloquentManager.onCreated.pipe(
            filter((response) => {

                return response.className === 'StartStopLog' || response.className === 'WorkOrderOperation' || response.className === 'Log';
            })
        ).subscribe((response) => {
            this.loadCurrentWork();
        });


        this.eloquentManager.onDeleted.pipe(
            filter((response) => {

                return response.className === 'StartStopLog' || response.className === 'WorkOrderOperation' || response.className === 'Log';
            })
        ).subscribe((response) => {
            this.loadCurrentWork();
        });
    };


    loadCurrentWork = () => {

        if (this.currentWorkSubscription && (!this.currentWorkSubscription.closed)) {
            this.currentWorkSubscription.unsubscribe();
        }

        this.currentWorkSubscription = this.eloquentManager.request('GET', this.configService.get('apiBaseUrl') + 'current-work').subscribe((response) => {
            if (response) {
                const modelList = [];
                const responseArray = response;
                for (let i = 0; i < responseArray.length; i++) {
                    modelList.push(new WorkStation().deserialize(responseArray[i]));
                }

                this.workStationList = modelList;
            }
        });
    };
}
