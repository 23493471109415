import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
import {Toast, ToastType} from './toast.model';


@Injectable()
export class ToastService {

    private idSeq = 1000;
    private toastList: Array<Toast> = [];
    public toastEvent: Observable<any>;
    private toastEventSubject: BehaviorSubject<Array<Toast>> = new BehaviorSubject(this.toastList);

    constructor() {
        this.toastEvent = this.toastEventSubject.asObservable();
    }

    public success(title: string, content: string, previousToast?: Toast): Toast {
        return this.generateToast(title, content, ToastType.SUCCESS, previousToast);
    }

    public error(title: string, content: string, previousToast?: Toast): Toast {
        return this.generateToast(title, content, ToastType.ERROR, previousToast);
    }

    public warn(title: string, content: string, previousToast?: Toast): Toast {
        return this.generateToast(title, content, ToastType.WARN, previousToast);
    }

    public info(title: string, content: string, previousToast?: Toast): Toast {
        return this.generateToast(title, content, ToastType.INFO, previousToast);
    }


    private generateToast(title: string, content: string, type: number, previousToast?: Toast): Toast {
        if (previousToast) {
            this.deleteToast(previousToast);
        }
        const newToast = new Toast(this.generateID(), title, content, type);
        this.toastList.push(newToast);
        this.toastEventSubject.next(this.toastList);
        return newToast;
    }

    public deleteToast(toast: Toast) {
        this.toastList = this.toastList.filter(item => item.id !== toast.id);

        this.toastEventSubject.next(this.toastList);
    }

    private generateID() {
        return ++this.idSeq;
    }

    /*
    Generic Toasts
     */


    processing = () => {
        return this.info('İşlem Devam Ediyor', 'İşlem gerçekleştirilirken lütfen bekleyin.');
    };

    processed = (previousToast: Toast = null) => {

        return this.success('İşlem Tamamlandı', 'İşlem başarıyla gerçekleştirildi', previousToast);
    };

    saving = () => {
        return this.info('Kaydediliyor', 'İşlem gerçekleştirilirken lütfen bekleyin.');
    };

    saved = (previousToast: Toast = null) => {
        if (previousToast) {
            this.deleteToast(previousToast);
        }
        return this.success('Kaydedildi', 'İşlem başarıyla gerçekleştirildi', previousToast);
    };

    saveFailed = (previousToast: Toast = null) => {
        if (previousToast) {
            this.deleteToast(previousToast);
        }
        return this.error('Hata Oluştu', 'Lütfen tekrar deneyin.', previousToast);
    };


    deleting = () => {
        return this.info('Siliniyor', 'İşlem gerçekleştirilirken lütfen bekleyin.');
    };

    deleted = (previousToast: Toast = null) => {
        return this.success('Silindi', 'İşlem başarıyla gerçekleştirildi.', previousToast);
    };

    deleteFailed = (previousToast: Toast = null) => {
        return this.error('Hata Oluştu', 'Lütfen tekrar deneyin.', previousToast);
    };
}

