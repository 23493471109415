import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotFoundComponent} from './not-found.component';
import {SharedImportsModule} from '../../../shared/modules/shared-imports.module';

@NgModule({
    imports: [
        SharedImportsModule
    ],
    declarations: [
        NotFoundComponent
    ],
    exports: [
        NotFoundComponent
    ]
})
export class NotFoundModule {
}
