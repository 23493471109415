import {AuthenticationService} from '../../../shared/services/authentication/authentication.service';
import {UserManagementComponent} from './user-management.component';


export const UserManagementRoutes = [
    {
        path: 'user-management',
        component: UserManagementComponent,
        data: {
            roles: [
                AuthenticationService.ROLES.ADMIN
            ]
        }
    }
];