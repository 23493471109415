import {EloquentSerializable} from '../interfaces/interfaces';

export class MaterialShape implements EloquentSerializable<MaterialShape> {

    public shape = '';

    public diameter = 0;
    public height = 0;
    public width = 0;
    public depth = 0;
    public wall = 0;


    deserialize(input: JSON): MaterialShape {
        this.diameter = input['diameter'];
        this.height = input['height'];
        this.width = input['width'];
        this.depth = input['depth'];
        this.wall = input['wall'];
        this.shape = input['shape'];
        return this;
    }
}