import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserManagementComponent} from './user-management.component';
import {SharedImportsModule} from '../../../shared/modules/shared-imports.module';

@NgModule({
    imports: [
        SharedImportsModule
    ],
    declarations: [UserManagementComponent],
    exports: [UserManagementComponent],
})
export class UserManagementModule {
}
