import {FormGroup, AbstractControl} from '@angular/forms';
import {EloquentValidation} from '../interfaces/interfaces';
export abstract class BaseModel implements EloquentValidation {

    public id: number = null;
    public validation: any;

    protected isValid = true;

    form_group: FormGroup;

    public getApiPrefix() {

    }


    private applyRule(value: any, rule: any): any {

        const response = {
            success: true,
            message: ''
        };

        switch (true) {
            case /^required$/.test(rule): {
                if (value === null || value === '') {
                    response.success = false;
                    response.message = 'Doldurulması zorunludur';
                }
                break;
            }
            case /^required_true$/.test(rule): {
                console.log('required_true');
                if (value !== true) {
                    response.success = false;
                    response.message = 'Doldurulması zorunludur';
                }
                break;
            }
            case /^required_false$/.test(rule): {
                if (value !== false) {
                    response.success = false;
                    response.message = 'Doldurulması zorunludur';
                }
                break;
            }
            case /^required_model$/.test(rule): {
                if (value.id === null || value.id === '') {
                    response.success = false;
                    response.message = 'Doldurulması zorunludur';
                }
                break;
            }
            case /^required_array$/.test(rule): {
                if (!(value === null) && value.length === 0) {
                    response.success = false;
                    response.message = `En az 1 tane seçilmesi gerekmektedir`;
                }
                break;
            }
            case /^max_length/.test(rule): {
                let maxLength = /\(([0-9])\)/.exec(rule)[0];
                maxLength = maxLength.substring(1, maxLength.length - 1);

                if (!(value === null || value === '') && value.length > maxLength) {
                    response.success = false;
                    response.message = `Maks. uzunluk: ${maxLength}`;
                }

                break;
            }

            case /^number$/.test(rule): {
                if (isNaN(value)) {
                    response.success = false;
                    response.message = `Sayı olması gerekmektedir`;
                }
                break;
            }
            case /^required_if\(.*\)$/.test(rule): {
                const rule_params = (/^\s*(\w+)\s*\((.*)\)/g.exec(rule)[2]).replace(/\/\*.*\*\//, '').trim().split(',');
                const required_property = rule_params[0];
                const required_rule = rule_params[1];
                const required_rule_type = rule_params[2];

                const applied_rule = this.applyRule(this[required_property], required_rule);
                if (applied_rule.success) {
                    return this.applyRule(value, required_rule_type);
                }
                break;
            }
            default:
                break;
        }

        return response;
    }

    form(key: string): AbstractControl {
        return this.form_group.get(key);
    }

    revalidate() {
        Object.keys(this.form_group.controls).forEach(key => {
            this.form_group.get(key).markAsTouched();
            this.form_group.get(key).updateValueAndValidity();
        });
    }


    public toJSON() {

        const jsonObject = {};

        Object.keys(this).forEach(key => {
            if (key !== 'form_group') {
                jsonObject[key] = this[key];
            }
        });

        return jsonObject;
    }

}

