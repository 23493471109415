import {AuthenticationService} from '../../../shared/services/authentication/authentication.service';
import {ReportingComponent} from './reporting.component';


export const ReportingRoutes = [
    {
        path: 'reporting',
        component: ReportingComponent,
        data: {
            roles: [
                AuthenticationService.ROLES.ADMIN,
                AuthenticationService.ROLES.PLANNER,
            ]
        }
    }
];