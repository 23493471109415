import {of as observableOf, throwError as observableThrowError, Observable, Subscription, Subject} from 'rxjs';
import {Injectable} from '@angular/core';

/*
 MODELS
 */
import {Attachment} from '../models/attachment.model';
import {Attachable} from '../models/attachable.model';
import {Me} from '../models/me.model';
import {Loggable} from '../models/loggable.model';
import {User} from '../models/user.model';
import {UserRole} from '../models/user-role.model';
import {Customer} from '../models/customer.model';
import {Supplier} from '../models/supplier.model';
import {Personnel} from '../models/personnel.model';
import {WorkOrder} from '../models/work-order.model';
import {WorkStation} from '../models/work-station.model';
import {WorkOrderStatus} from '../models/work-order-status.model';
import {WorkOrderOperation} from '../models/work-order-operation.model';
import {WorkOrderOperationStatus} from '../models/work-order-operation-status.model';
import {QualityControl} from '../models/quality-control.model';
import {MrpResponse} from '../interfaces/interfaces';
import {HttpErrorResponse, HttpEventType, HttpParams} from '@angular/common/http';
import {Pagination} from '../models/query/pagination.model';
import {catchError, map} from 'rxjs/operators';
import {ToastService} from '../../services/toast/toast.service';
import {Toast} from '../../services/toast/toast.model';
import {ConfigService} from '../../services/config/config.service';
import {AuthHttpService} from '../../services/http/auth-http.service';
import {WebSocketService} from '../../services/web-socket/web-socket.service';

@Injectable()
export class EloquentManagerService {


  private apiBaseUrl: string = this.configService.get('apiBaseUrl');
  private webSocketSubscription: Subscription;

  private onCreatedSubject: Subject<any> = new Subject<any>();
  public onCreated: Observable<any> = this.onCreatedSubject.asObservable();

  private onUpdatedSubject: Subject<any> = new Subject<any>();
  public onUpdated: Observable<any> = this.onUpdatedSubject.asObservable();

  private onDeletedSubject: Subject<any> = new Subject<any>();
  public onDeleted: Observable<any> = this.onDeletedSubject.asObservable();

  private onRestoredSubject: Subject<any> = new Subject<any>();
  public onRestored: Observable<any> = this.onRestoredSubject.asObservable();


  private readonly CREATED = 'created';
  private readonly UPDATED = 'updated';
  private readonly DELETED = 'deleted';
  private readonly RESTORED = 'restored';

  constructor(private configService: ConfigService,
              private authHttp: AuthHttpService,
              private toastService: ToastService,
              private webSocketService: WebSocketService) {
    this.subscribeWebSocketService();
  }


  subscribeWebSocketService = () => {
    this.webSocketSubscription = this.webSocketService.onEloquentEvent.subscribe((event) => {

      switch (event.data.type) {
        case this.CREATED: {
          this.onCreatedSubject.next(event.data);
          break;
        }
        case this.UPDATED: {
          this.onUpdatedSubject.next(event.data);
          break;
        }
        case this.DELETED: {
          this.onDeletedSubject.next(event.data);
          break;
        }
        case this.RESTORED: {
          this.onRestoredSubject.next(event.data);
          break;
        }
        default: {
          break;
        }
      }

    });
  };


  private getApiPrefix(model: any) {
    const apiPrefix = model.API_PREFIX;
    if (apiPrefix == null) {
      throw observableThrowError('API_PREFIX property is not defined in the model: ${model.name}');
    }
    return apiPrefix;
  }


  public list(model: any): any {

    return this.authHttp.authGet(this.apiBaseUrl + this.getApiPrefix(model), {}).pipe(
      map((response: MrpResponse) => {
        if (response.ok) {
          const modelList = [];
          const responseArray = response.body;
          for (let i = 0; i < responseArray.length; i++) {
            modelList.push(new model().deserialize(responseArray[i]));
          }

          return modelList;
        } else {
          return observableOf(false);

        }
      }),
      catchError(err => {
          return observableOf(false);
        }
      )
    );
  }


  public paginate(model: any, pagination: Pagination): any {

    const url = this.apiBaseUrl + this.getApiPrefix(model) + '/paginate';
    return this.authHttp.authPost(url, {pagination}).pipe(
      map((response: MrpResponse) => {
        if (response.ok) {
          const modelList = [];
          const responseArray = response.body.data;

          for (let i = 0; i < responseArray.length; i++) {
            modelList.push(new model().deserialize(responseArray[i]));
          }

          response.body.data = modelList;

          return response.body;
        } else {
          console.log(response);
          return observableOf(false);

        }
      }),
      catchError(err => {
        console.log(err);
        return observableOf(false);
      })
    );
  }

  public getById(model: any, id: number): any {
    return this.authHttp.authGet(this.apiBaseUrl + this.getApiPrefix(model) + '/' + id, {}).pipe(
      map((response: MrpResponse) => {

        if (response.ok) {
          return new model().deserialize(response.body);
        }
      }),
      catchError((error) => {
        return observableOf(false);
      })
    );
  }

  public save(model: any) {

    const savingToast = this.toastService.saving();


    return this.authHttp.authPost(this.apiBaseUrl + model.getApiPrefix(), model).pipe(
      map((response: MrpResponse) => {
        if (response.ok) {
          console.log(response);
          this.toastService.saved(savingToast);
          return model.deserialize(response.body);
        }
      }),
      catchError((error) => {
        console.log(error);
        this.toastService.saveFailed(savingToast);
        return observableOf(false);
      })
    );
  }

  public update(model: any) {

    const savingToast = this.toastService.saving();


    const url = this.apiBaseUrl + model.getApiPrefix() + '/' + model.id;

    return this.authHttp.authPut(url, model).pipe(
      map((response: Response) => {
        if (response.ok) {
          this.toastService.saved(savingToast);
          return observableOf(true);
        }
      }),
      catchError((error) => {
        this.toastService.saveFailed(savingToast);
        return observableOf(false);
      })
    );
  }


  public delete(model: any) {

    const deletingToast = this.toastService.deleting();


    const url = this.apiBaseUrl + model.getApiPrefix() + '/' + model.id;

    return this.authHttp.authDelete(url).pipe(
      map((response: Response) => {
        if (response.ok) {
          this.toastService.saved(deletingToast);
          return observableOf(true);
        }
      }),
      catchError((error) => {
        this.toastService.saveFailed(deletingToast);
        return observableOf(false);
      })
    );
  }

  public attach(model: any, attachment: File) {
    /*if (!attachment.validate()) {
     this.validationFailedNotification(notification);
     return Observable.of(false);
     }*/

    const input = new FormData();
    input.append('file', attachment);

    const url = this.apiBaseUrl + model.getApiPrefix() + '/' + model.id + '/attach';

    return this.authHttp.attach(url, input).pipe(
      map((event) => {
        return event;

      }),
      catchError((error) => {
        this.toastService.error('Hata Oluştu', `Beklenmeyen bir hatayla karşılaşıldı<br/><br/>Hata Kodu: ${error}`);
        return observableOf(false);
      })
    );
  }


  public detach(attachment: Attachment) {

    const url = this.apiBaseUrl + attachment.getApiPrefix() + '/' + attachment.id + '/detach';

    return this.authHttp.authPut(url).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        console.log(error);
        this.toastService.error('Hata Oluştu', `Beklenmeyen bir hatayla karşılaşıldı<br/><br/>Hata Kodu: ${error}`);
        return observableOf(false);
      })
    );
  }

  public request(method: string, url: string, data?: any, options?: any) {

    let authMethod;

    switch (method) {
      case 'POST': {
        authMethod = this.authHttp.authPost;
        break;
      }
      case 'PUT': {
        authMethod = this.authHttp.authPut;
        break;
      }
      case 'GET': {
        authMethod = this.authHttp.authGet;
        break;
      }
      case 'DELETE': {
        authMethod = this.authHttp.authDelete;
        break;
      }
      default: {
        authMethod = this.authHttp.authGet;
        break;
      }
    }


    return authMethod(url, data, options).pipe(
      map((response: MrpResponse) => {
        if (response.ok) {
          return response.body;
        } else {
          return observableOf(false);
        }
      }),
      catchError(err => {
          return observableOf(false);
        }
      )
    );
  }


  public reload(model) {
    this.authHttp.authGet(this.apiBaseUrl + model.getApiPrefix() + '/' + model.id, {}).pipe(
      map((response: MrpResponse) => {

        if (response.ok) {
          model.deserialize(response.body);
        }
      }),
      catchError((error) => {
        return observableOf(false);
      })
    ).subscribe();
  }


  public recalculateCompletion(model) {
    this.request('POST', `${this.configService.get('apiBaseUrl') + model.getApiPrefix()}/${model.id}/recalculate-completion`).subscribe(response => {
      if (response) {
        model.deserialize(response);

      }
    });
  }
}

