import {Shipment} from './shipment.model';
import {notEmpty} from '../utils/utils';

export class Shippable {


    public static readonly SHIPPABLE_STATUS = {
        shipped: {
            db_value: 'shipped',
            display_name: 'Sevk Edildi',
            cssClass: 'green'
        },
        partiallyShipped: {
            db_value: 'partially-shipped',
            display_name: 'Kısmen Sevk Edildi',
            cssClass: 'yellow'
        },
        notShipped: {
            db_value: 'not-shipped',
            display_name: 'Sevk Edilmedi',
            cssClass: 'red'
        }
    };

    public incoming_shipments: Array<Shipment> = [];
    public outgoing_shipments: Array<Shipment> = [];
    public incoming_percentage = 0;
    public outgoing_percentage = 0;
    public total_incoming_completed_quantity = 0;
    public total_incoming_wasted_quantity = 0;
    public total_outgoing_completed_quantity = 0;
    public total_outgoing_wasted_quantity = 0;
    public shippable_status = Shippable.SHIPPABLE_STATUS.notShipped.db_value;
    public shippable_status_name = Shippable.SHIPPABLE_STATUS.notShipped.display_name;


    getShippableStatusName() {
        for (const index in Shippable.SHIPPABLE_STATUS) {
            if (Shippable.SHIPPABLE_STATUS[index].db_value === this.shippable_status) {
                return Shippable.SHIPPABLE_STATUS[index];
            }
        }
    }

    public deserializeShippableAttributes(input: any) {

        this.incoming_percentage = input['incoming_percentage'];
        this.outgoing_percentage = input['outgoing_percentage'];
        this.total_incoming_completed_quantity = input['total_incoming_completed_quantity'];
        this.total_incoming_wasted_quantity = input['total_incoming_wasted_quantity'];
        this.total_outgoing_completed_quantity = input['total_outgoing_completed_quantity'];
        this.total_outgoing_wasted_quantity = input['total_outgoing_wasted_quantity'];
        this.shippable_status = input['shippable_status'];
        this.shippable_status_name = this.getShippableStatusName();

        this.deserializeIncomingShipments(input);
        this.deserializeOutgoingShipments(input);
    }


    public addIncomingShipment(shipment: Shipment) {
        this.incoming_shipments.push(shipment);
    }

    public addOutgoingShipment(shipment: Shipment) {
        this.outgoing_shipments.push(shipment);
    }

    public deserializeIncomingShipments(input: any) {
        this.incoming_shipments = [];
        if (notEmpty(input['incoming_shipments'])) {
            for (let i = 0; i < input['incoming_shipments'].length; i++) {
                this.incoming_shipments.push(new Shipment().deserialize(input['incoming_shipments'][i]));
            }
        }
    }

    public deserializeOutgoingShipments(input: any) {
        this.outgoing_shipments = [];
        if (notEmpty(input['outgoing_shipments'])) {
            for (let i = 0; i < input['outgoing_shipments'].length; i++) {
                this.outgoing_shipments.push(new Shipment().deserialize(input['outgoing_shipments'][i]));
            }
        }
    }


}
