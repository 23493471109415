<app-dynamic-component #dynamicComponent></app-dynamic-component>

<div class="ui container padding-bottom-75 content-background very padded basic segment">
  <div class="ui attached top">
    <div class="ui grid divided">
      <div class="ui row">
        <div class="four wide column">
          <div class="ui icon input fluid">
            <input type="text"
                   placeholder="Parça adı ya da Teklif Numarası..."
                   [formControl]="searchBarCtrl"
                   [value]="pagination.keyword"
                   #searchBarInput
            >
            <i class="search icon"
               *ngIf="paginateSubscription?.closed && (searchBarInput.value.length == 0)"></i>
            <i class="refresh loading icon"
               *ngIf="!paginateSubscription?.closed"></i>
            <i class="circular remove link icon"
               *ngIf="paginateSubscription?.closed && (searchBarInput.value.length > 0)"
               (click)="resetSearchValue()"></i>

          </div>
        </div>

        <div class="two wide column">
          <div class="ui dropdown basic button fluid" fuiDropdown [(isOpen)]="isCustomerDropdownOpen">
            <div class="text truncate">
              {{pagination.filter.customer ? (pagination.filter.customer.title | slice: 0:7) :
              'Müşt.'}}
            </div>
            <i class="dropdown icon"></i>
            <div class="menu" fuiDropdownMenu>
              <div class="padding-15">
                <button class="ui button basic red fluid" (click)="filterByCustomer(null);">
                  Filtreyi Kaldır
                  <i class="remove icon"></i>
                </button>
              </div>
              <div class="padding-15">
                <fui-search
                  class="ui fluid input"
                  placeholder="Yazmaya başlayın..."
                  [optionsLookup]="searchCustomers"
                  optionsField="title"
                  (resultSelected)="filterByCustomer($event)"
                  [retainSelectedResult]="false"
                >
                </fui-search>
              </div>
            </div>
          </div>
        </div>

        <div class="two wide column">
          <div class="ui dropdown basic button fluid" fuiDropdown [(isOpen)]="iStatusDropdownOpen">
            <div class="text truncate">
              <div class="status-dropdown-color-box margin-right-5"
                   *ngIf="pagination.filter.status"
                   [style.background-color]="pagination.filter.status.color"></div>
              {{pagination.filter.status ? (pagination.filter.status.name | slice: 0:4) :
              'Durum'}}
            </div>
            <i class="dropdown icon"></i>
            <div class="menu" fuiDropdownMenu>
              <div class="padding-15">
                <button class="ui button basic red fluid" (click)="filterByStatus(null);">
                  Filtreyi Kaldır
                  <i class="remove icon"></i>
                </button>
              </div>
              <div class="item" *ngFor="let status of workOrderStatusList"
                   (click)="filterByStatus(status)">
                <div class="status-dropdown-color-box margin-right-20"
                     [style.background-color]="status.color"></div>
                {{status.name}}
              </div>
            </div>
          </div>
        </div>

        <div class="two wide column">
          <div class="ui dropdown basic button fluid" fuiDropdown [(isOpen)]="isOpTypeDropdownOpen">
            <div class="text truncate">
              {{pagination.filter.work_order_operation_type ? (pagination.filter.work_order_operation_type.name | slice:
              0:4) :
              'Op.Tip'}}
            </div>
            <i class="dropdown icon"></i>
            <div class="menu" fuiDropdownMenu  style="max-height: 400px; overflow-y: scroll;">
              <div class="padding-15">
                <button class="ui button basic red fluid" (click)="filterByOpType(null);">
                  Filtreyi Kaldır
                  <i class="remove icon"></i>
                </button>
                <div class="margin-top-15" *ngIf="pagination.filter.work_order_operation_type">
                  <b>Seçilen: {{pagination.filter.work_order_operation_type.name}}</b>
                </div>
              </div>
              <div class="item" *ngFor="let type of typeList"
                   (click)="filterByOpType(type)">
                {{type.name}}
              </div>
            </div>
          </div>
        </div>

        <div class="two wide column">
          <div class="ui dropdown basic button fluid" fuiDropdown>
            Sırala
            <i class="dropdown icon"></i>
            <div class="menu" fuiDropdownMenu>
              <div class="item" *ngFor="let orderType of orderList" (click)="orderBy(orderType)"
                   [ngClass]="{'active': pagination.order === orderType}">
                {{orderType.name}}
              </div>
            </div>
          </div>
        </div>
        <div class="four wide column">
          <app-pagination [pagination]="pagination" (pageChange)="onPageChange()"></app-pagination>
        </div>
      </div>
    </div>

  </div>
  <div class="ui basic segment " style="min-height: 500px">
    <fui-dimmer class="inverted" [(isDimmed)]="isPaginationLoading" [isClickable]="false">
      <div class="ui text loader">Yükleniyor</div>
    </fui-dimmer>
    <ng-container *ngIf="workOrderList.length === 0 && !isPaginationLoading">
      <div class="ui grid centered margin-top-75 margin-bottom-75">
        <h2 class="ui icon header grey">
          <i class="warning circle icon"></i>
          <div class="content">
            Kayıt Bulunamadı
            <div class="sub header margin-top-50">
              Filtreleri değiştirerek yeniden deneyiniz.

            </div>
          </div>
        </h2>
      </div>
    </ng-container>
    <div class="ui accordion fluid" #orderAccordion>
      <ng-container *ngFor="let order of workOrderList">
        <div class="title" [ngClass]="{'delayed': order.is_delayed}">
          <div class="margin-bottom-15">
            <div class="ui ribbon label"
                 [style.color]="'#ffffff'"
                 [style.background-color]="order.work_order_status.color">
              {{order.work_order_status.name}}
            </div>
            <div class="ui circular large icon basic  red label"
                 *ngIf="!order.quality_control_configured"
                 fuiPopup
                 popupPlacement="bottom"
                 popupText="Kalite Kontrol Konfigürasyonu Eksik"
            >
              <i class="warning circle icon"></i> QC
            </div>
            <span class="proforma-id"
                  (click)="searchByProformaId(order.proforma_id)">{{order.proforma_id}}</span>
            <div class="ui icon buttons right floated">
              <a [routerLink]="['/work-order', order.id]"
                 class="ui button basic grey"
                 fuiPopup
                 popupText="Düzenle"
                 popupPlacement="bottom">
                <i class="edit icon"></i>
              </a>
              <a class="ui button basic blue"
                 fuiPopup
                 popupText="Ekleri Yönet"
                 popupPlacement="bottom"
                 (click)="openUploader(order)">
                <i class="attach icon"></i>
              </a>
              <a class="ui basic green button dropdown" fuiDropdown>
                <i class="truck flipped icon"></i>
                <i class="dropdown icon"></i>
                <div class="menu" fuiDropdownMenu>
                  <div class="item" (click)="openShipmentHistory(order)">
                    <i class="unhide icon"></i>
                    Kayıtları Gör
                  </div>
                  <div class="item" (click)="openShipmentHistory(order, true)">
                    <i class="add icon"></i>
                    Yeni Kayıt Ekle
                  </div>
                </div>
              </a>
              <button class="ui button basic red" (click)="openQualityControlConfig(order)">
                <i class="check icon "></i>
              </button>
              <div class="ui button basic grey dropdown" fuiDropdown>
                <i class="ellipsis vertical icon"></i>
                <div class="menu" fuiDropdownMenu>
                  <div class="item error"
                       (click)="deleteWorkOrder(order, operation)">
                    <i class="remove icon"></i>
                    Sil
                  </div>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
          <div class="ui grid clearing padding-top-15 padding-bottom-20">
            <div class="nine wide column">
              <div class="order-id">
                #{{order.id}}

              </div>
              <div class="order-customer margin-top-10">
                {{order.customer.title}}
              </div>
              <div class="order-part-name">
                {{order.part_name}}
              </div>
              <div class="order-part-number">
                {{order.quantity}} adet
              </div>
            </div>
            <div class="three wide column">
              <div class="ui tiny horizontal statistic total-operation-statistic">
                <div class="value">
                  {{order.work_order_operations.length}}
                </div>
                <div class="label">
                  Op.
                </div>
              </div>
            </div>
            <div class="three wide column right floated">
              <div class="padding-5">
                <div class="order-detail-label">Ekleyen</div>
                <div>
                  {{order.added_by}}
                </div>
              </div>
              <div class="padding-5 margin-top-5">
                <div class="order-detail-label">Giriş Tarihi</div>
                <div>
                  {{order.entry_datetime | date: 'dd.MM.yyyy'}}
                </div>
              </div>
              <div class="padding-5 margin-top-5">
                <div class="order-detail-label">Termin Tarihi</div>
                <div>
                  {{order.deadline | date: 'dd.MM.yyyy'}}
                </div>
              </div>
            </div>
            <div class="one wide column accordion-handle">
              <i class="chevron down icon"></i>
            </div>
          </div>
          <div class="ui grid  equal width">
            <div class="column" *ngIf="order.has_internal_operations">
              <fui-progress class="small green"
                            [value]="order.internal_completed">
                İç İşlemler
              </fui-progress>
            </div>
            <div class="column" *ngIf="order.has_external_operations">
              <fui-progress class="small green"
                            [value]="order.external_completed">
                <i class="truck icon margin-right-5"></i>
                Dış İşlemler
              </fui-progress>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="content-inner">
            <div class="clearfix"></div>
            <div class="ui large header grey margin-top-20 margin-bottom-20">Operasyonlar</div>
            <div class="ui segments">


              <div class="ui very padded segment"
                   *ngFor="let operation of order.work_order_operations">
                <a class="ui left corner label black" *ngIf="operation.has_open_log">
                  <i class="icon loading setting big"></i>
                </a>
                <div class="ui tiny operation menu borderless fitted">
                  <div class="item">

                    <div class="ui labels">
                      <a class="ui label"
                         [ngClass]="{'loading': operation.isStatusLoading}"
                         [style.color]="'#ffffff'"
                         [style.background-color]="operation.work_order_operation_status.color"
                      >
                        {{operation.work_order_operation_status.name}}
                      </a>
                      <div class="ui circular large icon basic  grey label"
                           *ngIf="!operation.is_internal">
                        <i class="truck icon"></i>DT
                      </div>
                    </div>
                  </div>
                  <div class="right menu">
                    <div class="ui small buttons">
                      <button class="ui basic blue button dropdown" fuiDropdown
                              *ngIf="operation.is_internal">
                        <div class="text">
                          <i class="sticky note outline icon"></i>
                          Günlük
                        </div>
                        <i class="dropdown icon"></i>
                        <div class="menu" fuiDropdownMenu>
                          <div class="item" (click)="openLogHistory(operation, order)">
                            <i class="unhide icon"></i>
                            Kayıtları Gör
                          </div>
                          <div class="item" (click)="openLogHistory(operation, order, true)">
                            <i class="add icon"></i>
                            Yeni Kayıt Ekle
                          </div>
                        </div>
                      </button>
                      <button class="ui basic blue button"
                              *ngIf="!operation.is_internal"
                              (click)="openPurchaseOrder(operation)"
                              fuiPopup
                              popupText="Satınalma Siparişi"
                              popupPlacement="bottom">
                        <i class="in cart icon"></i>Sipariş
                      </button>
                      <div class="ui icon button basic grey dropdown" fuiDropdown>
                        <i class="ellipsis vertical icon"></i>
                        <div class="menu" fuiDropdownMenu>
                          <div class="item"
                               (click)="openOperation(operation)">
                            <i class="edit icon"></i>
                            Düzenle
                          </div>
                          <div class="item"
                               (click)="deleteWorkOrderOperation(operation)">
                            <i class="remove icon"></i>
                            Sil
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="ui grid clearing padding-top-15 padding-bottom-20">
                  <div class="row">
                    <div class="four wide column">
                      <div class="operation-type-name">
                        {{operation.work_order_operation_type.name}}
                      </div>
                      <div class="margin-top-10">
                        {{operation.description}}
                      </div>
                    </div>
                    <div class="five wide column">
                      <ng-container *ngIf="operation.is_internal">
                        <div class="operation-detail-label">İş İstasyonu</div>
                        <div>
                          {{operation.work_station.code}} -
                          {{operation.work_station.name}}
                        </div>
                        <div class="operation-detail-label margin-top-10">Personel</div>
                        <div>
                          {{operation.user.name}}
                        </div>
                      </ng-container>
                      <ng-container *ngIf="!operation.is_internal">
                        <div class="operation-detail-label">Tedarikçi</div>
                        <div>
                          {{operation.supplier?.title}}
                        </div>
                      </ng-container>
                    </div>
                    <div class="four wide column">
                      <div class="operation-detail-label">Giriş Tarihi</div>
                      <div>
                        {{operation.entry_datetime | date: 'dd.MM.yyyy'}}
                      </div>
                      <div class="operation-detail-label margin-top-10">Termin Tarihi</div>
                      <div>
                        {{operation.deadline | date: 'dd.MM.yyyy'}}
                      </div>
                    </div>
                    <div class="three wide column">
                      <ng-container *ngIf="operation.is_internal">
                        <div class="operation-detail-label">Tahmini İşlem Süresi / Adet</div>
                        <div>
                          {{operation.estimated_operating_time}} dk.
                        </div>
                        <div class="ui grid equal width">
                          <div class="column">
                            <div class="operation-detail-label margin-top-10">Tamamlanan
                            </div>
                            <div class="stats completed">
                              <i class="check small icon margin-right-5"></i>{{operation.completed_num_of_parts}}
                            </div>
                          </div>
                          <div class="column">
                            <div class="operation-detail-label margin-top-10">Fire Verilen
                            </div>
                            <div class="stats wasted">
                              <i class="remove small icon margin-right-5"></i>{{operation.wasted_num_of_parts}}
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="!operation.is_internal">
                        <div class="ui grid equal width">
                          <div class="column">
                            <div class="operation-detail-label margin-top-10">Gelen</div>
                            <div>
                              <div class="stats completed">
                                <i class="check small icon margin-right-5"></i>{{operation.incoming_completed_num_of_parts}}
                              </div>
                              <div class="stats wasted">
                                <i class="remove small icon margin-right-5"></i>{{operation.incoming_wasted_num_of_parts}}
                              </div>
                            </div>
                          </div>
                          <div class="column">
                            <div class="operation-detail-label margin-top-10">Giden</div>
                            <div>
                              <div class="stats completed">
                                <i class="check small icon margin-right-5"></i>{{operation.outgoing_completed_num_of_parts}}
                              </div>
                              <div class="stats wasted">
                                <i class="remove small icon margin-right-5"></i>{{operation.outgoing_wasted_num_of_parts}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <fui-progress class=" small margin-bottom-10"
                              [ngClass]="{'active': operation.has_open_log}"
                              [value]="operation.completion_percentage"></fui-progress>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="ui grid">
    <div class="four wide column right floated">
      <app-pagination [pagination]="pagination" (pageChange)="onPageChange()"></app-pagination>
    </div>
  </div>
</div>


<!--MODALS-->

<div class="ui small basic modal" #confirmModal>
  <div class="ui icon header">
    <i class="remove from calendar icon"></i>
    Silme İşlemini Onayla
  </div>
  <div class="content" style="text-align: center !important">
    Bu işlem geri alınamaz. Silmek istediğinize emin misiniz?
  </div>
  <div class="actions">
    <div class="ui red basic cancel inverted button">
      <i class="remove icon"></i>
      Vazgeç
    </div>
    <div class="ui green ok inverted button">
      <i class="checkmark icon"></i>
      Devam Et
    </div>
  </div>
</div>


<a class="circular ui icon button fab bottom right red huge"
   fuiPopup
   popupText="Yeni İş Emri Ekle"
   popupPlacement="left"
   [popupInverted]="true"
   [routerLink]="['/work-order']"
>
  <i class="icon plus"></i>
</a>
