import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {LayoutService} from '../../../shared/services/layout/layout.service';
import {EloquentManagerService} from '../../../shared/eloquent/providers/eloquent-manager.provider';
import {ConfigService} from '../../../shared/services/config/config.service';
import {AuthHttpService} from '../../../shared/services/http/auth-http.service';
import {ToastService} from '../../../shared/services/toast/toast.service';
import {BaseComponent} from '../../base.component';
import {AuthenticationService} from '../../../shared/services/authentication/authentication.service';
import {Router} from '@angular/router';
import {PurchaseOrder} from '../../../shared/eloquent/models/purchase-order.model';
import {Pagination, PAGINATION_ORDER} from '../../../shared/eloquent/models/query/pagination.model';
import {Subscription} from 'rxjs';
import {WorkOrder} from '../../../shared/eloquent/models/work-order.model';
import {DynamicComponentComponent, DynamicComponentType} from '../../../shared/components/dynamic-component/dynamic-component.component';
import {FormControl} from '@angular/forms';
import {Customer} from '../../../shared/eloquent/models/customer.model';
import {Supplier} from '../../../shared/eloquent/models/supplier.model';
import {debounceTime} from 'rxjs/operators';
import {ShipmentHistoryComponent} from '../../../shared/components/shipment-history/shipment-history.component';

import {saveAs} from 'file-saver';
import {HttpClient} from '@angular/common/http';
import {WorkOrderOperation} from '../../../shared/eloquent/models/work-order-operation.model';
import {PurchaseOrderComponent} from '../../../shared/components/purchase-order/purchase-order.component';

declare const $: any;

@Component({
    selector: 'app-purchase',
    templateUrl: './purchase.component.html',
    styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent extends BaseComponent implements OnInit, AfterViewInit {


    pageTitle = 'Satınalma';

    purchaseOrderList: Array<PurchaseOrder> = [];
    pagination: Pagination = new Pagination();
    paginateSubscription: Subscription;

    isPaginationLoading = true;
    isSupplierDropdownOpen = false;

    searchBarCtrl: FormControl;

    @ViewChild('confirmModal', {static: false})
    confirmModal: ElementRef;

    @ViewChild('dynamicComponent', {static: false})
    dynamicComponent: DynamicComponentComponent;

    @ViewChild('orderAccordion', {static: false})
    orderAccordion: ElementRef;

    PURCHASE_ORDER_TYPES = PurchaseOrder.PURCHASE_ORDER_TYPES;

    orderList: Array<any> = [
        {
            name: 'Termin Tarihi (Azalan)',
            field: 'DEADLINE',
            value: PAGINATION_ORDER.DESCENDING
        },
        {
            name: 'Termin Tarihi (Artan)',
            field: 'DEADLINE',
            value: PAGINATION_ORDER.ASCENDING
        },
        {
            name: 'Sevkiyat Ter. Tar. (Azalan)',
            field: 'SHIPMENT_DEADLINE',
            value: PAGINATION_ORDER.DESCENDING
        },
        {
            name: 'Sevkiyat Ter. Tar. (Artan)',
            field: 'SHIPMENT_DEADLINE',
            value: PAGINATION_ORDER.ASCENDING
        }

    ];


    baseUrl = this.config.get('apiBaseUrl');

    constructor(protected router: Router,
                protected auth: AuthenticationService,
                private eloquentManager: EloquentManagerService,
                private toastService: ToastService,
                private authHttp: AuthHttpService,
                private config: ConfigService,
                private layoutService: LayoutService,
                private http: HttpClient) {
        super(router, auth);

    }

    ngOnInit() {
        this.layoutService.setTitle(this.pageTitle);
        this.pagination.order = this.orderList[0];
        this.registerSearchValueChanges();
        this.paginatePurchaseOrders();
    }


    ngAfterViewInit(): void {

        $(this.orderAccordion.nativeElement).accordion(
            {
                selector: {
                    trigger: '.title .accordion-handle'
                },
                onOpen: (event) => {
                    const openIndex = $('.ui.accordion .title.active');

                    $('html,body').animate({
                            scrollTop: openIndex.offset().top - 75
                        },
                        450);
                }
            }
        );
    }


    reloadPurchaseOrder = (order: PurchaseOrder) => {
        this.eloquentManager.getById(PurchaseOrder, order.id).subscribe((response) => {
            if (response) {
                for (const index in this.purchaseOrderList) {
                    if (this.purchaseOrderList[index].id === order.id) {
                        this.purchaseOrderList[index] = this.purchaseOrderList[index].deserialize(response);
                    }
                }
            }
        });
    };

    paginatePurchaseOrders = () => {
        console.log(this.pagination);

        if (this.paginateSubscription) {
            if (!this.paginateSubscription.closed) {
                this.paginateSubscription.unsubscribe();
            }
        }
        this.isPaginationLoading = true;
        console.log(this.pagination);
        this.paginateSubscription = this.eloquentManager.paginate(PurchaseOrder, this.pagination).subscribe((response) => {
            if (response) {

                this.purchaseOrderList = response.data;
                this.pagination.length = response.total;
                console.log(this.purchaseOrderList);
            } else {
                console.log(response);
                this.toastService.error('Hata Oluştu', 'Satınalma Siparişleri bilinmeyen bir nedenle yüklenemedi. Lütfen tekrar deneyiniz');
            }

            this.isPaginationLoading = false;
        });
    };


    registerSearchValueChanges = () => {
        this.searchBarCtrl = new FormControl({
            updateOn: 'blur', // default will be change
        });

        this.searchBarCtrl.valueChanges.pipe(
            debounceTime(500)
        ).subscribe((keyword: string) => {

            this.pagination.keyword = (keyword && keyword.length === 0) ? null : keyword;
            this.pagination.pageIndex = 0;
            this.paginatePurchaseOrders();
        });
    };


    orderBy = (order: any) => {
        this.pagination.order = order;
        this.paginatePurchaseOrders();
    };

    filterBySupplier = (supplier: Supplier) => {
        this.isSupplierDropdownOpen = false;
        this.pagination.filter.supplier = supplier;
        this.pagination.pageIndex = 0;
        this.paginatePurchaseOrders();
    };


    searchSuppliers = (query) => {
        return new Promise((resolve, reject) => {
            this.eloquentManager.request('POST', this.config.get('apiBaseUrl') + 'suppliers/get-all-for-autocomplete', {keyword: query})
                .subscribe((response: any) => {
                    if (response) {
                        const modelList = [];
                        const responseArray = response;
                        for (let i = 0; i < responseArray.length; i++) {
                            modelList.push(new Supplier().deserialize(responseArray[i]));
                        }
                        resolve(modelList);
                    }
                    resolve([]);
                });
        });
    };


    searchByWorkOrderProformaId = (proformaID) => {
        // this.searchBarCtrl.setValue(proformaID);
    };

    searchByWorkOrderID = (workOrderID) => {
        this.searchBarCtrl.setValue(workOrderID);
    };

    onPageChange() {
        this.paginatePurchaseOrders();
    }

    resetSearchValue = () => {
        this.searchBarCtrl.reset();
    };


    openPurchaseOrder = (order: PurchaseOrder) => {
        const component: PurchaseOrderComponent = (<PurchaseOrderComponent>this.dynamicComponent.create(PurchaseOrderComponent, DynamicComponentType.SIDEBAR_RIGHT));
        component.title = `#${order.id} - Satınalma Siparişi`;
        component.purchaseOrder = order;
        component.action = PurchaseOrderComponent.EDIT_PURCHASE_ORDER_BY_ID;

        component.onPurchaseOrderSaved = () => {
            this.eloquentManager.reload(order);
        };
    };

    deletePurchaseOrder = (order: PurchaseOrder): void => {
        $(this.confirmModal.nativeElement)
            .modal({
                closable: false,
                onDeny: () => {
                    return true;
                },
                onApprove: () => {
                    this.eloquentManager.delete(order).subscribe(response => {
                        if (response.ok) {
                            // this.notificationService.success('Başarılı', `${attachment.filename} dosyası başarıyla silindi`);
                            this.purchaseOrderList.filter((item) => {
                                return item.id !== order.id;
                            });
                        } else {
                            // this.notificationService.error('Hata Oluştu', `${attachment.filename} dosyası silinirken bir hata oluştu`);
                        }
                    });
                }
            })
            .modal('show');
    };


    openShipmentHistory = (shippable: any, addNew = false, order: PurchaseOrder = null) => {

        if (!shippable.supplier.id && addNew) {
            $('#cannotAddShipmentModal')
                .modal({
                    closable: false

                })
                .modal('show');
            return false;
        }

        const component: ShipmentHistoryComponent = (<ShipmentHistoryComponent>this.dynamicComponent.create(ShipmentHistoryComponent, DynamicComponentType.SIDEBAR_RIGHT));
        component.title = addNew ? 'Sevkiyat Ekle' : `Sevkiyat Geçmişi`;
        component.shippable = shippable;
        component.action = addNew ? ShipmentHistoryComponent.ADD_SHIPMENT_HISTORY : ShipmentHistoryComponent.LIST_SHIPMENT_HISTORY;

        component.onShipmentSaved = () => {
            console.log('onShipmentSaved');
            this.reloadPurchaseOrder(shippable);
        };

    };

    createPDF = (order) => {

        if (!order.supplier.id) {
            $('#cannotCreatePdfModal')
                .modal({
                    closable: false

                })
                .modal('show');
            return false;
        }

        const creatingToast = this.toastService.processing();

        this.eloquentManager.request('GET', this.config.get('apiBaseUrl') + order.getApiPrefix() + '/' + order.id + '/create-pdf').subscribe((response) => {
            console.log(response);
            if (response) {
                this.http.get(response.url, {
                    responseType: 'blob'
                }).subscribe((file: any) => {
                        console.log(file);
                        const blob = new Blob([file], {type: response.mimetype});
                        const filename = response.title;
                        saveAs(blob, filename);
                        this.toastService.processed(creatingToast);
                    },
                    (err) => {
                        this.toastService.saveFailed(creatingToast);
                        console.log(err);
                    });
            } else {
                this.toastService.saveFailed(creatingToast);
            }

        });
    };

}
