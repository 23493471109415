import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

declare const $: any;


@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit, AfterViewInit {

    @Input('title')
    title = 'Emin misiniz?';

    @Input('description')
    description = 'Bu işlem geri alınamaz';

    @Input('onDeny')
    onDeny = () => {
        return true;
    };

    @Input('onApprove')
    onApprove = () => {
        return true;
    };

    @ViewChild('modal', {static: false})
    modal: ElementRef;


    constructor() {
    }

    ngOnInit() {
    }


    ngAfterViewInit(): void {
        setTimeout(() => {
            $(this.modal.nativeElement)
                .modal({
                    closable: false,
                    onDeny: function () {
                        this.onDeny();
                    },
                    onApprove: function () {
                        this.onApprove();
                    }
                })
                .modal('show');
        }, 50);

    }
}
