<app-dynamic-component #dynamicComponent></app-dynamic-component>

<div class="ui container">
    <div class="ui basic segment " style="min-height: 500px">
        <fui-dimmer class="inverted" [(isDimmed)]="isPaginationLoading" [isClickable]="false">
            <div class="ui text loader">Yükleniyor</div>
        </fui-dimmer>
        <div class="ui grid centered margin-top-75 margin-bottom-75" *ngIf="orderList.length === 0">
            <h2 class="ui icon header grey">
                <i class="warning circle icon"></i>
                <div class="content">
                    Size atanmış bir iş emri operasyonu bulunmamaktadır.
                    <div class="sub header margin-top-30">
                        Bir sorun olduğunu düşünüyorsanız <b>İmalat Planlama Sorumlusu</b>'na bilgi veriniz
                    </div>
                </div>
            </h2>
        </div>

        <ng-container *ngIf="!openStartStopLog">
            <div class="ui segments accordion" #operationAccordion>
                <ng-container *ngFor="let order of orderList">
                    <div class="ui segment padding-0 title"
                         [ngClass]="{'disabled': (openStartStopLog && openStartStopLog?.work_order_operation.work_order_id !== order.id)}">
                        <table class="ui table very padded fluid">
                            <tr>
                                <td class="order-status-cell center-vertical center-horizontal">
                                    <div class="ui mini label"
                                         [style.color]="'#ffffff'"
                                         [style.background-color]="order.work_order_status.color">
                                        {{order.work_order_status.name}}
                                    </div>
                                </td>
                                <td class="order-id-cell center-vertical">

                                    <div class="detail-label">
                                        İş Emri
                                    </div>
                                    <div class="order-id">
                                        #{{order.id}}
                                    </div>
                                    <div class="detail-label margin-top-5">
                                        Müşteri
                                    </div>
                                    <div class="truncate">
                                        <b>{{order.customer.title}}</b>
                                    </div>
                                </td>
                                <td class="part-name-cell center-vertical">

                                    <div class="detail-label">
                                        Parça Adı
                                    </div>
                                    <div>
                                        <b>{{order.part_name}}</b>
                                    </div>
                                </td>
                                <td class="deadline-cell center-vertical">

                                    <div class="detail-label">
                                        Toplam Parça
                                    </div>
                                    <div class="truncate">
                                        <b>{{order.quantity}} Adet</b>
                                    </div>
                                    <div class="detail-label margin-top-5">
                                        Termin Tarihi
                                    </div>
                                    <div class="truncate">
                                        <b>{{order.deadline | date: 'dd.MM.yyyy'}}</b>
                                    </div>
                                </td>
                                <td class="accordion-handle-cell">
                                    <div class="margin-bottom-20">
                                        <div class="ui button small blue fluid"
                                             (click)="openUploader(order.id)"
                                        >
                                            <i class="icon upload"></i> Ekler
                                        </div>
                                    </div>
                                    <div class="accordion-handle">
                                        <i class="chevron down icon"></i>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="ui segment padded content">
                        <div class="ui header margin-bottom-15 grey">
                            Operasyonlar
                        </div>
                        <table class="ui celled table">
                            <thead>
                            <tr>
                                <th class="op-status-cell">Durum</th>
                                <th>Tipi</th>
                                <th>Açıklama</th>
                                <th>İstasyon</th>
                                <th>Operatör</th>
                                <th>Termin</th>
                                <th>Tamamlanma</th>
                                <th>&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let operation of order.work_order_operations"
                                [ngClass]="{'disabled': operation.user.id !== user.id || (openStartStopLog && openStartStopLog?.work_order_operation.id !== operation.id)}">
                                <td class="op-status-cell center-vertical center-horizontal">
                                    <div class="ui mini label"
                                         [style.color]="'#ffffff'"
                                         [style.background-color]="operation.work_order_operation_status.color">
                                        {{operation.work_order_operation_status.name}}
                                    </div>
                                </td>
                                <td class="op-type-cell">
                                    {{operation.work_order_operation_type.name}}
                                </td>
                                <td class="op-desc-cell truncate">
                                    {{operation.description}}
                                </td>
                                <td class="op-station-cell">
                                    {{operation.work_station.code}}
                                </td>
                                <td class="op-operator-cell">
                                    {{operation.user.name}}
                                </td>
                                <td class="op-deadline-cell">
                                    {{operation.deadline | date:'dd.MM.yyyy'}}
                                </td>
                                <td class="op-completion-cell">
                                    <fui-progress class="tiny indicating"
                                                  [value]="operation.completion_percentage">
                                        %{{operation.completion_percentage}}
                                    </fui-progress>
                                </td>
                                <td class="op-start-cell">
                                    <div class="ui button large green"
                                         *ngIf="operation.user.id === user.id"
                                         (click)="subscribeAddStartLogTimer(operation)">
                                        <i class="play icon large"></i> Başlat
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="openStartStopLog">
            <div class="ui massive secondary menu borderless" style="margin-top: -30px">
                <div class="item">
                    <button class="ui red bottom fluid button"
                            tabindex="0"
                            (click)="stopOpenLog()"
                    >
                        <i class="stop icon"></i>Durdur
                    </button>
                </div>
                <div class="item">
                    <button class="ui orange bottom fluid massive button"
                            tabindex="0"
                            (click)="openLogHistory(openStartStopLog.work_order_operation, true)"
                    >
                        <i class="sticky note outline icon"></i>Log
                    </button>
                </div>
                <div class="item">
                    <button class="ui green bottom fluid massive button"
                            tabindex="0"
                            (click)="openQualityControl(openStartStopLog.work_order_operation)"
                    >
                        <i class="plus icon"></i>Kalite Kontrol
                    </button>
                </div>
                <div class="right menu">
                    <div class="item">
                        <a class="ui button blue"
                           (click)="openUploader(openStartStopLog.work_order_operation.work_order_id)">
                            <i class="attach icon"></i>
                            Ekler
                        </a>
                    </div>
                </div>
            </div>
            <div class="ui segments">
                <div class="ui segment">
                    <div class="ui header large padding-20">
                        {{openStartStopLog.work_order_operation.work_order_operation_type.name}}
                        <div class="sub header margin-top-10">{{openStartStopLog.work_order_operation.description}}
                        </div>
                    </div>
                </div>
                <div class="ui segment">
                    <div class="ui grid equal width stats-grid">
                        <div class="column text-center">
                            <div class="holosen-stat big">
                                <div>TOPLAM</div>
                                <div class="num">{{openStartStopLog.work_order_operation.work_order?.quantity}}</div>
                                <div>ADET</div>
                            </div>
                        </div>
                        <div class="column text-center">
                            <div>
                                <div class="holosen-stat completed">
                                    <div>TAMAMLANAN</div>
                                    <div class="num">{{openStartStopLog.work_order_operation.completed_num_of_parts}}
                                    </div>
                                    <div>ADET</div>
                                </div>
                                <div class="holosen-stat wasted margin-top-20">
                                    <div>FİRE VERİLEN</div>
                                    <div class="num">{{openStartStopLog.work_order_operation.wasted_num_of_parts}}</div>
                                    <div>ADET</div>
                                </div>
                            </div>
                        </div>
                        <div class="column text-center">
                            <div class="holosen-stat big">
                                <div>KALİTE KONTROL</div>
                                <div class="num">
                                    {{openStartStopLog.work_order_operation.completed_quality_control_num}}/{{openStartStopLog.work_order_operation.quality_control_config.required_control_num}}
                                </div>
                                <div>ÖLÇÜM</div>
                            </div>
                        </div>
                    </div>
                    <fui-progress class="indicating margin-top-20"
                                  [value]="openStartStopLog.work_order_operation.completion_percentage"></fui-progress>
                </div>
                <div class="ui segment padding-0">
                    <table class="ui table very padded fluid">
                        <tr>
                            <!--<td class="order-status-cell center-vertical center-horizontal text-center">-->
                            <!--<div class="ui mini label"-->
                            <!--[style.color]="'#ffffff'"-->
                            <!--[style.background-color]="openStartStopLog.work_order_operation.work_order.work_order_status.color">-->
                            <!--{{openStartStopLog.work_order_operation.work_order.work_order_status.name}}-->
                            <!--</div>-->
                            <!--</td>-->
                            <td class="order-id-cell center-vertical">

                                <div class="detail-label">
                                    İş Emri
                                </div>
                                <div class="order-id">
                                    #{{openStartStopLog.work_order_operation.work_order?.id}}
                                </div>
                                <div class="detail-label margin-top-5">
                                    Müşteri
                                </div>
                                <div class="truncate">
                                    <b>{{openStartStopLog.work_order_operation.work_order?.customer.title}}</b>
                                </div>
                            </td>
                            <td class="part-name-cell center-vertical">

                                <div class="detail-label">
                                    Parça Adı
                                </div>
                                <div>
                                    <b>{{openStartStopLog.work_order_operation.work_order?.part_name}}</b>
                                </div>
                            </td>
                            <td class="deadline-cell center-vertical">
                                <div class="detail-label">
                                    Termin Tarihi
                                </div>
                                <div class="truncate">
                                    <b>{{openStartStopLog.work_order_operation.work_order?.deadline | date:
                                        'dd.MM.yyyy'}}</b>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>

            </div>
        </ng-container>
    </div>
</div>


<!--MODALS-->

<div class="ui small basic modal" #confirmModal>
    <div class="ui icon header">
        <i class="half hourglass hourglass-animation massive icon"></i>
        İşleme Başlanıyor
    </div>
    <div class="content text-center">
        <div class="ui header huge inverted">
            #{{subscribedOperation?.work_order_id}} - {{subscribedOperation?.work_order_operation_type.name}}
        </div>
        <b style="font-size: 75px !important;">{{startLogTimerRemaining}}</b>
    </div>
    <div class="actions text-center margin-top-15">
        <div class="ui cancel button large negative margin-0">
            <i class="remove icon"></i>
            Vazgeç
        </div>
    </div>
</div>


<div class="ui modal" #stopModal>
    <div class="header">İşlemi Durdur</div>
    <div class="scrolling content">
        <div class="ui form  margin-bottom-40">
            <div class="field">
                <div class="ui  pointing below red basic label"
                     *ngIf="noCurrentEndReason"
                >
                    Boş Bırakılamaz
                </div>
                <label>Durma Nedeni Seçiniz</label>
                <div class="ui divider"></div>
                <div class="ui grid three column relaxed">
                    <div class="column" *ngFor="let reason of endReasonList">
                        <button class="ui button blue small fluid huge"
                                [ngClass]="{'green': reason === currentEndReason}"
                                (click)="currentEndReason = reason">
                            {{reason}}
                        </button>
                    </div>
                </div>
                <div class="ui divider"></div>

            </div>
        </div>
        <form class="ui form huge" novalidate [formGroup]="newLog.form_group" (submit)="addStoptLog($event)">

            <div class="two fields">
                <div class="field"
                     [ngClass]="{'error': newLog.form('completed_num_of_parts').hasError('requiredNumber') && (newLog.form('completed_num_of_parts').dirty || newLog.form('completed_num_of_parts').touched)}">
                    <label>Tamamlanan Parça Sayısı</label>
                    <input type="number"
                           formControlName="completed_num_of_parts"
                           [(ngModel)]="newLog.completed_num_of_parts">
                    <div class="ui pointing red basic label"
                         *ngIf="newLog.form('completed_num_of_parts').hasError('requiredNumber') && (newLog.form('completed_num_of_parts').dirty || newLog.form('completed_num_of_parts').touched)">
                        Boş Bırakılamaz
                    </div>
                    <small class="hint">
                        Bir önceki kayıttan itibaren tamamlanan parça sayısı
                    </small>

                </div>

                <div class="field"
                     [ngClass]="{'error': newLog.form('wasted_num_of_parts').hasError('requiredNumber') && (newLog.form('wasted_num_of_parts').dirty || newLog.form('wasted_num_of_parts').touched)}">
                    <label>Fire Verilen Parça Sayısı</label>
                    <input type="number"
                           formControlName="wasted_num_of_parts"
                           [(ngModel)]="newLog.wasted_num_of_parts"
                           #wastedNumOfParts>
                    <div class="ui pointing red basic label"
                         *ngIf="newLog.form('wasted_num_of_parts').hasError('requiredNumber') && (newLog.form('wasted_num_of_parts').dirty || newLog.form('wasted_num_of_parts').touched)">
                        Boş Bırakılamaz
                    </div>
                    <small class="hint">
                        Bir önceki kayıttan itibaren fire verilen parça sayısı
                    </small>

                </div>
            </div>
            <div class="field"
                 *ngIf="wastedNumOfParts.value > 0 || currentEndReason == 'Arıza' || currentEndReason == 'Diğer'"
                 [ngClass]="{'error': newLog.form('additional_notes').hasError('required') && (newLog.form('additional_notes').dirty || newLog.form('additional_notes').touched)}">
                <label>Ek Notlar</label>
                <textarea
                        rows="2"
                        placeholder="Ek Notlar"
                        formControlName="additional_notes"
                        [(ngModel)]="newLog.additional_notes"
                ></textarea>
                <div class="ui pointing red basic label"
                     *ngIf="newLog.form('additional_notes').hasError('required') && (newLog.form('additional_notes').dirty || newLog.form('additional_notes').touched)">
                    Boş Bırakılamaz
                </div>
            </div>

            <div class="actions margin-top-20">

                <div class="ui cancel button huge">
                    <i class="remove icon"></i>
                    Vazgeç
                </div>
                <button type="submit" class="ui button green huge">
                    <i class="icon check"></i>Kaydet
                </button>
                <small class="hint error" *ngIf="hasError">
                    <i class="warning sign icon"></i>Doldurulması Gereken Alanlar Var!
                </small>
            </div>
        </form>
    </div>
</div>


<fui-dimmer class="inverted page" [isDimmed]="pageLoadingDimmerVisible" [isClickable]="false">
    <div class="ui text loader">Yükleniyor</div>
</fui-dimmer>


<div class="ui small basic modal" id="operationCannotStartModal">
    <div class="ui icon header">
        <i class="close icon"></i>
        Operasyon Başlatılamıyor
    </div>
    <div class="content" style="text-align: center !important">
        Bir önceki operasyonda herhangi bir işlem yapılmadığı için operasyon başlatılamıyor.
    </div>
    <div class="actions">
        <div class="ui green ok inverted button">
            <i class="checkmark icon"></i>
            Tamam
        </div>
    </div>
</div>
