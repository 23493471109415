
import {of as observableOf, Subscription, Observable} from 'rxjs';
import {EloquentManagerService} from '../providers/eloquent-manager.provider';
import {Attachable} from './attachable.model';
import {Attachment} from './attachment.model';
import {HttpEventType} from '@angular/common/http';

import {catchError} from 'rxjs/operators';
import {UploaderComponent} from '../../components/uploader/uploader.component';

export class Uploadable {
    private eloquentManager: EloquentManagerService;
    private attachable: Attachable;
    public attachment: Attachment;
    public file: File;
    public uploadPercent = 0;
    public onUploadComplete: any;
    public onUploadAborted: any;

    uploadSubscription: Subscription;
    hasError = false;

    constructor(eloquentManager: EloquentManagerService,
                attachable: Attachable,
                file: File,
                uploader: UploaderComponent) {
        this.eloquentManager = eloquentManager;
        this.attachable = attachable;
        this.file = file;
        this.onUploadComplete = uploader.onUploadComplete;
        this.onUploadAborted = uploader.onUploadAborted;
        this.startUpload();
    }

    private startUpload() {
        this.uploadSubscription = this.eloquentManager.attach(this.attachable, this.file).pipe(
            catchError((error) => {
                console.log(error);
                this.hasError = true;
                return observableOf(false);
            })
        ).subscribe((event: any) => {
            if (event) {
                switch (event.type) {
                    case HttpEventType.UploadProgress: {
                        this.uploadPercent = Math.round(event.loaded / event.total) * 100;
                        break;
                    }
                    case HttpEventType.Response: {
                        const response = event.body;
                        if (response.ok) {
                            this.attachment = new Attachment().deserialize(response.body);
                            this.onUploadComplete(this);
                        }
                        break;
                    }

                }
            }
        });
    }

    abortUpload = () => {
        this.uploadSubscription.unsubscribe();
        this.onUploadAborted(this);
    };

}
