import {NgModule} from '@angular/core';
import {CurrentWorkComponent} from './current-work.component';
import {SharedModule} from '../../../shared/modules/shared.module';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        CurrentWorkComponent
    ],
    exports: [
        CurrentWorkComponent
    ]
})
export class CurrentWorkModule {
}
