import {EloquentSerializable} from '../../interfaces/interfaces';


export enum PAGINATION_ORDER{
    ASCENDING = <any>'ASC',
    DESCENDING = <any>'DESC'
}

export class Pagination implements EloquentSerializable<Pagination> {


    public length: number;
    public page: number;
    public pageIndex: number;
    public pageSize: number;
    public pageSizeOptions: number[];
    public order: any = {};
    public keyword: string = null;
    public filter: any = {};


    constructor() {
        this.length = 0;
        this.page = 1;
        this.pageIndex = 0;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 25, 50, 100];
    }

    deserialize(input: JSON): Pagination {
        return this;
    }
}