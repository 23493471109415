import {WorkOrderComponent} from './work-order.component';
import {AuthenticationService} from '../../../shared/services/authentication/authentication.service';


export const WorkOrderRoutes = [
    {
        path: 'work-order',
        component: WorkOrderComponent,
        data: {
            roles: [
                AuthenticationService.ROLES.ADMIN,
                AuthenticationService.ROLES.PLANNER
            ]
        }
    },
    {
        path: 'work-order/:id',
        component: WorkOrderComponent,
        data: {
            roles: [
                AuthenticationService.ROLES.ADMIN,
                AuthenticationService.ROLES.PLANNER
            ]
        }
    },
]