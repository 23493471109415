import {NgModule} from '@angular/core';
import {SharedImportsModule} from '../../modules/shared-imports.module';
import {UploaderComponent} from './uploader.component';
import {ConfirmationComponent} from '../confirmation/confirmation.component';
import {HolosenPdfViewerModule} from '../holosen-pdf-viewer/holosen-pdf-viewer.module';
import {HolosenPdfViewerComponent} from '../holosen-pdf-viewer/holosen-pdf-viewer.component';

@NgModule({
    imports: [
        SharedImportsModule,
        HolosenPdfViewerModule
    ],
    declarations: [
        UploaderComponent
    ],
    exports: [
        UploaderComponent
    ],
    // entryComponents: [
    //     ConfirmationComponent,
    //     HolosenPdfViewerComponent
    //
    // ]
})
export class UploaderModule {
}
