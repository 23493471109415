import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReportingComponent} from './reporting.component';
import {SharedModule} from '../../../shared/modules/shared.module';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        ReportingComponent
    ],
    exports: [
        ReportingComponent
    ]
})
export class ReportingModule {
}
