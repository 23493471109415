import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'inArray'
})
export class InArrayPipe implements PipeTransform {
    transform(items: any[], values: string[]): boolean {
        for (let i = 0; i < items.length; i++) {
            for (let j = 0; j < values.length; j++) {
                if (values[j] === items[i].slug) {
                    return true;
                }
            }
        }
        return false;
    }
}