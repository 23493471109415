import {NgModule} from '@angular/core';
import {ToastContainerComponent} from './toast-container.component';
import {ToastComponent} from './toast.component';
import {SharedImportsModule} from '../../modules/shared-imports.module';


@NgModule({
    imports: [
        SharedImportsModule
    ],
    declarations: [
        ToastContainerComponent,
        ToastComponent
    ],
    exports: [
        ToastContainerComponent,
        ToastComponent
    ],

    providers: [],
})
export class ToastModule {
}
