import {Component, Input, OnInit, Output} from '@angular/core';
import {WorkOrderOperation} from '../../eloquent/models/work-order-operation.model';
import {EloquentManagerService} from '../../eloquent/providers/eloquent-manager.provider';
import {ConfigService} from '../../services/config/config.service';
import {Log} from '../../eloquent/models/log.model';
import {ValidationErrors} from '@angular/forms';
import {ToastService} from '../../services/toast/toast.service';
import {StartStopLog} from '../../eloquent/models/start-stop-log.model';

@Component({
    selector: 'app-start-stop-log-history',
    templateUrl: './start-stop-log-history.component.html',
    styleUrls: ['./start-stop-log-history.component.scss']
})
export class StartStopLogHistoryComponent implements OnInit {

    @Input('operation') operation: WorkOrderOperation;
    @Input('title') title = 'Başlat-Bitir Kayıtları';


    logList: Array<StartStopLog>;
    newLog: Log;
    isLogsLoading = true;

    @Output('close') close = () => {
        return false;
    };


    constructor(private eloquentManager: EloquentManagerService,
                private configService: ConfigService,
                private toastService: ToastService) {
    }

    ngOnInit() {
        this.isLogsLoading = true;
        this.eloquentManager.request('GET', this.configService.get('apiBaseUrl') + this.operation.getApiPrefix() + '/' + this.operation.id + '/get-start-stop-logs').subscribe((response) => {
            if (response) {
                const modelList = [];
                const responseArray = response;
                for (let i = 0; i < responseArray.length; i++) {
                    modelList.push(new StartStopLog().deserialize(responseArray[i]));
                }
                this.logList = modelList;
            }

            this.isLogsLoading = false;
        });
    }

}
