import {AuthenticationService} from '../../../shared/services/authentication/authentication.service';
import {OperatorComponent} from './operator.component';


export const OperatorRoutes = [
    {
        path: 'operator',
        component: OperatorComponent,
        data: {
            roles: [
                AuthenticationService.ROLES.ADMIN,
                AuthenticationService.ROLES.OPERATOR
            ]
        }
    }
];