<div class="ui sidebar vertical menu" #sidebar id="sidebar">

    <a class="item" [routerLink]="['/current-work']" [routerLinkActive]="['active']"
       *ngIf=" user.roles | inArray:[ROLES.ADMIN, ROLES.PLANNER, ROLES.VIEWER] ">
        <i class="checked calendar icon left"></i>
        Anlık İş Durumu
    </a>
    <a class="item" [routerLink]="['/planning']" [routerLinkActive]="['active']"
       *ngIf=" user.roles | inArray:[ROLES.ADMIN, ROLES.PLANNER] ">
        <i class="checked calendar icon left"></i>
        Planlama
    </a>
    <a class="item" [routerLink]="['/operator']" [routerLinkActive]="['active']"
       *ngIf=" user.roles | inArray:[ROLES.ADMIN, ROLES.OPERATOR] ">
        <i class="users icon left "></i>
        Operatör Ekranı
    </a>
    <a class="item" [routerLink]="['/user-management']" [routerLinkActive]="['active']"
       *ngIf="user.roles| inArray: [ROLES.ADMIN] ">
        <i class="users icon left "></i>
        Kullanıcı Yönetimi
    </a>
    <a class="item" [routerLink]="['/purchase']" [routerLinkActive]="['active']"
       *ngIf="user.roles | inArray: [ROLES.ADMIN, ROLES.PLANNER]">
        <i class="checked calendar icon left"></i>
        Satınalma
    </a>
    <a class="item" [routerLink]="['/work-order']" [routerLinkActive]="['active']"
       *ngIf="user.roles | inArray: [ROLES.ADMIN, ROLES.PLANNER]">
        <i class="add square icon left "></i>
        Yeni İş Emri
    </a>
    <a class="item" [routerLink]="['/statuses']" [routerLinkActive]="['active']"
       *ngIf="user.roles | inArray: [ROLES.ADMIN]">
        <i class="check circle outline icon left"></i>
        Durum Yönetimi
    </a>
    <a class="item" [routerLink]="['/reporting']" [routerLinkActive]="['active']"
       *ngIf="user.roles | inArray: [ROLES.ADMIN, ROLES.PLANNER]">
        <i class="line chart icon left"></i>
        Raporlama
    </a>
    <a class="item" [routerLink]="['/work-station']" [routerLinkActive]="['active']"
       *ngIf="user.roles | inArray: [ROLES.ADMIN]">
        <i class="user circle icon left"></i>
        İstasyonlar ve Personel
    </a>
    <div class="ui divider margin-top-30 margin-bottom-30"></div>
    <a class="item" [routerLink]="['/me']" [routerLinkActive]="['active']">
        <i class="address card outline icon left"></i>
        Hesabım
    </a>
    <a class="item" [routerLink]="['/logout']" [routerLinkActive]="['active']">
        <i class="sign out icon left"></i>
        Çıkış
    </a>

</div>
<div class="pusher">
    <div class="ui fixed menu top-menu">
        <a class="item" (click)="toggleSidebar()">
            <i class="sidebar icon"></i>
        </a>
        <span class="item header">
        {{pageTitle}}
        </span>
        <div class="right menu">
            <div class="item">
                {{user.name}}
            </div>
        </div>
    </div>
    <div class="ui basic segment" style="padding-top: 70px;">
        <router-outlet></router-outlet>
    </div>
</div>