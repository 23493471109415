import {NgModule} from '@angular/core';
import {SharedImportsModule} from '../../modules/shared-imports.module';
import {PaginationComponent} from './pagination.component';

@NgModule({
    imports: [
        SharedImportsModule
    ],
    declarations: [
        PaginationComponent
    ],
    exports: [
        PaginationComponent
    ]
})
export class PaginationModule {
}
