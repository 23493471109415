import {NgModule} from '@angular/core';
import {SharedImportsModule} from '../../modules/shared-imports.module';
import {ConfirmationComponent} from './confirmation.component';

@NgModule({
    imports: [
        SharedImportsModule
    ],
    declarations: [
        ConfirmationComponent
    ],
    exports: [
        ConfirmationComponent
    ]
})
export class ConfirmationModule {
}
