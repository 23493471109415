import {BaseModel} from './base.model';
import {EloquentSerializable} from '../interfaces/interfaces';
import {User} from './user.model';
export class Attachment extends BaseModel implements EloquentSerializable<Attachment> {


    public static readonly API_PREFIX: string = 'attachments';


    public id: number = null;
    public created_by: number = null;
    public created_at: Date = new Date();
    public filename = '';
    public filesize = '';
    public full_url = '';
    public rel_url = '';
    public thumb_full_url = '';
    public thumb_rel_url = '';
    public extension = '';
    public mimetype = '';
    public uploaded_by: User = new User();

    public validation: Array<any>;

    public getApiPrefix(): string {
        return Attachment.API_PREFIX;
    }


    deserialize(input: JSON): Attachment {
        this.id = input['id'];
        this.created_by = input['created_by'];
        this.created_at = new Date(input['created_at']);
        this.filename = input['filename'];
        this.filesize = input['filesize'];
        this.full_url = input['full_url'];
        this.rel_url = input['rel_url'];
        this.thumb_full_url = input['thumb_full_url'];
        this.thumb_rel_url = input['thumb_rel_url'];
        this.mimetype = input['mimetype'];
        this.extension = input['extension'];

        this.uploaded_by = this.uploaded_by.deserialize(input['uploaded_by']);

        return this;
    }


}
