
export enum ToastType {
    SUCCESS = 1,
    ERROR = 2,
    WARN = 3,
    INFO = 4
}

export class Toast {
    public id: number;
    public title: string;
    public content: string;
    public type: ToastType;


    constructor(id: number, title: string, content: string, type: ToastType) {
        this.id = id;
        this.title = title;
        this.content = content;
        this.type = type;
    }
}