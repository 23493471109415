import {BaseModel} from './base.model';
import {EloquentSerializable} from '../interfaces/interfaces';


export class WorkOrderOperationStatus extends BaseModel implements EloquentSerializable<WorkOrderOperationStatus> {


    public static readonly API_PREFIX: string = 'work-order-operation-statuses';
    public id: number = null;
    public name = '';
    public description = '';
    public color = '';
    public seq_num: number = null;

    public validation: Array<any>;

    public getApiPrefix(): string {
        return WorkOrderOperationStatus.API_PREFIX;
    }


    public deserialize(input: Object): WorkOrderOperationStatus {
        this.id = input['id'];
        this.name = input['name'];
        this.description = input['description'];
        this.color = input['color'];
        this.seq_num = input['seq_num'];

        return this;
    }




}
