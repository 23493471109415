<div class="ui container padding-bottom-75 content-background very padded basic segment">
    <div class="ui basic segment " style="min-height: 500px">
        <fui-dimmer class="inverted" [(isDimmed)]="isStationsLoading" [isClickable]="false">
            <div class="ui text loader">Yükleniyor</div>
        </fui-dimmer>
        <div class="ui accordion fluid" #stationAccordion>
            <ng-container *ngFor="let station of stationList">
                <div class="title">
                    <div class="margin-bottom-15">
                        <div class="ui icon buttons right floated">
                            <div class="ui button basic grey dropdown" fuiDropdown>
                                <i class="ellipsis vertical icon"></i>
                                <div class="menu" fuiDropdownMenu>
                                    <div class="item error"
                                         (click)="deleteStation(station)">
                                        <i class="remove icon"></i>
                                        Sil
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="ui grid clearing padding-top-15 padding-bottom-20">
                        <div class="eleven wide column">
                            <div class="station-code margin-bottom-15">
                                {{station.code}}
                            </div>
                            <div class="station-name operation-type margin-bottom-15">
                                {{station.name}}
                            </div>
                        </div>
                        <div class="four wide column text-center">
                            <div class="ui tiny horizontal statistic total-operation-statistic">
                                <div class="value">
                                    {{station.users.length}}
                                </div>
                                <div class="label">
                                    Pers.
                                </div>
                            </div>

                        </div>
                        <div class="one wide column accordion-handle">
                            <i class="chevron down icon"></i>
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div class="content-inner">
                        <div class="clearfix"></div>
                        <div class="ui large header grey margin-top-20 margin-bottom-20">
                            İş İstasyonuna Atanmış Personneller
                        </div>
                        <div class="ui very padded segment">
                            <div class="ui form">
                                <div class="field" *ngFor="let user of userList">
                                    <fui-checkbox
                                            [ngModel]="station.user_id_list.includes(user.id)"
                                            (ngModelChange)="togglePersonnelStation(station,user.id)">
                                        {{user.name}}
                                    </fui-checkbox>
                                </div>
                                <div class="field right aligned">
                                    <button class="ui button positive large" (click)="updateStation(station)">Kaydet</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<button class="circular ui icon button fab bottom right red huge"
        fuiPopup
        popupText="Yeni İş İstasyonu Ekle"
        popupPlacement="left"
        [popupInverted]="true"
        (click)="openStationModal()"
>
    <i class="icon plus"></i>
</button>




<!--MODALS-->
<div class="ui small modal" id="stationModal">
    <div class="header">
        {{editingStation?.id ? 'İş İstasyonunu Düzenle' : 'Yeni İş İstasyonu Ekle'}}
    </div>
    <div class="scrolling content">
        <form class="ui form" [formGroup]="editingStation.form_group">
            <div class="field"
                 [ngClass]="{'error': editingStation.form('name').hasError('required') && (editingStation.form('name').dirty || editingStation.form('name').touched)}"
            >
                <label>İstasyon Adı</label>
                <input
                        type="text"
                        placeholder="İstasyon Adı"
                        formControlName="name"
                        [(ngModel)]="editingStation.name"
                >
                <div class="ui pointing red basic label"
                     *ngIf="editingStation.form('name').hasError('required') && (editingStation.form('name').dirty || editingStation.form('name').touched)">
                    Boş Bırakılamaz
                </div>
            </div>

            <div class="field"
                 [ngClass]="{'error': editingStation.form('code').hasError('required') && (editingStation.form('code').dirty || editingStation.form('code').touched)}"
            >
                <label>İstasyon Kodu</label>
                <input
                        type="text"
                        placeholder="İstasyon Kodu"
                        formControlName="code"
                        [(ngModel)]="editingStation.code"
                >
                <div class="ui pointing red basic label"
                     *ngIf="editingStation.form('code').hasError('required') && (editingStation.form('code').dirty || editingStation.form('code').touched)">
                    Boş Bırakılamaz
                </div>
            </div>

        </form>
    </div>
    <div class="actions">
        <div class="ui red cancel inverted button">
            <i class="remove icon"></i>
            Vazgeç
        </div>
        <div class="ui green inverted button" (click)="saveStation()">
            <i class="checkmark icon"></i>
            Kaydet
        </div>
    </div>
</div>
