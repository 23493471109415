import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {StorageService} from '../storage/storage.service';
import {Observable} from 'rxjs';


@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {


    constructor(private storage: StorageService) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const tokenData = this.storage.get(StorageService.VARS.TOKEN_DATA);
        if (tokenData) {
            const authReq = req.clone({
                setHeaders: {
                    Accept: 'application/json',
                    Authorization: tokenData.token_type + ' ' + tokenData.access_token
                }
            });
            return next.handle(authReq);
        } else {
            return next.handle(req);
        }

    }
}
