import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {ToastService} from './toast.service';
import {Toast} from './toast.model';

@Component({
    selector: 'app-toast-container',
    templateUrl: 'toast-container.component.html',
    styleUrls: ['toast-container.component.scss']
})
export class ToastContainerComponent implements OnInit, OnDestroy {

    public toastList: Array<Toast>;
    public toastSubscription: Subscription;

    constructor(private toastService: ToastService) {
    }

    ngOnInit() {
        this.toastSubscription = this.toastService.toastEvent.subscribe(response => {
            this.toastList = response;
        });
    }


    ngOnDestroy(): void {
        this.toastSubscription.unsubscribe();
    }
}
