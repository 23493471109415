<div class="holosen-pdf-viewer-container">
    <div class="toolbar">
        <div class="ui huge menu basic borderless inverted grey">
            <div class="item">
                <button class="ui button white" (click)="zoomIn()">
                    <i class="icon zoom in"></i> Yaklaş
                </button>
            </div>
            <div class="item">
                <button class="ui button white" (click)="zoomOut()">
                    <i class="icon zoom out"></i> Uzaklaş
                </button>
            </div>
            <div class="item">
                <button class="ui button white" (click)="rotateLeft()">
                    <i class="reply icon"></i> Sola Döndür
                </button>
            </div>
            <div class="item">
                <button class="ui button white" (click)="rotateRight()">
                    <i class="share icon"></i> Sağa Döndür
                </button>
            </div>
            <div class="item">
                {{title}}
            </div>
            <div class="right menu">
                <div class="item">
                    <button class="ui button icon red" (click)="close()">
                        <i class="icon close"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="pdf-wrapper fill-remaining-space padding-top-30 padding-bottom-30">
        <ng-container *ngIf="config.src.url">
            <pdf-viewer [src]="config.src"
                        [render-text]="config.render_text"
                        style="display: block;"
                        [zoom]="config.zoom"
                        [rotation]="config.rotation"
            ></pdf-viewer>
        </ng-container>
    </div>
</div>