import {NgModule} from '@angular/core';
import {AuthenticatedWrapperComponent} from './authenticated-wrapper.component';
import {SharedModule} from '../../../shared/modules/shared.module';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        AuthenticatedWrapperComponent
    ],
    exports: [
        AuthenticatedWrapperComponent
    ]
})
export class AuthenticatedWrapperModule {
}
