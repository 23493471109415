import {NgModule} from '@angular/core';
import {SharedModule} from '../../../shared/modules/shared.module';
import {PublicWrapperComponent} from './public-wrapper.component';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        PublicWrapperComponent
    ],
    exports: [
        PublicWrapperComponent
    ]
})
export class PublicWrapperModule {
}
