import {Component, Input, OnInit, Output} from '@angular/core';
import {EloquentManagerService} from '../../eloquent/providers/eloquent-manager.provider';
import {ConfigService} from '../../services/config/config.service';
import {Log} from '../../eloquent/models/log.model';
import {ValidationErrors} from '@angular/forms';
import {ToastService} from '../../services/toast/toast.service';
import {PurchaseOrder} from '../../eloquent/models/purchase-order.model';
import {WorkOrderOperation} from '../../eloquent/models/work-order-operation.model';
import {Customer} from '../../eloquent/models/customer.model';

@Component({
    selector: 'app-purchase-order',
    templateUrl: './purchase-order.component.html',
    styleUrls: ['./purchase-order.component.scss']
})
export class PurchaseOrderComponent implements OnInit {

    static readonly LIST_PURCHASE_ORDER = 'list-purchase-order';
    static readonly ADD_PURCHASE_ORDER = 'add-purchase-order';
    static readonly EDIT_PURCHASE_ORDER = 'edit-purchase-order';
    static readonly EDIT_PURCHASE_ORDER_BY_ID = 'edit-purchase-order-by-id';

    readonly LIST_PURCHASE_ORDER = PurchaseOrderComponent.LIST_PURCHASE_ORDER;
    readonly ADD_PURCHASE_ORDER = PurchaseOrderComponent.ADD_PURCHASE_ORDER;
    readonly EDIT_PURCHASE_ORDER = PurchaseOrderComponent.EDIT_PURCHASE_ORDER;
    readonly EDIT_PURCHASE_ORDER_BY_ID = PurchaseOrderComponent.EDIT_PURCHASE_ORDER_BY_ID;


    @Input('title') title = 'Satınalma Teklifi';
    @Input('action') action = this.LIST_PURCHASE_ORDER;
    @Input('purchaseOrderID') purchaseOrderID = null;
    @Input('purchaseOrder') purchaseOrder: PurchaseOrder = new PurchaseOrder();

    isPurchaseOrderLoading = true;
    isSupplierChanging: false;

    @Output('close') close = () => {
        return false;
    };

    @Output('onPurchaseOrderSaved') onPurchaseOrderSaved = (): any => {
        return;
    };


    constructor(private eloquentManager: EloquentManagerService,
                private configService: ConfigService,
                private toastService: ToastService) {
    }

    ngOnInit() {


        switch (this.action) {
            default:
            case this.ADD_PURCHASE_ORDER: {
                this.isPurchaseOrderLoading = false;
                this.purchaseOrder = new PurchaseOrder();

                break;
            }
            case this.EDIT_PURCHASE_ORDER: {

                this.eloquentManager.getById(PurchaseOrder, this.purchaseOrderID).subscribe((response) => {
                    if (response) {

                        this.purchaseOrder = this.purchaseOrder.deserialize(response);
                        this.purchaseOrder.form('purchase_order_type').setValue(this.purchaseOrder.purchase_order_type);

                        console.log(this.purchaseOrder);
                    }

                    this.isPurchaseOrderLoading = false;
                });


                break;
            }
            case this.EDIT_PURCHASE_ORDER_BY_ID: {
                this.isPurchaseOrderLoading = false;
                break;
            }
        }
    }

    savePurchaseOrder = () => {
        console.log('savePurchaseOrder');
        console.log(this.purchaseOrder.form_group);
        this.purchaseOrder.revalidate();


        if (this.purchaseOrder.form_group.invalid) {

            Object.keys(this.purchaseOrder.form_group.controls).forEach(key => {

                const controlErrors: ValidationErrors = this.purchaseOrder.form_group.get(key).errors;
                if (controlErrors != null) {
                    Object.keys(controlErrors).forEach(keyError => {
                        console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                    });
                }
            });


            return false;
        }

        if (this.purchaseOrder.id) {
            this.eloquentManager.update(this.purchaseOrder).subscribe(response => {
                if (response) {
                    this.onPurchaseOrderSaved();
                    this.close();
                }
            });
        } else {
            this.eloquentManager.save(this.purchaseOrder).subscribe(response => {
                if (response) {
                    this.onPurchaseOrderSaved();
                    this.close();
                }
            });
        }
    };


    logCurrentOrder = () => {
        console.log(this.purchaseOrder);
    };


    searchSuppliers = (query) => {
        return new Promise((resolve, reject) => {
            this.eloquentManager.request('POST', this.configService.get('apiBaseUrl') + 'suppliers/get-all-for-autocomplete', {keyword: query})
                .subscribe((response: any) => {
                    if (response) {
                        const modelList = [];
                        const responseArray = response;
                        for (let i = 0; i < responseArray.length; i++) {
                            modelList.push(new Customer().deserialize(responseArray[i]));
                        }
                        console.log(modelList);
                        resolve(modelList);
                    }
                    resolve([]);
                });
        });
    };

    selectSupplier = (supplier) => {
        this.purchaseOrder.supplier = supplier;
        this.purchaseOrder.form('supplier').setValue(supplier);
        this.changeSupplier(false);
    };

    changeSupplier = (value) => {
        this.isSupplierChanging = value;
    };

    supplierDisplayFn(supplier): string {
        return supplier ? supplier.title : supplier;
    }

}
