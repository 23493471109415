import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WorkStationComponent} from './work-station.component';
import {SharedImportsModule} from '../../../shared/modules/shared-imports.module';
import {SharedModule} from '../../../shared/modules/shared.module';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        WorkStationComponent
    ]
})
export class WorkStationModule {
}
