import {NgModule} from '@angular/core';
import {PurchaseComponent} from './purchase.component';
import {SharedModule} from '../../../shared/modules/shared.module';
import {ShipmentHistoryComponent} from '../../../shared/components/shipment-history/shipment-history.component';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        PurchaseComponent
    ],
    exports: [
        PurchaseComponent
    ],
    // entryComponents: [
    //     ShipmentHistoryComponent
    // ]
})
export class PurchaseModule {
}
