import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../shared/services/authentication/authentication.service';

@Component({
    selector: 'app-logout',
    template: ''
})
export class LogoutComponent implements OnInit {
    constructor(protected auth: AuthenticationService,
                protected router: Router) {
    }

    ngOnInit() {
        this.auth.logout().subscribe((response) => {
            switch (response) {
                case AuthenticationService.RESPONSE.LOGOUT_SUCCESSFUL: {
                    this.router.navigate(['/login']);
                    break;
                }
            }
        });
    }


}
