import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EloquentManagerService} from '../../../shared/eloquent/providers/eloquent-manager.provider';
import {LayoutService} from '../../../shared/services/layout/layout.service';
import {ConfigService} from '../../../shared/services/config/config.service';
import {Me} from '../../../shared/eloquent/models/me.model';
import {WorkOrderOperation} from '../../../shared/eloquent/models/work-order-operation.model';
import {AuthenticationService} from '../../../shared/services/authentication/authentication.service';
import {User} from '../../../shared/eloquent/models/user.model';
import {Subscription, timer} from 'rxjs';
import {StartStopLog} from '../../../shared/eloquent/models/start-stop-log.model';
import {ToastService} from '../../../shared/services/toast/toast.service';
import {Log} from '../../../shared/eloquent/models/log.model';
import {UploaderComponent} from '../../../shared/components/uploader/uploader.component';
import {WorkOrder} from '../../../shared/eloquent/models/work-order.model';
import {
  DynamicComponentComponent,
  DynamicComponentType
} from '../../../shared/components/dynamic-component/dynamic-component.component';
import {QualityControlComponent} from '../../../shared/components/quality-control/quality-control.component';
import {LogHistoryComponent} from '../../../shared/components/log-history/log-history.component';
import {filter} from 'rxjs/operators';

declare const $: any;

@Component({
    selector: 'app-operator',
    templateUrl: './operator.component.html',
    styleUrls: ['./operator.component.scss']
})
export class OperatorComponent implements OnInit, AfterViewInit, OnDestroy {

    private pageTitle = 'Operatör Ekranı';

    operationList: Array<WorkOrderOperation> = [];
    orderList: Array<WorkOrder> = [];

    @ViewChild('operationAccordion', {static: false})
    operationAccordion: ElementRef;

    @ViewChild('confirmModal', {static: false})
    confirmModal: ElementRef;

    @ViewChild('stopModal', {static: false})
    stopModal: ElementRef;

    @ViewChild('dynamicComponent', {static: false})
    dynamicComponent: DynamicComponentComponent;

    isPaginationLoading = true;
    user: User;
    private userSubscripton: Subscription;
    private addStartLogSubscription;
    startLogTimerRemaining = 3;
    newLog: Log = new Log();

    pageLoadingDimmerVisible = false;

    openStartStopLog: StartStopLog = null;


    endReasonList = [
        'Mola',
        'Öğle Arası',
        'Mesai Bitimi',
        'İşlem Tamamlandı',
        'Arıza',
        'Diğer'
    ];

    currentEndReason = '';
    noCurrentEndReason = false;
    hasError = false;

    subscribedOperation: WorkOrderOperation = null;

    constructor(private eloquentManager: EloquentManagerService,
                private layoutService: LayoutService,
                private configService: ConfigService,
                private authenticationService: AuthenticationService,
                private toastService: ToastService) {
        this.layoutService.setTitle(this.pageTitle);
        this.subscribeUser();
    }

    ngOnInit() {
        this.subscribeEloquentEvents();
    }

    ngAfterViewInit(): void {

        this.initAccordion();
    }


    initAccordion = () => {
        if (this.operationAccordion) {

            $(this.operationAccordion.nativeElement).accordion(
                {
                    selector: {
                        trigger: '.title .accordion-handle'
                    },
                    onOpening: function () {
                        console.log('onOpening');
                    },
                    onOpen: (event) => {
                        const openIndex = $('.ui.accordion .title.active');

                        $('html,body').animate({
                                scrollTop: openIndex.offset().top - 75
                            },
                            450);
                    }
                }
            );
        }
    };

    ngOnDestroy(): void {
        this.unsubscribeUser();
    }

    getMyWorkOrderOperations = () => {
        this.eloquentManager.request('GET', this.configService.get('apiBaseUrl') + Me.API_PREFIX + '/work-order-operations-by-work-order').subscribe((response) => {
            if (response) {


                const modelList = [];
                const responseArray = response;
                for (let i = 0; i < responseArray.length; i++) {
                    modelList.push(new WorkOrder().deserialize(responseArray[i]));
                }
                this.orderList = modelList;
                setTimeout(this.initAccordion, 50);


            }
            this.isPaginationLoading = false;

        });
    };


    private subscribeUser = () => {
        this.userSubscripton = this.authenticationService.user.subscribe((user) => {
            this.user = user;
            this.getMyWorkOrderOperations();
            if (this.user.has_open_start_stop_log) {
                this.getOpenStartStopLog();
            } else {
                this.openStartStopLog = null;
            }
            console.log(this.user);
        });
    };

    private unsubscribeUser = () => {
        this.userSubscripton.unsubscribe();
    };

    subscribeAddStartLogTimer = (operation: WorkOrderOperation) => {

        if (!operation.can_start) {
            $('#operationCannotStartModal')
                .modal({
                    closable: false

                })
                .modal('show');
            return false;
        }

        this.subscribedOperation = operation;
        console.log(this.subscribedOperation);
        const $that = this;
        $that.startLogTimerRemaining = 3;
        const modal = $(this.confirmModal.nativeElement);
        modal
            .modal({
                closable: false,
                observeChanges: true,
                onDeny: () => {
                    $that.unsubscribeAddStartLogTimer();
                },
                onVisible: function () {

                    const countdown = timer(500, 1000);
                    $that.addStartLogSubscription = countdown.subscribe((t) => {
                        console.log($that.startLogTimerRemaining);
                        if (!--$that.startLogTimerRemaining) {
                            modal.modal('hide');
                            $that.pageLoadingDimmerVisible = true;
                            $that.addStartLog(operation);
                            $that.unsubscribeAddStartLogTimer();
                        }
                    });
                }
            })
            .modal('show');
    };

    unsubscribeAddStartLogTimer = () => {
        this.subscribedOperation = null;
        this.addStartLogSubscription.unsubscribe();
    };


    private getOpenStartStopLog = () => {
        this.pageLoadingDimmerVisible = true;
        this.eloquentManager.request('GET', this.configService.get('apiBaseUrl') + Me.API_PREFIX + '/open-start-stop-log').subscribe((response) => {
            if (response) {
                const startStopLog = new StartStopLog().deserialize(response);
                this.openStartStopLog = startStopLog;
            }

            console.log(this.openStartStopLog);
            this.pageLoadingDimmerVisible = false;
        });
    };

    private addStartLog = (operation: WorkOrderOperation) => {
        this.eloquentManager.request('GET', this.configService.get('apiBaseUrl') + operation.getApiPrefix() + '/' + operation.id + '/add-start-log').subscribe((response) => {
            console.log(response);
            if (response) {
                this.openStartStopLog = new StartStopLog().deserialize(response);
            } else {
                console.log('oluşturamazsın');
                this.toastService.error('Hata Oluştu', 'Tamamlanmamış bir günlük kaydınız bulunduğu için yeni bir günlük kaydı başlatamazsınız');
            }
            this.authenticationService.getCurrentUserFromDd().subscribe((user) => {
                this.pageLoadingDimmerVisible = false;
            });

        });
    };

    public stopOpenLog = () => {
        const $that = this;
        $that.newLog = new Log();

        const modal = $(this.stopModal.nativeElement);
        modal
            .modal({
                closable: true,
                observeChanges: true,
                onDeny: () => {

                }
            })
            .modal('show');
    };


    public addStoptLog = (event) => {

        event.preventDefault();


        this.newLog.revalidate();

        this.hasError = false;

        if (this.newLog.form_group.invalid) {
            this.hasError = true;
        }
        this.noCurrentEndReason = false;
        if (this.currentEndReason === '' || !this.currentEndReason) {
            this.noCurrentEndReason = true;
            this.hasError = true;
        }

        if (this.hasError) {
            return false;
        }

        const dataToSend = {
            log: this.newLog,
            reason: this.currentEndReason,
            openStartStopLog: this.openStartStopLog
        };
        const modal = $(this.stopModal.nativeElement);
        modal.modal('hide');
        this.pageLoadingDimmerVisible = true;
        this.eloquentManager.request('POST', this.configService.get('apiBaseUrl') + WorkOrderOperation.API_PREFIX + '/' + this.openStartStopLog.work_order_operation.id + '/add-stop-log', dataToSend).subscribe((response) => {
            console.log(response);
            if (response) {

                this.eloquentManager.recalculateCompletion(this.openStartStopLog.work_order_operation);
            }

            this.authenticationService.getCurrentUserFromDd().subscribe((user) => {
                this.pageLoadingDimmerVisible = false;
            });

            this.currentEndReason = '';

        });
    };


    openUploader = (orderID) => {
        this.eloquentManager.getById(WorkOrder, orderID).subscribe((response) => {
            if (response) {
                const component: UploaderComponent = (<UploaderComponent>this.dynamicComponent.create(UploaderComponent, DynamicComponentType.SIDEBAR_RIGHT));
                component.title = `Ekleri Yönet | İş Emri: #${response.id}`;
                component.canUpload = false;
                component.attachable = response;
            }
        });
    };


    openQualityControl = (operation: WorkOrderOperation) => {
        const component: QualityControlComponent = (<QualityControlComponent>this.dynamicComponent.create(QualityControlComponent, DynamicComponentType.SIDEBAR_RIGHT));
        component.operation = operation;
    };


    openLogHistory = (operation: WorkOrderOperation, addNew = false) => {
        const component: LogHistoryComponent = (<LogHistoryComponent>this.dynamicComponent.create(LogHistoryComponent, DynamicComponentType.SIDEBAR_RIGHT));
        component.operation = operation;
        component.title = addNew ? `Günlük Kaydı Ekle | #${operation.work_order_id} - ${operation.work_order_operation_type.name}` : `Günlük Kayıt Geçmişi | #${operation.work_order_id} - ${operation.work_order_operation_type.name}`;
        component.action = addNew ? LogHistoryComponent.ADD_LOG_HISTORY : LogHistoryComponent.LIST_LOG_HISTORY;
        component.onLogSaved = () => {
            this.eloquentManager.recalculateCompletion(operation);
        };
    };

    subscribeEloquentEvents = () => {
        this.eloquentManager.onUpdated.pipe(
            filter((response) => {

                return response.className === 'WorkOrder' || response.className === 'WorkOrderOperation';
            })
        ).subscribe((response) => {
            switch (response.className) {
                case 'WorkOrder': {
                    const id = response.object.id;
                    for (const index in this.orderList) {
                        if (this.orderList[index].id === id) {
                            this.eloquentManager.reload(this.orderList[index]);
                        }
                    }
                    break;
                }
                case 'WorkOrderOperation': {

                    const id = response.object.id;
                    for (const orderIndex in this.orderList) {
                        for (const operationIndex in this.orderList[orderIndex].work_order_operations) {
                            if (this.orderList[orderIndex].work_order_operations[operationIndex].id === id) {
                                this.eloquentManager.reload(this.orderList[orderIndex].work_order_operations[operationIndex]);
                            }
                        }
                    }

                    break;
                }
                default: {
                    break;
                }
            }
        });


        this.eloquentManager.onCreated.pipe(
            filter((response) => {

                return response.className === 'WorkOrder' || response.className === 'WorkOrderOperation';
            })
        ).subscribe((response) => {
            switch (response.className) {
                case 'WorkOrderOperation':
                case 'WorkOrder': {
                    this.eloquentManager.request('GET', this.configService.get('apiBaseUrl') + Me.API_PREFIX + '/work-order-operations-by-work-order').subscribe((response2) => {
                        if (response2) {
                            const modelList = [];
                            const responseArray = response2;
                            console.log(responseArray);

                            for (let i = 0; i < responseArray.length; i++) {
                                let found = false;
                                for (const orderIndex in this.orderList) {
                                    console.log(this.orderList[orderIndex].id, responseArray[i].id);
                                    if (this.orderList[orderIndex].id === responseArray[i].id) {
                                        found = true;
                                        break;
                                    }
                                }
                                if (!found) {
                                    this.orderList.push(new WorkOrder().deserialize(responseArray[i]));
                                }
                            }

                            setTimeout(this.initAccordion, 50);


                        }

                    });
                    break;
                }
                default: {
                    break;
                }
            }
        });


        this.eloquentManager.onDeleted.pipe(
            filter((response) => {

                return response.className === 'WorkOrder' || response.className === 'WorkOrderOperation' || response.className === 'Log';
            })
        ).subscribe((response) => {
            switch (response.className) {
                case 'WorkOrder': {
                    this.orderList = this.orderList.filter(item => {
                        return item.id !== response.object.id;
                    });
                    break;
                }
                case 'WorkOrderOperation': {
                    for (const orderIndex in this.orderList) {
                        if (this.orderList[orderIndex].id === response.object.work_order_id) {
                            this.eloquentManager.reload(this.orderList[orderIndex]);
                        }
                    }
                    break;
                }
                default: {
                    break;
                }
            }
        });
    };

}
