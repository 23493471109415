import {Component, OnInit} from '@angular/core';
import {WorkOrder} from '../../eloquent/models/work-order.model';
import {EloquentManagerService} from '../../eloquent/providers/eloquent-manager.provider';
import {WorkOrderOperation} from '../../eloquent/models/work-order-operation.model';
import {QualityControlConfigItem} from '../../eloquent/models/quality-control-config.model';

@Component({
  selector: 'app-quality-control-config',
  templateUrl: './quality-control-config.component.html',
  styleUrls: ['./quality-control-config.component.scss']
})
export class QualityControlConfigComponent implements OnInit {


  order: WorkOrder;

  constructor(private eloquenManager: EloquentManagerService) {
  }

  ngOnInit() {
    console.log(`[this.order] `, this.order);
  }

  addQualityConfigItem = (operation: WorkOrderOperation) => {
    operation.quality_control_config.quality_control_config_items.push(new QualityControlConfigItem());
  };


  removeQualityConfigItem = (operation, index) => {
    operation.quality_control_config.quality_control_config_items.splice(index, 1);
  };

  updateOperation = (operation: WorkOrderOperation) => {

    let hasError = false;
    operation.quality_control_config.revalidate();
    hasError = hasError || operation.quality_control_config.form_group.invalid;

    for (let i = 0; i < operation.quality_control_config.quality_control_config_items.length; i++) {
      const item = operation.quality_control_config.quality_control_config_items[i];
      item.revalidate();
      hasError = hasError || item.form_group.invalid;
    }


    if (hasError) {
      console.log('hasError');
      return false;
    }


    this.eloquenManager.update(operation).subscribe();
  };


}
