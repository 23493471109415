import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {QualityControlComponent} from './quality-control.component';
import {SharedImportsModule} from '../../modules/shared-imports.module';

@NgModule({
    imports: [
        SharedImportsModule
    ],
    declarations: [
        QualityControlComponent
    ],
    exports: [
        QualityControlComponent
    ]
})
export class QualityControlModule {
}
