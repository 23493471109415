import {NgModule} from '@angular/core';
import {SharedImportsModule} from '../../modules/shared-imports.module';
import {StartStopLogHistoryComponent} from './start-stop-log-history.component';

@NgModule({
    imports: [
        SharedImportsModule
    ],
    declarations: [
        StartStopLogHistoryComponent
    ],
    exports: [
        StartStopLogHistoryComponent
    ]
})
export class StartStopLogHistoryModule {
}
