import {ChangeDetectorRef, Component, Input, OnInit, Output} from '@angular/core';
import {WorkOrder} from '../../../shared/eloquent/models/work-order.model';
import {WorkOrderOperation} from '../../../shared/eloquent/models/work-order-operation.model';
import {EloquentManagerService} from '../../../shared/eloquent/providers/eloquent-manager.provider';
import {WorkOrderOperationStatus} from '../../../shared/eloquent/models/work-order-operation-status.model';
import {WorkOrderOperationType} from '../../../shared/eloquent/models/work-order-operation-type.model';
import {WorkStation} from '../../../shared/eloquent/models/work-station.model';
import {ValidationErrors} from '@angular/forms';
import {Customer} from '../../../shared/eloquent/models/customer.model';
import {ConfigService} from '../../../shared/services/config/config.service';
import {Supplier} from '../../../shared/eloquent/models/supplier.model';

@Component({
    selector: 'app-work-order-operation',
    templateUrl: './work-order-operation.component.html',
    styleUrls: ['./work-order-operation.component.scss']
})
export class WorkOrderOperationComponent implements OnInit {

    static readonly ADD_OPERATION = 'add-operation';
    static readonly EDIT_OPERATION = 'edit-operation';

    readonly ADD_OPERATION = WorkOrderOperationComponent.ADD_OPERATION;
    readonly EDIT_OPERATION = WorkOrderOperationComponent.EDIT_OPERATION;

    @Input() workOrder: WorkOrder;
    @Input() operationID = null;
    @Input() title = 'Operasyon';
    @Input('action') action = this.ADD_OPERATION;

    operation: WorkOrderOperation = new WorkOrderOperation();

    isPageReady = true;
    private _isOperationLoading = true;
    private _isStatusesLoading = true;
    private _isTypesLoading = true;
    private _isStationsLoading = true;
    statusList: Array<WorkOrderOperationStatus>;
    typeList: Array<WorkOrderOperationType>;
    workStationList: Array<WorkStation>;
    isSupplierChanging: false;

    @Output('close') close = () => {
        return false;
    };

    onOperationSaved = (response) => {
        return;
    };

    constructor(private eloquentManager: EloquentManagerService,
                private configService: ConfigService) {
    }

    ngOnInit() {
        this.getWorkOrderOperationStatuses();
        this.getWorkOrderOperationTypes();
        this.getWorkStations();

        switch (this.action) {
            default:
            case this.ADD_OPERATION: {
                this.setWorkOrderID();
                this.isOperationLoading = false;
                break;
            }
            case this.EDIT_OPERATION: {
                this.isPageReady = false;
                this.eloquentManager.getById(WorkOrderOperation, this.operationID).subscribe((response) => {
                    if (response) {
                        this.operation = response;
                        console.log(this.operation);
                        this.isOperationLoading = false;
                    }
                });
                break;
            }
        }
    }

    private setWorkOrderID = () => {
        this.operation.work_order_id = this.workOrder.id;
        // this.operation.form('work_order_id').setValue(this.workOrder.id);
        // this.operation.form('work_order_id').updateValueAndValidity();
    };

    getWorkOrderOperationStatuses = () => {
        this.eloquentManager.list(WorkOrderOperationStatus).subscribe(response => {
            if (response) {
                this.statusList = response;

                this.isStatusesLoading = false;
            } else {
                /*
                 TODO uyarı
                 */
            }
        });
    };


    getWorkOrderOperationTypes = () => {

        this.eloquentManager.list(WorkOrderOperationType).subscribe(response => {
            if (response) {
                this.typeList = response;
                this.isTypesLoading = false;
            } else {
                /*
                 TODO uyarı
                 */
            }
        });
    };

    getWorkStations = () => {
        this.eloquentManager.list(WorkStation).subscribe(response => {
            if (response) {
                this.workStationList = response;
                this.isStationsLoading = false;
            } else {
                /*
                 TODO uyarı
                 */
            }
        });
    };


    saveOperation = () => {
        console.log(this.operation);
        this.operation.revalidate();


        if (this.operation.form_group.invalid) {

            Object.keys(this.operation.form_group.controls).forEach(key => {

                const controlErrors: ValidationErrors = this.operation.form_group.get(key).errors;
                if (controlErrors != null) {
                    Object.keys(controlErrors).forEach(keyError => {
                        console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                    });
                }
            });


            return false;
        }

        console.log(this.operation);
        if (this.operation.id) {
            this.eloquentManager.update(this.operation).subscribe(response => {
                if (response) {
                    this.onOperationSaved(response);
                    this.isPageReady = true;
                    this.close();
                }
            });
        } else {
            this.eloquentManager.save(this.operation).subscribe(response => {
                if (response) {

                    this.workOrder.addOperation(this.operation);
                    this.onOperationSaved(response);
                    this.isPageReady = true;
                    this.close();
                }
            });
        }
    };

    changeSupplier = (value) => {
        this.isSupplierChanging = value;
    };

    searchSuppliers = (query) => {
        return new Promise((resolve, reject) => {
            this.eloquentManager.request('POST', this.configService.get('apiBaseUrl') + 'suppliers/get-all-for-autocomplete', {keyword: query})
                .subscribe((response: any) => {
                    if (response) {
                        const modelList = [];
                        const responseArray = response;
                        for (let i = 0; i < responseArray.length; i++) {
                            modelList.push(new Customer().deserialize(responseArray[i]));
                        }
                        resolve(modelList);
                    }
                    resolve([]);
                });
        });
    };


    setSupplier = (supplier: Supplier) => {
        this.operation.supplier = supplier;
        this.operation.form('supplier').setValue(supplier);
        this.operation.form('supplier').updateValueAndValidity();
        this.changeSupplier(false);
    };

    isInternalOptionFormatter = (option: any, query?: string): string => {
        return option === true ? 'İç Operasyon' : 'Dış Operasyon';
    };

    setIsOperationLoading() {

        if (!this._isOperationLoading) {
            if (!this._isStationsLoading) {
                if (this.operation.id) {
                    this.operation.work_order_operation_status = this.statusList.filter((item) => {
                        return item.id === this.operation.work_order_operation_status.id;
                    })[0];
                }
            }

            if (!this._isTypesLoading) {
                if (this.operation.id) {
                    this.operation.work_order_operation_type = this.typeList.filter((item) => {
                        return item.id === this.operation.work_order_operation_type.id;
                    })[0];
                }
            }

            if (!this._isStationsLoading) {
                if (this.operation.id) {
                    this.operation.work_station = this.workStationList.filter((item) => {
                        return item.id === this.operation.work_station.id;
                    })[0];
                    if (this.operation.user.id) {
                        const user = this.operation.work_station.users.filter((item) => {
                            return item.id === this.operation.user.id;
                        });

                        if (user.length > 0) {
                            this.operation.user = user[0];
                        }
                    }
                }
            }
        }


        this.isPageReady = this._isTypesLoading || this._isStationsLoading || this._isStationsLoading || this._isOperationLoading;
        console.log(this.isPageReady);
    }


    set isOperationLoading(value: boolean) {
        this._isOperationLoading = value;
        this.setIsOperationLoading();
    }

    set isStatusesLoading(value: boolean) {
        this._isStatusesLoading = value;
        this.setIsOperationLoading();
    }

    set isTypesLoading(value: boolean) {
        this._isTypesLoading = value;
        this.setIsOperationLoading();
    }

    set isStationsLoading(value: boolean) {
        this._isStationsLoading = value;
        this.setIsOperationLoading();
    }
}
