import {NgModule} from '@angular/core';
import {SharedImportsModule} from '../../modules/shared-imports.module';
import {PurchaseOrderComponent} from './purchase-order.component';

@NgModule({
    imports: [
        SharedImportsModule

    ],
    declarations: [
        PurchaseOrderComponent
    ],
    exports: [
        PurchaseOrderComponent
    ]
})
export class PurchaseOrderModule {
}
