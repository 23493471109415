import {NgModule} from '@angular/core';
import {DynamicComponentComponent} from './dynamic-component.component';
import {SharedImportsModule} from '../../modules/shared-imports.module';
import {UploaderComponent} from '../uploader/uploader.component';
import {QualityControlComponent} from '../quality-control/quality-control.component';
import {LogHistoryComponent} from '../log-history/log-history.component';
import {ShipmentHistoryComponent} from '../shipment-history/shipment-history.component';
import {ConfirmationComponent} from '../confirmation/confirmation.component';
import {QualityControlConfigComponent} from '../quality-control-config/quality-control-config.component';
import {StartStopLogHistoryComponent} from '../start-stop-log-history/start-stop-log-history.component';

@NgModule({
    imports: [
        SharedImportsModule
    ],
    declarations: [
        DynamicComponentComponent
    ],
    exports: [
        DynamicComponentComponent
    ],
    // entryComponents: [
    //     UploaderComponent,
    //     QualityControlComponent,
    //     LogHistoryComponent,
    //     ShipmentHistoryComponent,
    //     ConfirmationComponent,
    //     QualityControlConfigComponent,
    //     StartStopLogHistoryComponent
    // ]
})
export class DynamicComponentModule {
}
