import {Component, OnInit} from '@angular/core';
import {WorkOrderOperation} from '../../eloquent/models/work-order-operation.model';
import {EloquentManagerService} from '../../eloquent/providers/eloquent-manager.provider';
import {ConfigService} from '../../services/config/config.service';
import {Log} from '../../eloquent/models/log.model';
import {QualityControl} from '../../eloquent/models/quality-control.model';
import {QualityControlGroup} from '../../eloquent/models/quality-control-group.model';
import {ToastService} from '../../services/toast/toast.service';

@Component({
    selector: 'app-quality-control',
    templateUrl: './quality-control.component.html',
    styleUrls: ['./quality-control.component.scss']
})
export class QualityControlComponent implements OnInit {

    title = 'Kalite Kontrol';
    operation: WorkOrderOperation;

    isQualityControlsLoaded = false;

    newQualityControlGroup: QualityControlGroup = new QualityControlGroup();


    measurementInstrumentsList = [];

    constructor(private eloquentManager: EloquentManagerService,
                private configService: ConfigService,
                private toastService: ToastService) {

        for (let i = 1; i <= 40; i++) {
            this.measurementInstrumentsList.push('M' + i);
        }
    }

    ngOnInit() {
        this.title = `Kalite Kontrol Ekle: #${this.operation.id}`;
        this.configureGroups();
        this.loadQualityControls();


    }

    configureGroups = () => {
        if (this.operation.quality_control_config.quality_control_config_items.length > 0) {
            this.newQualityControlGroup.quality_controls = [];
            this.newQualityControlGroup.work_order_operation_id = this.operation.id;
            for (const qc of this.operation.quality_control_config.quality_control_config_items) {

                const newControl = new QualityControl();
                newControl.section = qc.section;
                newControl.dimension = qc.dimension;
                newControl.tolerance = qc.tolerance;
                newControl.tolerance_upper = qc.tolerance_upper;
                newControl.tolerance_lower = qc.tolerance_lower;

                this.newQualityControlGroup.quality_controls.push(newControl);
            }

        }
    };


    loadQualityControls = () => {
        this.eloquentManager.request('GET', this.configService.get('apiBaseUrl') + this.operation.getApiPrefix() + '/' + this.operation.id + '/get-quality-controls').subscribe((response) => {
            if (response) {
                console.log(response);
                this.operation.deserializeQualityControlGroups(response);
                console.log(this.operation);
                this.isQualityControlsLoaded = true;

            }
        });
    };

    saveQualityControlGroup = () => {

        let hasError = false;

        for (let i = 0; i < this.newQualityControlGroup.quality_controls.length; i++) {
            const item = this.newQualityControlGroup.quality_controls[i];
            item.revalidate();
            hasError = hasError || item.form_group.invalid;
        }


        if (hasError) {
            console.log('hasError');
            return false;
        }

        const savingToast = this.toastService.saving();

        this.eloquentManager.request('POST', this.configService.get('apiBaseUrl') + this.operation.getApiPrefix() + '/' + this.operation.id + '/add-quality-control', this.newQualityControlGroup).subscribe((response) => {
            if (response) {
                this.toastService.saved(savingToast);
                this.configureGroups();
                this.loadQualityControls();
                return;
            }

            this.toastService.saveFailed(savingToast);

        });
    };
}
