import {Component, OnDestroy, OnInit} from '@angular/core';
import {EloquentManagerService} from '../../../shared/eloquent/providers/eloquent-manager.provider';
import {LayoutService} from '../../../shared/services/layout/layout.service';
import {ConfigService} from '../../../shared/services/config/config.service';
import {User} from '../../../shared/eloquent/models/user.model';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '../../../shared/services/authentication/authentication.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HolosenValidators} from '../../../shared/eloquent/validators/validators';
import {AuthHttpService} from '../../../shared/services/http/auth-http.service';
import {ToastService} from '../../../shared/services/toast/toast.service';
import {Router} from '@angular/router';

declare const $: any;

@Component({
    selector: 'app-me',
    templateUrl: './me.component.html',
    styleUrls: ['./me.component.scss']
})
export class MeComponent implements OnInit, OnDestroy {

    pageTitle = 'Hesabım';

    user: User;
    private userSubscripton: Subscription;

    passwordFormData = {
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: ''
    };

    passwordForm = new FormGroup(
        {
            oldPassword: new FormControl(
                this.passwordFormData.oldPassword,
                Validators.compose([
                    Validators.required
                ])
            ),
            newPassword: new FormControl(
                this.passwordFormData.newPassword,
                Validators.compose([
                    HolosenValidators.passwordStrength
                ])
            ),
            newPasswordConfirm: new FormControl(
                this.passwordFormData.newPasswordConfirm,
                Validators.compose([
                    HolosenValidators.confirmPassword
                ])
            ),
        }
    );

    constructor(private eloquentManager: EloquentManagerService,
                private layoutService: LayoutService,
                private configService: ConfigService,
                private authenticationService: AuthenticationService,
                private authHtp: AuthHttpService,
                private toastService: ToastService,
                private router: Router) {
        this.layoutService.setTitle(this.pageTitle);

    }

    ngOnInit() {
        this.subscribeUser();
    }


    ngOnDestroy(): void {
        this.unsubscribeUser();
    }

    private subscribeUser() {
        this.userSubscripton = this.authenticationService.user.subscribe((user) => {
            this.user = user;
            console.log(this.user);
            if (this.user.is_first_login) {
                $('#firstLoginModal').modal({
                    closable: true,
                    observeChanges: true,
                }).modal('show');
            }
        });
    }

    private unsubscribeUser() {
        this.userSubscripton.unsubscribe();
    }


    changePassword = (e) => {
        e.preventDefault();
        e.stopPropagation();

        Object.keys(this.passwordForm.controls).forEach(key => {
            this.passwordForm.get(key).markAsTouched();
            this.passwordForm.get(key).updateValueAndValidity();
        });

        if (this.passwordForm.invalid) {
            return false;
        }

        const processingToast = this.toastService.processing();

        this.authHtp.authPut(this.configService.get('apiBaseUrl') + 'me/change-password', this.passwordFormData).subscribe((response) => {
            if (response.ok) {
                this.toastService.success('Başarılı', 'Şifre başarıyla değiştirildi. Yeniden giriş yapmanız gerekiyor', processingToast);
                this.router.navigate(['/login']);
            } else {
                this.toastService.error('Hata Oluştu', response.message, processingToast);
            }
        });
    };

}
