import {Injectable} from '@angular/core';
import {StorageService} from '../storage/storage.service';
import {HttpClient, HttpRequest, HttpHeaders} from '@angular/common/http';
import {MrpResponse} from '../../eloquent/interfaces/interfaces';


@Injectable()
export class AuthHttpService {

    constructor(private http: HttpClient,
                private storage: StorageService) {
    }

    public authPost = (URL: string, data?: any, options?: any) => {
        return this.makeApiRequest(URL, data, 'POST', options);
    };

    public authGet = (URL, data?, options?: any) => {
        return this.makeApiRequest(URL, data, 'GET', options);
    };

    public authPut = (URL, data?, options?: any) => {
        return this.makeApiRequest(URL, data, 'PUT', options);
    };

    public authDelete = (URL, data?, options?: any) => {
        return this.makeApiRequest(URL, data, 'DELETE', options);
    };

    public attach = (URL, data) => {

        const headers = new HttpHeaders();
        headers.append('enctype', 'multipart/form-data');

        const httpRequest = new HttpRequest('POST', URL, data, {
            reportProgress: true,
            headers: headers
        });

        return this.http.request(httpRequest);
    };

    makeApiRequest(URL: string, data: any, method: string, options?: any) {


        let httpResponse;

        switch (method) {
            case 'POST': {
                httpResponse = this.http.post<MrpResponse>(URL, data, options);
                break;
            }
            case 'GET': {
                httpResponse = this.http.get<MrpResponse>(URL, options);
                break;
            }
            case 'PUT': {
                httpResponse = this.http.put<MrpResponse>(URL, data, options);
                break;
            }
            case 'DELETE': {
                httpResponse = this.http.delete<MrpResponse>(URL, options);
                break;
            }
            default: {
                httpResponse = this.http.get<MrpResponse>(URL, options);
                break;
            }
        }

        return httpResponse;
    }


}
