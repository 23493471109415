import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../shared/services/authentication/authentication.service';
import {ConfigService} from '../../../shared/services/config/config.service';
import {ToastService} from '../../../shared/services/toast/toast.service';


@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

    username: string;
    password: string;

    constructor(private router: Router,
                private authenticationService: AuthenticationService,
                private configService: ConfigService,
                private toastService: ToastService) {
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
    }


    login(event) {
        event.preventDefault();
        const loggingToast = this.toastService.info('Giriş Yapılıyor', 'Lütfen Bekleyiniz');
        this.authenticationService.login(this.username, this.password)
            .then(
                response => {
                    console.log(response);
                    switch (response) {
                        case  AuthenticationService.RESPONSE.LOGIN_SUCCESSFUL: {
                            this.toastService.success('Giriş Başarılı', 'Yönlendiriliyorsunuz', loggingToast);
                            if (this.authenticationService.userHasRole([AuthenticationService.ROLES.OPERATOR])) {
                                this.router.navigate(['/operator']);
                            } else {
                                this.router.navigate(['/planning']);
                            }
                            break;
                        }
                        default:
                        case  AuthenticationService.RESPONSE.INVALID_CREDENTIALS: {
                            this.toastService.error('Giriş Başarısız', 'Lütfen Kullanıcı Adı ve Şifrenizi Kontrol Edip Tekrar Deneyin', loggingToast);
                            break;
                        }
                    }


                },
                (error) => {
                    console.log(error);
                    this.toastService.error('Giriş Başarısız', 'Lütfen Kullanıcı Adı ve Şifrenizi Kontrol Edip Tekrar Deneyin', loggingToast);this.toastService.error('Giriş Başarısız', 'Lütfen Kullanıcı Adı ve Şifrenizi Kontrol Edip Tekrar Deneyin', loggingToast);
                    return;
                });
    }

    onLoggedIn() {

    }


}




