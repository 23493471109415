
import {timer as observableTimer, Observable, Subscription} from 'rxjs';
import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {ToastService} from './toast.service';
import {Toast, ToastType} from './toast.model';

@Component({
    selector: 'app-toast',
    templateUrl: 'toast.component.html',
    styleUrls: ['toast.component.scss'],
    animations: [
        trigger('toastState', [
            state('void', style({
                right: '-50px',
                opacity: 0.0
            })),
            state('active', style({
                right: '0px',
                opacity: 1.0

            })),
            state('exit', style({
                right: '50px',
                opacity: 0.0

            })),
            transition('active => exit', animate('200ms ease-out')),
            transition('void => active', animate('200ms ease-in'))
        ])
    ]
})
export class ToastComponent implements OnInit, OnDestroy {

    @Input('toast')
    public toast: Toast;

    public state: string;
    styleClass = '';
    styleIcon = '';

    private timer: Observable<any>;
    private timerSubscrption: Subscription;
    private lifetime = 5000;

    constructor(private toastService: ToastService) {
    }

    ngOnInit() {
        this.setStyleClass();
        this.state = 'active';
        this.startCountdown();
    }


    ngOnDestroy(): void {
        this.timerSubscrption.unsubscribe();
    }

    private startCountdown(){
        this.timer = observableTimer(5000);
        this.timerSubscrption = this.timer.subscribe(response => {
            this.close();
        });
    }

    public close() {
        this.state = 'exit';
    }

    public onAnimationEnd(e: any) {
        if (e.toState === 'exit') {
            this.toastService.deleteToast(this.toast);
        }
    }

    private setStyleClass() {
        let styleClass = '';
        let styleIcon = '';
        switch (this.toast.type) {
            case ToastType.SUCCESS: {
                styleClass = 'success';
                styleIcon = 'checkmark';
                break;
            }
            case ToastType.ERROR: {
                styleClass = 'error';
                styleIcon = 'remove';
                break;
            }
            case ToastType.WARN: {
                styleClass = 'warn';
                styleIcon = 'warning';
                break;
            }
            case ToastType.INFO: {
                styleClass = 'info';
                styleIcon = 'info circle';
                break;
            }
            default: {
                break;
            }
        }

        this.styleClass = styleClass;
        this.styleIcon = styleIcon;
    }
}
