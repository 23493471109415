import {Component, ElementRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {WorkOrderOperation} from '../../eloquent/models/work-order-operation.model';
import {EloquentManagerService} from '../../eloquent/providers/eloquent-manager.provider';
import {ConfigService} from '../../services/config/config.service';
import {Shipment} from '../../eloquent/models/shipment.model';
import {Log} from '../../eloquent/models/log.model';
import {ValidationErrors} from '@angular/forms';
import {ToastService} from '../../services/toast/toast.service';
import {WorkOrder} from '../../eloquent/models/work-order.model';

declare const $: any;


@Component({
  selector: 'app-shipment-history',
  templateUrl: './shipment-history.component.html',
  styleUrls: ['./shipment-history.component.scss']
})
export class ShipmentHistoryComponent implements OnInit {

  static readonly LIST_SHIPMENT_HISTORY = 'list-shipment-history';
  static readonly ADD_SHIPMENT_HISTORY = 'add-shipment-history';

  readonly LIST_SHIPMENT_HISTORY = ShipmentHistoryComponent.LIST_SHIPMENT_HISTORY;
  readonly ADD_SHIPMENT_HISTORY = ShipmentHistoryComponent.ADD_SHIPMENT_HISTORY;

  @Input('shippable') shippable: any;
  @Input('title') title = 'Sevkiyat Kayıtları';
  @Input('action') action = this.LIST_SHIPMENT_HISTORY;
  @Input('workOrder') workOrder: WorkOrder;


  shipmentList: Array<Shipment>;
  newShipment: Shipment;
  isShipmentsLoading = true;


  @ViewChild('deleteShipmentConfirmModal', {static: false})
  deleteShipmentConfirmModal: ElementRef;

  @Output('close') close = () => {
    return false;
  };

  @Output('onShipmentSaved') onShipmentSaved = (): any => {
    return;
  };

  constructor(private eloquentManager: EloquentManagerService,
              private configService: ConfigService,
              private toastService: ToastService) {
  }

  ngOnInit() {
    console.log(`[this.action]`, this.action);
    switch (this.action) {
      case this.ADD_SHIPMENT_HISTORY: {
        this.isShipmentsLoading = false;
        this.newShipment = new Shipment();
        this.newShipment.unit = this.shippable.unit;
        this.newShipment.setUnitDisplay();

        break;
      }
      default:
      case this.LIST_SHIPMENT_HISTORY: {
        this.isShipmentsLoading = true;
        this.loadShipments();
        break;
      }

    }

  }


  loadShipments = () => {
    this.eloquentManager.request('GET', this.configService.get('apiBaseUrl') + this.shippable.getApiPrefix() + '/' + this.shippable.id + '/get-shipments').subscribe((response) => {
      if (response) {
        const modelList = [];
        const responseArray = response;
        for (let i = 0; i < responseArray.length; i++) {
          modelList.push(new Shipment().deserialize(responseArray[i]));
        }
        this.shipmentList = modelList;
      }

      this.isShipmentsLoading = false;
    });
  };


  saveShipment = () => {
    this.newShipment.revalidate();


    if (this.newShipment.form_group.invalid) {

      Object.keys(this.newShipment.form_group.controls).forEach(key => {

        const controlErrors: ValidationErrors = this.newShipment.form_group.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
        }
      });


      return false;
    }

    const savingToast = this.toastService.saving();

    this.eloquentManager.request('POST', `${this.configService.get('apiBaseUrl') + this.shippable.getApiPrefix()}/${this.shippable.id}/add-shipment`, this.newShipment).subscribe(response => {
      if (response) {
        this.toastService.saved(savingToast);
        // this.eloquentManager.recalculateCompletion(this.shippable);
        this.onShipmentSaved();
        this.close();
      }
    });
  };


  deleteShipment = (shipment: Shipment) => {
    $(this.deleteShipmentConfirmModal.nativeElement)
      .modal({
        closable: false,
        onDeny: () => {
          return true;
        },
        onApprove: () => {
          this.eloquentManager.delete(shipment).subscribe(response => {
            if (response) {
              this.loadShipments();
            } else {
              // this.notificationService.error('Hata Oluştu', `${attachment.filename} dosyası silinirken bir hata oluştu`);
            }
          });
        }
      })
      .modal('show');

  };

}
