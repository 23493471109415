import {Log} from './log.model';
import {notEmpty} from '../utils/utils';

export class Loggable {

    public logs: Array<Log> = [];


    public add_log(log: Log) {
        this.logs.push(log);
    }


    public deserializeLogs(input: any) {
        if (notEmpty(input['logs'])) {
            for (let i = 0; i < input['logs'].length; i++) {
                this.logs.push(new Log().deserialize(input['logs'][i]));
            }
        }
    }


}
