import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedImportsModule} from '../../../shared/modules/shared-imports.module';
import {WorkOrderOperationComponent} from './work-order-operation.component';
import {WorkOrderOperationService} from './work-order-operation.service';

@NgModule({
    imports: [
        SharedImportsModule
    ],
    declarations: [
        WorkOrderOperationComponent
    ],
    exports: [
        WorkOrderOperationComponent
    ],
    providers: [
        WorkOrderOperationService
    ]
})
export class WorkOrderOperationModule {
}
