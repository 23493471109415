import {EloquentSerializable} from '../interfaces/interfaces';


export class PurchaseOrderType implements EloquentSerializable<PurchaseOrderType> {
    public type;
    public name;

    deserialize(input: JSON): PurchaseOrderType {

        this.type = input['type'];
        this.name = input['name'];

        return this;
    }
}