import {BaseModel} from './base.model';
import {EloquentSerializable} from '../interfaces/interfaces';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {HolosenValidators} from '../validators/validators';

export class Shipment extends BaseModel implements EloquentSerializable<Shipment> {


    public static readonly API_PREFIX: string = 'shipments';


    public id: number = null;
    public created_at: Date = new Date();
    public is_incoming = false;
    public dispatch_number: string = null;
    public completed_quantity: number = null;
    public wasted_quantity: number = null;
    public unit = 'pcs';
    public additional_notes = '';
    public shipping_date: Date = new Date();
    public shipped_by: string = null;
    public unit_display = 'Adet';
    public quality_checked = false;

    form_group = new FormGroup({
        dispatch_number: new FormControl(
            this.dispatch_number,
            Validators.compose([
                HolosenValidators.requiredIf('is_incoming', false, Validators.required)
            ])
        ),
        is_incoming: new FormControl(
            this.is_incoming,
            Validators.compose([])
        ),
        completed_quantity: new FormControl(
            this.completed_quantity,
            Validators.compose([
                HolosenValidators.requiredNumber
            ])
        ),
        wasted_quantity: new FormControl(
            this.wasted_quantity,
            Validators.compose([
                HolosenValidators.requiredNumber,
            ])
        ),
        additional_notes: new FormControl(
            this.additional_notes,
            Validators.compose([
                HolosenValidators.requiredIfNumberGreaterThan('wasted_quantity', 0, Validators.required)
            ])
        ),
        shipping_date: new FormControl(
            this.shipping_date,
            Validators.compose([
                Validators.required
            ])
        ),
        quality_checked: new FormControl(
            this.quality_checked,
            Validators.compose([
                HolosenValidators.requiredTrue
            ])
        )
    });


    public getApiPrefix(): string {
        return Shipment.API_PREFIX;
    }

    public setUnitDisplay() {
        this.unit_display = this.unit === 'pcs' ? 'Adet' : (this.unit === 'kg' ? 'kg' : 'Birim');
    }

    deserialize(input: JSON): Shipment {
        this.id = input['id'];
        this.created_at = input['created_at'];
        this.dispatch_number = input['dispatch_number'];
        this.is_incoming = input['is_incoming'];
        this.completed_quantity = input['completed_quantity'];
        this.wasted_quantity = input['wasted_quantity'];
        this.unit = input['unit'];
        this.setUnitDisplay();
        this.additional_notes = input['additional_notes'];
        this.shipping_date = input['shipping_date'];
        this.shipped_by = input['shipped_by'];
        this.quality_checked = input['quality_checked'];
        return this;
    }


}
