import {NotFoundComponent} from './not-found.component';


export const NotFoundRoutes = [
    {
        path: '404',
        component: NotFoundComponent
    },
    {
        path: '**',
        redirectTo: '/404'
    },
];
