import {Injectable} from '@angular/core';

@Injectable()
export class ConfigService {

    private baseUrl = 'http://api.planbu.bo-tek.com.tr/';

    private config: Object = {
        env: 'dev',
        baseUrl: this.baseUrl,
        apiBaseUrl: this.baseUrl + 'api/',
        loginUrl: this.baseUrl + 'oauth/token',
        clientId: 1,
        clientSecret: 'DQ7T482j5NwkzTTcXgZuvSyYtcwzMHzqXF748F2S'
    };

    constructor() {}

    public get(key: string) {
        return this.config[key];
    }
}
