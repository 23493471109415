import {NgModule} from '@angular/core';
import {AuthenticatedModule} from './authenticated/authenticated.module';
import {SharedModule} from '../shared/modules/shared.module';
import {PublicModule} from './public/public.module';
import {BaseComponent} from './base.component';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        BaseComponent
    ],
    exports: [
        AuthenticatedModule,
        PublicModule
    ]
})
export class ComponentsModule {
}
