<div class="log-history-container">
    <div class="ui large header dividing margin-bottom-30 padding-left-30 padding-right-30">
        {{title}}
    </div>

    <fui-dimmer class="inverted" [(isDimmed)]="isLogsLoading" [isClickable]="false">
        <div class="ui text loader">Yükleniyor</div>
    </fui-dimmer>


    <div *ngIf="logList && logList.length == 0">
        <div class="ui grid centered margin-top-75">
            <h2 class="ui icon header grey">
                <i class="warning circle icon"></i>
                <div class="content">
                    Kayıt Bulunamadı
                </div>
            </h2>
        </div>
    </div>
    <div *ngIf="logList && logList.length > 0">
        <div class="ui small feed">
            <div class="event" *ngFor="let log of logList">
                <div class="label">
                    <img src="./assets/images/joe.jpg">
                </div>
                <div class="content">
                    <div class="summary">
                        <a class="user">
                            {{log.logged_by_display_name}}
                        </a> tarafından eklendi
                    </div>
                    <div class="extra text">
                        <table class="ui table">
                            <tr>
                                <td>Başlangıç</td>
                                <td>{{log.started_at | date: 'dd.MM.yyyy HH:mm'}}</td>
                            </tr>
                            <tr>
                                <td>Bitiş</td>
                                <td>{{log.ended_at | date: 'dd.MM.yyyy HH:mm'}}</td>
                            </tr>
                            <tr>
                                <td>Durma Nedeni</td>
                                <td>{{log.end_reason}}</td>
                            </tr>
                            <tr [ngClass]="{'negative': log.has_overtime}">
                                <td>Fazla Mesai</td>
                                <td>{{log.has_overtime ? 'Var' : 'Yok'}}</td>
                            </tr>

                        </table>
                    </div>
                    <div class="extra text" *ngIf="log.log.additional_notes">
                        <div class="ui icon message">
                            <i class="envelope icon"></i>
                            <div class="content">
                                <p>{{log.log.additional_notes}}</p>
                            </div>
                        </div>

                    </div>
                    <div class="extra">
                        <div class="ui tiny statistics">
                            <div class="green statistic">
                                <div class="value">
                                    {{log.log.completed_num_of_parts}}
                                </div>
                                <div class="label">
                                    Tamamlandı
                                </div>
                            </div>
                            <div class="red mini statistic">
                                <div class="value">
                                    {{log.log.wasted_num_of_parts}}
                                </div>
                                <div class="label">
                                    Fire Verildi
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
