import {EloquentSerializable} from '../interfaces/interfaces';
import {BaseModel} from './base.model';
import {PurchaseOrderType} from './purchase-order-type.model';
import {RequiredDocuments} from './required-documents.model';
import {Personnel} from './personnel.model';
import {Uploadable} from './uploadable.model';
import {Attachment} from './attachment.model';
import {Mixin, notEmpty} from '../utils/utils';
import {Attachable} from './attachable.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HolosenValidators} from '../validators/validators';
import {Supplier} from './supplier.model';
import {MaterialShape} from './material-shape.model';
import {Shippable} from './shippable.model';
import {Shipment} from './shipment.model';
import {WorkOrderOperation} from './work-order-operation.model';


@Mixin([Attachable, Shippable])
export class PurchaseOrder extends BaseModel implements EloquentSerializable<PurchaseOrder>, Shippable {


    public static readonly API_PREFIX: string = 'purchase-orders';

    public static readonly TYPE_MATERIAL = {
        name: 'Hammadde',
        value: 'material'
    };

    public static readonly TYPE_SERVICE = {
        name: 'Hizmet',
        value: 'service',
    };

    public static readonly TYPE_CONSUMABLE = {
        name: 'Sarf Malzemesi',
        value: 'consumable',
    };

    public static readonly TYPE_FIXTURE = {
        name: 'Demirbaş',
        value: 'fixture'
    };

    public static readonly PURCHASE_ORDER_TYPES = [
        PurchaseOrder.TYPE_MATERIAL,
        PurchaseOrder.TYPE_SERVICE,
        PurchaseOrder.TYPE_CONSUMABLE,
        PurchaseOrder.TYPE_FIXTURE,
    ];


    get purchaseOrderTypes(): { name: string; value: string }[] {
        return PurchaseOrder.PURCHASE_ORDER_TYPES;
    }

    public id: number = null;
    public created_at: Date = new Date();
    public shipment_deadline: Date = new Date();
    public deadline: Date = new Date();
    public material = '';
    public purchase_order_type = '';
    public type_list: Array<PurchaseOrderType> = [];
    public description = '';
    public shipment_by = true;
    public shipment_paid_by = true;
    public unit = 'pcs';
    public quantity = null;
    public unit_price = null;
    public shipment_price = null;
    public required_documents: RequiredDocuments = new RequiredDocuments();
    public material_shape = new MaterialShape();
    public supplier: Supplier = new Supplier();
    public unit_display = 'Adet';
    public product_service_name = '';
    public quality_checked = false;
    public work_order_operation: WorkOrderOperation = null;


    /*
     ATTACHABLE
     */
    public attachments: Array<Attachment> = [];
    attach: (attachment: Attachment) => void;
    detach: (attachment: Attachment) => void;
    uploadableToAttachment: (uploadable: Uploadable) => void;
    deserializeAttachments: (input: any) => void;


    /*
     SHIPPABLE
     */


    shippable_status;

    getShippableStatusName: () => void;
    shippable_status_name;
    incoming_shipments: Array<Shipment> = [];
    outgoing_shipments: Array<Shipment> = [];
    incoming_percentage = 0;
    outgoing_percentage = 0;
    total_incoming_completed_quantity = 0;
    total_incoming_wasted_quantity = 0;
    total_outgoing_completed_quantity = 0;
    total_outgoing_wasted_quantity = 0;
    addIncomingShipment: (shipment: Shipment) => void;
    addOutgoingShipment: (shipment: Shipment) => void;
    deserializeShippableAttributes: (input: any) => void;
    deserializeIncomingShipments: (input: any) => void;
    deserializeOutgoingShipments: (input: any) => void;

    form_group = new FormGroup({
        // product_service_name: new FormControl(
        //     this.product_service_name,
        //     Validators.compose([
        //         Validators.required
        //     ])
        // ),
        shipment_deadline: new FormControl(
            this.shipment_deadline,
            Validators.compose([
                Validators.required
            ])
        ),
        supplier: new FormControl(
            this.supplier,
            Validators.compose([
                HolosenValidators.requiredModel
            ])
        ),
        deadline: new FormControl(
            this.deadline,
            Validators.compose([
                Validators.required
            ])
        ),
        material: new FormControl(
            this.material,
            Validators.compose([
                Validators.required,
            ])
        ),
        purchase_order_type: new FormControl(
            this.purchase_order_type,
            Validators.compose([
                Validators.required
            ])
        ),
        description: new FormControl(
            this.description,
            Validators.compose([
                Validators.required,
            ])
        ),
        unit: new FormControl(
            this.unit,
            Validators.compose([
                Validators.required
            ])
        ),
        quantity: new FormControl(
            this.quantity,
            Validators.compose([
                HolosenValidators.requiredNumber
            ])
        ),
        unit_price: new FormControl(
            this.unit_price,
            Validators.compose([
                HolosenValidators.requiredNumber
            ])
        ),
        shipment_price: new FormControl(
            this.shipment_price,
            Validators.compose([
                HolosenValidators.requiredNumber
            ])
        ),
        material_shape: new FormControl(
            this.material_shape,
            Validators.compose([
                // HolosenValidators.requiredIf('type', 'material', Validators.required)
            ])
        )
    });


    public getApiPrefix(): string {
        return PurchaseOrder.API_PREFIX;
    }

    public setUnitDisplay() {
        this.unit_display = this.unit === 'pcs' ? 'Adet' : (this.unit === 'kg' ? 'kg' : 'Birim');
    }

    deserialize(input: JSON): PurchaseOrder {
        this.id = input['id'];
        // this.product_service_name = input['product_service_name'];
        this.shipment_deadline = new Date(input['shipment_deadline']);
        this.deadline = new Date(input['deadline']);
        this.material = input['material'];
        this.purchase_order_type = input['purchase_order_type'];
        this.description = input['description'];
        this.shipment_by = input['shipment_by'];
        this.shipment_paid_by = input['shipment_paid_by'];
        this.unit = input['unit'];
        this.setUnitDisplay();
        this.quantity = input['quantity'];
        this.unit_price = input['unit_price'];
        this.shipment_price = input['shipment_price'];
        this.quality_checked = input['quality_checked'];

        if (notEmpty(input['material_shape'])) {
            this.material_shape = new MaterialShape().deserialize(input['material_shape']);
        }

        this.required_documents = new RequiredDocuments().deserialize(input['required_documents']);

        if (notEmpty(input['supplier'])) {
            this.supplier = this.supplier.deserialize(input['supplier']);
        }


        if (notEmpty(input['work_order_operation'])) {
            this.work_order_operation = new WorkOrderOperation().deserialize(input['work_order_operation']);
        }

        if (notEmpty(input['type_list'])) {
            for (let i = 0; i < input['type_list'].length; i++) {
                this.type_list.push(new PurchaseOrderType().deserialize(input['type_list'][i]));
            }
        }

        this.deserializeShippableAttributes(input);
        return this;
    }
}
