export * from './classes/active-modal';
export * from './classes/modal-config';
export * from './classes/modal-controls';
export * from './classes/modal-template';

export * from './components/modal';

export * from './services/modal.service';

export * from './modal.module';
