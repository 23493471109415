import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/modules/shared.module';
import {LoginModule} from './login/login.module';
import {LogoutModule} from './logout/logout.module';
import {NotFoundModule} from './not-found/not-found.module';
import {PublicWrapperModule} from './public-wrapper/public-wrapper.module';

@NgModule({
    imports: [
        SharedModule
    ],
    exports: [
        PublicWrapperModule,
        LoginModule,
        LogoutModule,
        NotFoundModule
    ],
})
export class PublicModule {
}
