<div class="ui grid">
    <div class="four wide column">
        <div class="ui dropdown fluid" fuiDropdown>
            <div class="text fluid">
                <div class="mini ui button basic fluid">
                    {{pagination.pageSize}}
                </div>
                <div class="pagination-label" style="text-align: center">
                    öğe
                </div>
            </div>
            <div class="menu" fuiDropdownMenu>
                <div class="item" *ngFor="let pageSize of pagination.pageSizeOptions" (click)="setPageSize(pageSize)">
                    {{pageSize}}
                </div>
            </div>
        </div>
    </div>
    <div class="six wide column">
        <div class="pagination-index">
            {{calculateIndex()}}
        </div>
    </div>
    <div class="three wide column">
        <div class="pagination-arrow" (click)="previousPage()" [ngClass]="{'disabled': isOnFirstPage()}">
            <i class="icon chevron left"></i>
        </div>
    </div>
    <div class="three wide column">
        <div class="pagination-arrow" (click)="nextPage()" [ngClass]="{'disabled': _isOnLastPage}">
            <i class="icon chevron right"></i>
        </div>
    </div>
</div>
