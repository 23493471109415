import {Directive, TemplateRef, Input, OnInit, OnDestroy, EmbeddedViewRef, ViewContainerRef} from '@angular/core';


@Directive({
    selector: '[appHolosenTemplate]'
})
export class HolosenTemplateDirective {

    @Input('appHolosenTemplate') appHolosenTemplate: string;

    constructor(public template: TemplateRef<any>) {
    }

    get type(){
        return this.appHolosenTemplate;
    }
}


@Directive({
    selector: '[appHolosenTemplateWrapper]'
})
export class HolosenTemplateWrapperDirective implements OnInit, OnDestroy {

    @Input() index: number;

    @Input('appHolosenTemplateWrapper') appHolosenTemplateWrapper: TemplateRef<any>;

    view: EmbeddedViewRef<any>;

    _item: any;

    constructor(public viewContainer: ViewContainerRef) {}

    ngOnInit() {
        this.render();
    }

    set item(item: any) {
        this._item = item;

        if (this.view) {
            this.view.destroy();
            this.render();
        }
    }

    @Input() get item(): any {
        return this._item;
    }

    render() {
        this.view = this.viewContainer.createEmbeddedView(this.appHolosenTemplateWrapper, {
            '\$implicit': this.item,
            'index': this.index
        });
    }

    ngOnDestroy() {
        this.view.destroy();
    }
}
