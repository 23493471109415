import {BaseModel} from './base.model';
import {EloquentSerializable} from '../interfaces/interfaces';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {HolosenValidators} from '../validators/validators';
import {Log} from './log.model';
import {WorkOrderOperation} from './work-order-operation.model';
import {User} from './user.model';
import {notEmpty} from '../utils/utils';

export class StartStopLog extends BaseModel implements EloquentSerializable<StartStopLog> {

    public static readonly API_PREFIX: string = 'start-stop-logs';


    public id: number = null;
    public started_at: Date = new Date();
    public ended_at: Date = new Date();
    public work_order_operation = new WorkOrderOperation();
    public is_logged = false;
    public log: Log = new Log();
    public logged_by: User = new User();
    public has_overtime = false;
    public logged_by_display_name = '';
    public end_reason = '';


    form_group = new FormGroup({
        started_at: new FormControl(
            this.started_at,
            Validators.compose([
                Validators.required
            ])
        ),
        ended_at: new FormControl(
            this.ended_at,
            Validators.compose([
                Validators.required
            ])
        ),
        work_order_operation: new FormControl(
            this.work_order_operation,
            Validators.compose([
                Validators.required
            ])
        )
    });


    public getApiPrefix(): string {
        return StartStopLog.API_PREFIX;
    }


    deserialize(input: JSON): StartStopLog {
        this.id = input['id'];
        this.started_at = new Date(input['started_at']);
        this.ended_at = new Date(input['ended_at']);


        this.is_logged = input['is_logged'];


        this.has_overtime = input['has_overtime'];
        this.end_reason = input['end_reason'];

        if (notEmpty(input['work_order_operation'])) {
            this.work_order_operation = this.work_order_operation.deserialize(input['work_order_operation']);
        }

        if (notEmpty(input['log'])) {
            this.log = this.log.deserialize(input['log']);
        }

        if (notEmpty(input['logged_by'])) {
            this.logged_by = this.logged_by.deserialize(input['logged_by']);
        }

        if (notEmpty(input['logged_by_display_name'])) {
            this.logged_by_display_name = input['logged_by_display_name'];
        }


        return this;
    }
}

