<div class="purchase-order-container">
    <div class="ui large header dividing margin-bottom-30 padding-left-30 padding-right-30">
        {{title}}
    </div>

    <fui-dimmer class="inverted" [(isDimmed)]="isPurchaseOrderLoading" [isClickable]="false">
        <div class="ui text loader">Yükleniyor</div>
    </fui-dimmer>


    <ng-container *ngIf="action == EDIT_PURCHASE_ORDER || action == EDIT_PURCHASE_ORDER_BY_ID">
        <form novalidate [formGroup]="purchaseOrder.form_group">

            <div class="ui form">

                <div class="field"
                     [ngClass]="{'error': purchaseOrder.form('material').hasError('required') && (purchaseOrder.form('material').dirty || purchaseOrder.form('material').touched)}">
                    <label>Ürün/Hizmet/Malzeme</label>
                        <input type="text"
                               formControlName="material"
                               [(ngModel)]="purchaseOrder.material">
                        <div class="ui pointing red basic label"
                             *ngIf="purchaseOrder.form('material').hasError('required') && (purchaseOrder.form('material').dirty || purchaseOrder.form('material').touched)">
                            Boş Bırakılamaz
                        </div>
                </div>

                <div class="field"
                     [ngClass]="{'error': purchaseOrder.form('purchase_order_type').hasError('required') && (purchaseOrder.form('purchase_order_type').dirty || purchaseOrder.form('purchase_order_type').touched)}">
                    <label>Sipariş Tipi</label>
                    <fui-select class="selection"
                                [(ngModel)]="purchaseOrder.purchase_order_type"
                                formControlName="purchase_order_type"
                                [options]="purchaseOrder.purchaseOrderTypes"
                                [optionTemplate]="purchaseOrderTypeOptionTemplate"
                                [isSearchable]="false"
                                valueField="value"
                                #purchaseOrderTypeMenu>
                        <fui-select-option *ngFor="let o of purchaseOrderTypeMenu.filteredOptions"
                                           [value]="o"></fui-select-option>
                    </fui-select>
                    <ng-template let-option #purchaseOrderTypeOptionTemplate>
                        {{option.name}}
                    </ng-template>
                </div>


                <div class="field" *ngIf="!purchaseOrder.supplier.id || isSupplierChanging">
                    <label>Tedarikçi Ara
                        <a class="mini ui label basic red margin-left-20" (click)="changeSupplier(false)"
                           *ngIf="isSupplierChanging">
                            Vazgeç <i class="icon close"></i>
                        </a>
                    </label>
                    <fui-search
                            class="ui fluid input"
                            placeholder="Yazmaya başlayın..."
                            [optionsLookup]="searchSuppliers"
                            optionsField="title"
                            (resultSelected)="selectSupplier($event)"
                            [retainSelectedResult]="false"
                    >
                    </fui-search>

                </div>


                <div class="field" *ngIf="purchaseOrder.supplier.id && !isSupplierChanging">
                    <label>Tedarikçi Bilgileri</label>
                    <div class="ui card customer-card fluid">

                        <div class="content">
                            <div class="header">
                                {{purchaseOrder.supplier.title}}
                            </div>
                            <div class="description">
                                <div>
                                    <i class="icon envelope"></i>
                                    {{purchaseOrder.supplier.email}}
                                </div>
                                <div>
                                    <i class="icon phone"></i>
                                    {{purchaseOrder.supplier.phone}}
                                </div>
                            </div>
                        </div>
                        <div class="content">
                            <a class="mini ui label basic icon right floated red" (click)="changeSupplier(true)">
                                Değiştir <i class="close icon"></i>
                            </a>

                        </div>
                    </div>


                </div>

                <div class="ui icon visible warning message small" *ngIf="!purchaseOrder.supplier.id">
                    <i class="exclamation icon"></i>
                    <div class="content">
                        <div class="header">
                            Tedarikçi Seçilmedi
                        </div>
                        <p>Tedarikçi seçmek için yukarıdaki arama kutusuna tedarikçinin ünvanını yazmaya başlayın ve
                            gelen
                            menüden tedarikçiyi seçin.</p>
                    </div>
                </div>

                <div class="field"
                     [ngClass]="{'error': purchaseOrder.form('description').hasError('required') && (purchaseOrder.form('description').dirty || purchaseOrder.form('description').touched)}">
                    <label>Sipariş Açıklaması</label>
                    <textarea type="text"
                              formControlName="description"
                              [(ngModel)]="purchaseOrder.description"
                              rows="4"></textarea>
                    <div class="ui pointing red basic label"
                         *ngIf="purchaseOrder.form('description').hasError('required') && (purchaseOrder.form('description').dirty || purchaseOrder.form('description').touched)">
                        Boş Bırakılamaz
                    </div>
                </div>

                <div class="ui divider"></div>


                <div class="field"
                     [ngClass]="{'error': purchaseOrder.form('deadline').hasError('required') && (purchaseOrder.form('deadline').dirty || purchaseOrder.form('deadline').touched)}">
                    <label>Sipariş Termin Tarihi</label>
                    <button class="ui basic blue button"
                            fuiDatepicker
                            [pickerMode]="'date'"
                            [(ngModel)]="purchaseOrder.deadline"
                            formControlName="deadline"
                            [pickerFirstDayOfWeek]="1"
                    >
                        <i class="calendar icon"></i> {{purchaseOrder.deadline | date: 'dd.MM.yyyy'}}
                    </button>
                </div>


                <div class="field"
                     [ngClass]="{'error': purchaseOrder.form('shipment_deadline').hasError('required') && (purchaseOrder.form('shipment_deadline').dirty || purchaseOrder.form('shipment_deadline').touched)}">
                    <label>Sevkiyat Termin Tarihi</label>
                    <button class="ui basic blue button"
                            fuiDatepicker
                            [pickerMode]="'date'"
                            [(ngModel)]="purchaseOrder.shipment_deadline"
                            formControlName="deadline"
                            [pickerFirstDayOfWeek]="1"
                    >
                        <i class="calendar icon"></i> {{purchaseOrder.shipment_deadline | date: 'dd.MM.yyyy'}}
                    </button>
                </div>


                <div class="field">
                    <label>Sevkiyatı Kim Yapacak?</label>
                    <fui-select class="selection fluid"
                                [(ngModel)]="purchaseOrder.shipment_by"
                                [ngModelOptions]="{standalone: true}"
                                [options]="[{value: true, name: 'Biz'}, {value: false, name: 'Tedarikçi'}]"
                                [optionTemplate]="shipmentByOptionTemplate"
                                [isSearchable]="false"
                                valueField="value"
                                #shipmentByOptionMenu>
                        <fui-select-option *ngFor="let o of shipmentByOptionMenu.filteredOptions"
                                           [value]="o"></fui-select-option>
                    </fui-select>
                    <ng-template let-option #shipmentByOptionTemplate>
                        {{option.name}}
                    </ng-template>
                </div>

                <div class="field">
                    <label>Sevkiyatı Kim Ödeyecek?</label>
                    <fui-select class="selection fluid"
                                [(ngModel)]="purchaseOrder.shipment_paid_by"
                                [ngModelOptions]="{standalone: true}"
                                [options]="[{value: true, name: 'Biz'}, {value: false, name: 'Tedarikçi'}]"
                                [optionTemplate]="shipmentPaidByOptionTemplate"
                                [isSearchable]="false"
                                valueField="value"
                                #shipmentPaidByOptionMenu>
                        <fui-select-option *ngFor="let o of shipmentPaidByOptionMenu.filteredOptions"
                                           [value]="o"></fui-select-option>
                    </fui-select>
                    <ng-template let-option #shipmentPaidByOptionTemplate>
                        {{option.name}}
                    </ng-template>
                </div>

                <div class="ui divider"></div>
                <ng-container *ngIf="purchaseOrder.purchase_order_type == 'material'">

                    <div class="field"
                         [ngClass]="{'error': purchaseOrder.form('material_shape').hasError('required') && (purchaseOrder.form('material_shape').dirty || purchaseOrder.form('material_shape').touched)}">
                        <label>Malzeme Şekli</label>
                        <fui-select class="selection fluid"
                                    [(ngModel)]="purchaseOrder.material_shape.shape"
                                    formControlName="material_shape"
                                    [options]="[{value: 'round', name: 'Dolu Çap'}, {value: 'rectangle', name: 'Dolu Kütük'}, {value: 'tubular', name: 'Boru Çap'}]"
                                    [optionTemplate]="materialShapeOptionTemplate"
                                    [isSearchable]="false"
                                    valueField="value"
                                    #materialShapeOptionMenu>
                            <fui-select-option *ngFor="let o of materialShapeOptionMenu.filteredOptions"
                                               [value]="o"></fui-select-option>
                        </fui-select>
                        <ng-template let-option #materialShapeOptionTemplate>
                            {{option.name}}
                        </ng-template>
                    </div>

                    <div class="fields equal width">
                        <div class="field"
                             *ngIf="purchaseOrder.material_shape.shape == 'round' || purchaseOrder.material_shape.shape == 'tubular'">
                            <label>Çap Ø (mm)</label>
                            <input type="number"
                                   [ngModelOptions]="{standalone: true}"
                                   [(ngModel)]="purchaseOrder.material_shape.diameter">
                        </div>
                        <div class="field" *ngIf="purchaseOrder.material_shape.shape == 'rectangle'">
                            <label>En (mm)</label>
                            <input type="number"
                                   [ngModelOptions]="{standalone: true}"
                                   [(ngModel)]="purchaseOrder.material_shape.width">
                        </div>
                        <div class="field"
                             *ngIf="purchaseOrder.material_shape.shape == 'round' || purchaseOrder.material_shape.shape == 'rectangle' || purchaseOrder.material_shape.shape == 'tubular'">
                            <label>Boy (mm)</label>
                            <input type="number"
                                   [ngModelOptions]="{standalone: true}"
                                   [(ngModel)]="purchaseOrder.material_shape.height">
                        </div>

                        <div class="field"
                             *ngIf="purchaseOrder.material_shape.shape == 'rectangle'">
                            <label>Yükseklik (mm)</label>
                            <input type="number"
                                   [ngModelOptions]="{standalone: true}"
                                   [(ngModel)]="purchaseOrder.material_shape.depth">
                        </div>

                        <div class="field" *ngIf="purchaseOrder.material_shape.shape == 'tubular'">
                            <label>Et Kalınlığı (mm)</label>
                            <input type="text"
                                   [ngModelOptions]="{standalone: true}"
                                   [(ngModel)]="purchaseOrder.material_shape.wall">
                        </div>
                    </div>

                </ng-container>
                <div class="fields">
                    <div class="twelve wide field"
                         [ngClass]="{'error': purchaseOrder.form('quantity').hasError('requiredNumber') && (purchaseOrder.form('quantity').dirty || purchaseOrder.form('quantity').touched)}">
                        <label>Miktar</label>
                        <input type="text"
                               formControlName="quantity"
                               [(ngModel)]="purchaseOrder.quantity">
                        <div class="ui pointing red basic label"
                             *ngIf="purchaseOrder.form('quantity').hasError('requiredNumber') && (purchaseOrder.form('quantity').dirty || purchaseOrder.form('quantity').touched)">
                            Boş Bırakılamaz
                        </div>
                    </div>
                    <div class="four wide field">
                        <label>Birim</label>
                        <fui-select class="selection fluid"
                                    [(ngModel)]="purchaseOrder.unit"
                                    formControlName="unit"
                                    [options]="[{value: 'pcs', name: 'Adet'}, {value: 'kg', name: 'kg'}]"
                                    [optionTemplate]="unitOptionTemplate"
                                    [isSearchable]="false"
                                    valueField="value"
                                    #unitOptionMenu>
                            <fui-select-option *ngFor="let o of unitOptionMenu.filteredOptions"
                                               [value]="o"></fui-select-option>
                        </fui-select>
                        <ng-template let-option #unitOptionTemplate>
                            {{option.name}}
                        </ng-template>
                    </div>
                </div>

                <div class="fields equal width">
                    <div class="field"
                         [ngClass]="{'error': purchaseOrder.form('unit_price').hasError('requiredNumber') && (purchaseOrder.form('unit_price').dirty || purchaseOrder.form('unit_price').touched)}">

                        <label>Birim Fiyat (KDV Hariç)</label>
                        <div class="ui right labeled input">
                            <input type="number"
                                   formControlName="unit_price"
                                   [(ngModel)]="purchaseOrder.unit_price">
                            <div class="ui basic label">
                                <i class="lira icon"></i>
                            </div>
                        </div>
                        <div class="ui pointing red basic label"
                             *ngIf="purchaseOrder.form('unit_price').hasError('requiredNumber') && (purchaseOrder.form('unit_price').dirty || purchaseOrder.form('unit_price').touched)">
                            Boş Bırakılamaz
                        </div>
                    </div>
                    <div class="field"
                         [ngClass]="{'error': purchaseOrder.form('shipment_price').hasError('requiredNumber') && (purchaseOrder.form('shipment_price').dirty || purchaseOrder.form('shipment_price').touched)}">

                        <label>Sevkiyat Ücreti (Toplam) (KDV Hariç)</label>
                        <div class="ui right labeled input">
                            <input type="number"
                                   formControlName="shipment_price"
                                   [(ngModel)]="purchaseOrder.shipment_price">
                            <div class="ui basic label">
                                <i class="lira icon"></i>
                            </div>
                        </div>
                        <div class="ui pointing red basic label"
                             *ngIf="purchaseOrder.form('shipment_price').hasError('requiredNumber') && (purchaseOrder.form('shipment_price').dirty || purchaseOrder.form('shipment_price').touched)">
                            Boş Bırakılamaz
                        </div>
                    </div>
                </div>


                <div class="ui divider"></div>

                <div class="grouped fields">
                    <label>İstenilen Belgeler</label>
                    <div class="field" *ngFor="let document of purchaseOrder.required_documents.document_list">
                        <fui-checkbox
                                [(ngModel)]="document.required"
                                [ngModelOptions]="{standalone: true}"
                        >
                            {{document.name}}
                        </fui-checkbox>
                    </div>
                    <div class="field">
                        <textarea
                                rows="6"
                                placeholder="Diğer Belgeler"
                                [(ngModel)]="purchaseOrder.required_documents.other"
                                [ngModelOptions]="{standalone: true}"></textarea>
                    </div>
                </div>

                <div class="ui divider"></div>


                <div class="field margin-top-20">
                    <button class="ui button positive right floated" (click)="savePurchaseOrder()">
                        Kaydet
                    </button>
                </div>
            </div>
        </form>
    </ng-container>


    <ng-container *ngIf="action == LIST_PURCHASE_ORDER">
        <div *ngIf="purchaseOrderList && purchaseOrderList.length == 0">
            <div class="ui grid centered margin-top-75">
                <h2 class="ui icon header grey">
                    <i class="warning circle icon"></i>
                    <div class="content">
                        Kayıt Bulunamadı
                        <div class="sub header">Kayıt eklemek için <b>Sipariş -> Yeni Kayıt Ekle</b> bağlantısını
                            kullanabilirsiniz.
                        </div>
                    </div>
                </h2>
            </div>
        </div>
        <div *ngIf="purchaseOrderList && purchaseOrderList.length > 0">
            <div class="ui small feed">
                <div class="event" *ngFor="let purchaseOrder of purchaseOrderList">
                    <div class="label">
                        <img src="./assets/images/joe.jpg">
                    </div>
                    <div class="content">
                        <div class="summary">
                            <a class="user">
                                asdasdsa
                            </a> tarafından eklendi
                            <div class="date">
                                {{purchaseOrder.created_at | date: 'dd.MM.yyyy HH:mm:ss'}}
                            </div>
                        </div>
                        <div class="extra text">
                            <label>Sipariş Adı</label>
                            <p>{{purchaseOrder.name}}</p>

                            <label>Sipariş Açıklaması</label>
                            <p>{{purchaseOrder.description}}</p>

                            <label>Tedarikçi</label>
                            <p>{{purchaseOrder.supplier.title}}</p>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
