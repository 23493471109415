import {BaseModel} from './base.model';
import {EloquentSerializable} from '../interfaces/interfaces';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HolosenValidators} from '../validators/validators';

export class Customer extends BaseModel implements EloquentSerializable<Customer> {


    public static readonly API_PREFIX: string = 'customers';


    public id: number = null;
    public title = '';
    public code = '';
    public phone = '';
    public email = '';

    public validation: Array<any>;


    form_group = new FormGroup({
        title: new FormControl(
            this.title,
            Validators.compose([
                Validators.required
            ])
        ),
        phone: new FormControl(
            this.phone,
            Validators.compose([
                Validators.required
            ])
        ),
        email: new FormControl(
            this.email,
            Validators.compose([

            ])
        )
    });

    public getApiPrefix(): string {
        return Customer.API_PREFIX;
    }


    deserialize(input: JSON): Customer {
        this.id = input['id'];
        this.title = input['title'];
        this.code = input['code'];
        this.phone = input['phone'];
        this.email = input['email'];
        return this;
    }


}
