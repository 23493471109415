import {BaseModel} from './base.model';
import {EloquentSerializable} from '../interfaces/interfaces';
export class Supplier extends BaseModel implements EloquentSerializable<Supplier> {

    public static readonly API_PREFIX: string = 'suppliers';


    public id: number = null;
    public title = '';
    public phone = '';
    public email = '';

    public validation: Array<any>;

    public getApiPrefix(): string {
        return Supplier.API_PREFIX;
    }


    deserialize(input: JSON): Supplier {
        this.id = input['id'];
        this.title = input['title'];
        this.phone = input['phone'];
        this.email = input['email'];
        return this;
    }
}
