import {EloquentSerializable, EloquentValidation} from '../interfaces/interfaces';
import {Attachable} from './attachable.model';
import {WorkOrderOperationStatus} from './work-order-operation-status.model';
import {WorkOrderOperationType} from './work-order-operation-type.model';
import {Supplier} from './supplier.model';
import {Personnel} from './personnel.model';
import {WorkStation} from './work-station.model';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {HolosenValidators} from '../validators/validators';
import {Loggable} from './loggable.model';
import {Log} from './log.model';
import {Attachment} from './attachment.model';
import {BaseModel} from './base.model';
import {EloquentManagerService} from '../providers/eloquent-manager.provider';
import {applyMixins} from 'rxjs/util/applyMixins';
import {Uploadable} from './uploadable.model';
import {Mixin, notEmpty} from '../utils/utils';
import {Shippable} from './shippable.model';
import {Shipment} from './shipment.model';
import {PurchaseOrder} from './purchase-order.model';
import {User} from './user.model';
import {WorkOrder} from './work-order.model';
import {QualityControlConfig} from './quality-control-config.model';
import {QualityControlGroup} from './quality-control-group.model';

@Mixin([Attachable, Loggable])
export class WorkOrderOperation extends BaseModel implements EloquentSerializable<WorkOrderOperation>, Loggable, Attachable {


    public static readonly API_PREFIX: string = 'work-order-operations';

    public id: number = null;
    public work_order_id: number = null;
    public work_order_operation_status: WorkOrderOperationStatus = new WorkOrderOperationStatus();
    public work_order_operation_type: WorkOrderOperationType = new WorkOrderOperationType();
    public supplier: Supplier = new Supplier();
    public started_at: Date = null;
    public ended_at: Date = null;
    public user: User = new User();
    public work_station: WorkStation = new WorkStation();
    public completion_percentage: number = null;
    public entry_datetime: Date = new Date();
    public deadline: Date = new Date();
    public estimated_operating_time: number = null;
    public is_internal = true;
    public completed_num_of_parts = null;
    public wasted_num_of_parts = null;
    public total_num_of_parts = null;
    public description = '';
    public completed_quality_control_num = 0;

    public incoming_completed_num_of_parts = 0;
    public incoming_wasted_num_of_parts = 0;
    public outgoing_completed_num_of_parts = 0;
    public outgoing_wasted_num_of_parts = 0;
    public purchase_order_id: null;
    public has_open_log = false;
    public can_start = false;
    public is_delayed = false;

    public quality_control_config: QualityControlConfig = new QualityControlConfig();

    public quality_control_group_list: Array<QualityControlGroup> = [];

    public work_order = null;

    public total_actual_time_formatted = 0;
    public total_actual_time_per_part_formatted = 0;


    public total_overtime = 0;
    public total_overtime_formatted = 0;

    public has_notes = false;

    /*
    Eloquent Request Callbacks and Flags
     */
    isStatusLoading = false;


    /*
     ATTACHABLE
     */
    attachments: Array<Attachment> = [];
    attach: (attachment: Attachment) => void;
    detach: (attachment: Attachment) => void;
    uploadableToAttachment: (uploadable: Uploadable) => void;
    deserializeAttachments: (input: any) => void;

    /*
     LOGGABLE
     */
    logs: Array<Log> = [];
    add_log: (log: Log) => void;
    deserializeLogs: (input: any) => void;


    form_group = new FormGroup({
        // work_order_operation_status: new FormControl(
        //     this.work_order_operation_status,
        //     Validators.compose([
        //         HolosenValidators.requiredModel,
        //     ])
        // ),
        work_order_operation_type: new FormControl(
            this.work_order_operation_type,
            Validators.compose([
                HolosenValidators.requiredModel,
            ])
        ),
        description: new FormControl(
            this.description,
            Validators.compose([
            ])
        ),
        work_station: new FormControl(
            this.work_station,
            Validators.compose([
                HolosenValidators.requiredIf('is_internal', true, HolosenValidators.requiredModel)
            ])
        ),
        user: new FormControl(
            this.user,
            Validators.compose([
                HolosenValidators.requiredIf('is_internal', true, HolosenValidators.requiredModel)
            ])
        ),
        supplier: new FormControl(
            this.supplier,
            Validators.compose([
                HolosenValidators.requiredIf('is_internal', false, HolosenValidators.requiredModel)
            ])
        ),
        entry_datetime: new FormControl(
            this.entry_datetime,
            Validators.compose([
                Validators.required,
            ])
        ),
        deadline: new FormControl(
            this.entry_datetime,
            Validators.compose([
                Validators.required,
            ])
        ),
        started_at: new FormControl(
            this.entry_datetime,
            Validators.compose([
                Validators.required,
            ])
        ),
        ended_at: new FormControl(
            this.entry_datetime,
            Validators.compose([
                Validators.required,
            ])
        ),
        estimated_operating_time: new FormControl(
            this.estimated_operating_time,
            Validators.compose([
                HolosenValidators.requiredIf('is_internal', true, HolosenValidators.requiredNumber)
            ])
        ),

        is_internal: new FormControl(
            this.is_internal,
            Validators.compose([
                Validators.required,
            ])
        ),
    });


    constructor() {
        super();
    }

    public getApiPrefix(): string {
        return WorkOrderOperation.API_PREFIX;
    }


    public deserializeQualityControlGroups = (input) => {
        this.quality_control_group_list = [];

        for (let i = 0; i < input.length; i++) {
            this.quality_control_group_list.push(new QualityControlGroup().deserialize(input[i]));
        }

    };

    deserialize(input: Object): WorkOrderOperation {

        this.id = input['id'];
        this.work_order_id = input['work_order_id'];


        this.started_at = new Date(input['started_at']);
        this.ended_at = new Date(input['ended_at']);

        this.completion_percentage = input['completion_percentage'];
        this.entry_datetime = input['entry_datetime'];
        this.deadline = new Date(input['deadline']);
        this.estimated_operating_time = input['estimated_operating_time'];
        this.is_internal = input['is_internal'];
        this.completed_num_of_parts = input['completed_num_of_parts'];
        this.wasted_num_of_parts = input['wasted_num_of_parts'];
        this.incoming_completed_num_of_parts = input['incoming_completed_num_of_parts'];
        this.incoming_wasted_num_of_parts = input['incoming_wasted_num_of_parts'];
        this.outgoing_completed_num_of_parts = input['outgoing_completed_num_of_parts'];
        this.outgoing_wasted_num_of_parts = input['outgoing_wasted_num_of_parts'];
        this.purchase_order_id = input['purchase_order_id'];
        this.total_num_of_parts = input['total_num_of_parts'];
        this.has_open_log = input['has_open_log'];
        this.can_start = input['can_start'];
        this.is_delayed = input['is_delayed'];
        this.description = input['description'];
        this.completed_quality_control_num = input['completed_quality_control_num'];


        this.work_order_operation_status = this.work_order_operation_status.deserialize(input['work_order_operation_status']);
        this.work_order_operation_type = this.work_order_operation_type.deserialize(input['work_order_operation_type']);

        if (notEmpty(input['user'])) {
            this.user = this.user.deserialize(input['user']);
        }

        if (notEmpty(input['work_station'])) {
            this.work_station = this.work_station.deserialize(input['work_station']);
        }

        if (notEmpty(input['supplier'])) {
            this.supplier = this.supplier.deserialize(input['supplier']);
        }

        if (notEmpty(input['work_order'])) {
            this.work_order = new WorkOrder().deserialize(input['work_order']);
        }


        if (notEmpty(input['quality_control_config'])) {
            this.quality_control_config.deserialize(input['quality_control_config']);
        }


        if (notEmpty(input['total_actual_time_formatted'])) {
            this.total_actual_time_formatted = input['total_actual_time_formatted'];
        }

        if (notEmpty(input['total_actual_time_per_part_formatted'])) {
            this.total_actual_time_per_part_formatted = input['total_actual_time_per_part_formatted'];
        }

        if (notEmpty(input['total_overtime'])) {
            this.total_overtime = input['total_overtime'];
        }

        if (notEmpty(input['total_overtime_formatted'])) {
            this.total_overtime_formatted = input['total_overtime_formatted'];
        }


        if (notEmpty(input['has_notes'])) {
            this.has_notes = input['has_notes'];
        }

        /*if (input['purchase_order']) {
            this.purchase_order = this.purchase_order.deserialize(input['purchase_order']);
        }*/


        this.deserializeAttachments(input);
        this.deserializeLogs(input);


        return this;


    }


}

