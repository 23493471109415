import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {Gatekeeper} from './shared/guards/gatekeeper.guard';
import {AuthenticatedWrapperComponent} from './components/authenticated/authenticated-wrapper/authenticated-wrapper.component';
import {WorkOrderRoutes} from './components/authenticated/work-order/work-order.routes';
import {LoginRoutes} from './components/public/login/login.routes';
import {LogoutRoutes} from './components/public/logout/logout.routes';
import {PublicWrapperComponent} from './components/public/public-wrapper/public-wrapper.component';
import {NotFoundRoutes} from './components/public/not-found/not-found.routes';
import {PlanningRoutes} from './components/authenticated/planning/planning.routes';
import {PurchaseRoutes} from './components/authenticated/purchase/purchase.routes';
import {OperatorRoutes} from './components/authenticated/operator/operator.routes';
import {UserManagementRoutes} from './components/authenticated/user-management/user-management.routes';
import {KeyMaster} from './shared/guards/key-master.guard';
import {WorkStationRoutes} from './components/authenticated/work-station/work-station.routes';
import {MeRoutes} from './components/authenticated/me/me.routes';
import {CurrentWorkRoutes} from './components/authenticated/current-work/current-work.routes';
import {ReportingRoutes} from './components/authenticated/reporting/reporting.routes';


export const AuthenticatedRoutes = [

    ...PurchaseRoutes,
    ...WorkOrderRoutes,
    ...WorkStationRoutes,
    ...OperatorRoutes,
    ...UserManagementRoutes,
    ...PlanningRoutes,
    ...MeRoutes,
    ...CurrentWorkRoutes,
    ...ReportingRoutes

];


export const PublicRoutes = [

    ...LoginRoutes,
    ...LogoutRoutes

];

export const AppRoutes = [

    {
        path: '',
        component: AuthenticatedWrapperComponent,
        children: [
            ...AuthenticatedRoutes
        ],
        canActivate: [Gatekeeper],
        canActivateChild: [Gatekeeper],

    },

    {
        path: '',
        component: PublicWrapperComponent,
        children: [
            ...PublicRoutes,
            ...NotFoundRoutes
        ],

    }

];


@NgModule({
    imports: [
        RouterModule.forChild(AppRoutes)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
