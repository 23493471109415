import {AuthenticationService} from '../../../shared/services/authentication/authentication.service';
import {WorkStationComponent} from './work-station.component';


export const WorkStationRoutes = [
    {
        path: 'work-station',
        component: WorkStationComponent,
        data: {
            roles: [
                AuthenticationService.ROLES.ADMIN
            ]
        }
    }
];