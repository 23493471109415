import {AuthenticationService} from '../../../shared/services/authentication/authentication.service';
import {PurchaseComponent} from './purchase.component';


export const PurchaseRoutes = [
    {
        path: 'purchase',
        component: PurchaseComponent,
        data: {
            roles: [
                AuthenticationService.ROLES.ADMIN,
                AuthenticationService.ROLES.PLANNER,
            ]
        }
    }
];