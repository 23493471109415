<div class="quality-control-container">
  <div class="ui large header dividing margin-bottom-30 padding-left-30 padding-right-30">
    {{title}}
  </div>


  <fui-tabset>
    <div class="ui top attached tabular menu">
      <a class="item" fuiTabHeader="1"><i class="icon plus"></i>Yeni Kayıt Ekle</a>
      <a class="item" fuiTabHeader="2"><i class="icon unhide"></i>Kayıtları Gör</a>
    </div>
    <div class="ui bottom attached segment" fuiTabContent="1">
      <div class="ui grid centered margin-top-75 margin-bottom-75"
           *ngIf="operation.quality_control_config.quality_control_config_items.length === 0">
        <h2 class="ui icon header grey">
          <i class="warning circle icon"></i>
          <div class="content">
            Kalite Kontrol Konfigürasyonu Yapılmamış
            <div class="sub header">
              Kayıt ekleyebilmek için <b>İmalat Planlama Sorumlusu</b>'na bilgi veriniz
            </div>
          </div>
        </h2>
      </div>
      <ng-container *ngIf="newQualityControlGroup.quality_controls.length > 0">
        <ng-container *ngFor="let controlPoint of newQualityControlGroup.quality_controls; let i = index">
          <div class="ui header small">Kontrol Noktası {{i + 1}}</div>
          <form [formGroup]="controlPoint.form_group" class="ui form" novalidate>
            <div class="three fields">
              <div class="field">
                <label>
                  Bölüm Adı
                </label>
                <h3 class="ui header margin-top-0">
                  {{controlPoint.section}}
                </h3>
              </div>
              <div class="field">
                <label>
                  Gerekli Ölçü
                </label>
                <h3 class="ui header margin-top-0">
                  <div class="ui grid equal width">
                    <div class="column">{{controlPoint.dimension}}mm</div>
                    <div class="column">
                      <div>{{controlPoint.tolerance_upper}}</div>
                      <div>{{controlPoint.tolerance_lower}}</div>
                    </div>
                  </div>
                </h3>
              </div>
              <div class="field"
                   [ngClass]="{'error': controlPoint.form('measurement').hasError('requiredNumber') && (controlPoint.form('measurement').dirty || controlPoint.form('measurement').touched)}">
                <label>
                  Ölçülen Değer
                </label>
                <div class="ui right labeled input">
                  <input type="number"
                         [(ngModel)]="controlPoint.measurement"
                         formControlName="measurement">

                  <div class="ui label basic">
                    mm
                  </div>
                </div>
                <div class="ui pointing red basic label"
                     *ngIf="controlPoint.form('measurement').hasError('requiredNumber') && (controlPoint.form('measurement').dirty || controlPoint.form('measurement').touched)">
                  Boş Bırakılamaz
                </div>
              </div>
              <div class="field"
                   [ngClass]="{'error': controlPoint.form('measured_with').hasError('required') && (controlPoint.form('measured_with').dirty || controlPoint.form('measured_with').touched)}">
                <label>Ölçüm Aleti</label>
                <fui-select class="selection"
                            [(ngModel)]="controlPoint.measured_with"
                            formControlName="measured_with"
                            [options]="measurementInstrumentsList"
                            [isSearchable]="false"
                            #measuredWithOptionMenu>

                  <fui-select-option
                    *ngFor="let o of measurementInstrumentsList"
                    [value]="o">
                  </fui-select-option>
                </fui-select>
                <div class="ui pointing red basic label"
                     *ngIf="controlPoint.form('measured_with').hasError('required') && (controlPoint.form('measured_with').dirty || controlPoint.form('measured_with').touched)">
                  Boş Bırakılamaz
                </div>
              </div>
            </div>
          </form>
        </ng-container>
        <button class="ui button fluid green large margin-top-30" (click)="saveQualityControlGroup()">
          <i class="icon check"></i>Kaydet
        </button>
      </ng-container>

    </div>
    <div class="ui bottom attached segment" fuiTabContent="2">
      <fui-dimmer class="inverted" [(isDimmed)]="!isQualityControlsLoaded" [isClickable]="false">
        <div class="ui text loader">Yükleniyor</div>
      </fui-dimmer>
      <ng-container *ngIf="isQualityControlsLoaded">
        <div *ngIf="operation.quality_control_group_list.length === 0">
          <div class="ui grid centered margin-top-75  margin-bottom-75">
            <h2 class="ui icon header grey">
              <i class="warning circle icon"></i>
              <div class="content">
                Kayıt Bulunamadı
                <div class="sub header">Kayıt eklemek için <b>Yeni Kayıt Ekle</b> bağlantısını
                  kullanabilirsiniz.
                </div>
              </div>
            </h2>
          </div>
        </div>
        <div *ngIf="operation.quality_control_group_list.length > 0">
          <ng-container *ngFor="let controlGroup of operation.quality_control_group_list">
            <table class="ui celled table"
                   [ngClass]="{
                               'red': controlGroup.is_out_of_tolerance,
                               'green': !controlGroup.is_out_of_tolerance
                               }">
              <thead>
              <tr>
                <th colspan="4">
                  <div>{{controlGroup.measured_by}}</div>
                  <small>{{controlGroup.created_at | date: 'dd.MM.yyyy HH:mm:ss'}}</small>
                </th>
              </tr>
              <tr>
                <th>Bölüm</th>
                <th>Gerekli Ölçü</th>
                <th>Ölçülen Değer</th>
                <th>Ölçüm Aleti</th>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let controlPoint of controlGroup.quality_controls; let i = index">
                <tr [ngClass]="{'error': controlPoint.is_out_of_tolerance}">
                  <td>{{controlPoint.section}}</td>
                  <td>{{controlPoint.dimension}}
                    <small>({{controlPoint.tolerance_upper + ' ' + controlPoint.tolerance_lower}})</small>mm
                  </td>
                  <td>{{controlPoint.measurement}}mm <i class="icon warning circle small red"
                                                        *ngIf="controlPoint.is_out_of_tolerance"></i>
                  </td>
                  <td>{{controlPoint.measured_with}}</td>
                </tr>
              </ng-container>
              </tbody>
            </table>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </fui-tabset>

</div>
