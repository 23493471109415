import {EloquentSerializable} from '../interfaces/interfaces';
import {QualityControl} from './quality-control.model';
import {BaseModel} from './base.model';
import {HolosenValidators} from '../validators/validators';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {notEmpty} from '../utils/utils';


export class QualityControlConfigItem extends BaseModel implements EloquentSerializable<QualityControlConfigItem> {
  public section = '';
  public dimension = null;
  public tolerance = 0.01;
  public tolerance_upper = 0.1;
  public tolerance_lower = -0.1;


  form_group = new FormGroup({
    section: new FormControl(
      this.section,
      Validators.compose([
        Validators.required
      ])
    ),
    dimension: new FormControl(
      this.dimension,
      Validators.compose([
        HolosenValidators.requiredNumber
      ])
    ),
    // tolerance: new FormControl(
    //     this.tolerance,
    //     Validators.compose([
    //         HolosenValidators.requiredNumber
    //     ])
    // ),
    tolerance_upper: new FormControl(
      this.tolerance_upper,
      Validators.compose([
        HolosenValidators.requiredNumber
      ])
    ),
    tolerance_lower: new FormControl(
      this.tolerance_lower,
      Validators.compose([
        HolosenValidators.requiredNumber
      ])
    )


  });

  deserialize(input: JSON): QualityControlConfigItem {
    console.log(`[input] `, input);
    this.section = input['section'];
    this.dimension = input['dimension'];

    if (notEmpty(input['tolerance'])) {
      this.tolerance = input['tolerance'];
    }

    if (notEmpty(input['tolerance_upper'])) {
      this.tolerance_upper = input['tolerance_upper'];
    }

    if (notEmpty(input['tolerance_lower'])) {
      this.tolerance_lower = input['tolerance_lower'];
    }


    return this;
  }
}

export class QualityControlConfig extends BaseModel implements EloquentSerializable<QualityControlConfig> {


  public required_control_num = 3;
  public quality_control_config_items: Array<QualityControlConfigItem> = [];

  form_group = new FormGroup({
    required_control_num: new FormControl(
      this.required_control_num,
      Validators.compose([
        HolosenValidators.requiredNumber
      ])
    )
  });

  deserialize(input: JSON): QualityControlConfig {

    this.required_control_num = input['required_control_num'];

    this.quality_control_config_items = [];
    if (notEmpty(input['quality_control_config_items'])) {
      for (let i = 0; i < input['quality_control_config_items'].length; i++) {
        this.quality_control_config_items.push(new QualityControlConfigItem().deserialize(input['quality_control_config_items'][i]));
      }
    }

    return this;
  }
}
