import {Component, Input, OnInit} from '@angular/core';
import {StorageService} from '../../services/storage/storage.service';

@Component({
    selector: 'app-holosen-pdf-viewer',
    templateUrl: './holosen-pdf-viewer.component.html',
    styleUrls: ['./holosen-pdf-viewer.component.scss']
})
export class HolosenPdfViewerComponent implements OnInit {


    config = {
        zoom: 1.0,
        rotation: 0,
        download: false,
        render_text: false,
        src: {
            url: null,
            withCredentials: true,
            httpHeaders: {
                'Access-Control-Allow-Credentials': true,
            }
        }
    };

    @Input('url')
    url = null;

    @Input('title')
    title = null;

    @Input('close') close = () => {
        return false;
    };

    constructor(private storageService: StorageService) {
        const tokenData = this.storageService.get(StorageService.VARS.TOKEN_DATA);

        this.config.src.httpHeaders['Accept'] = 'application/json';
        this.config.src.httpHeaders['Authorization'] = tokenData.token_type + ' ' + tokenData.access_token;

        // (<any>window).PDFJS.workerSrc = './assets/plugins/pdfjs/pdf.worker.min.js';
    }

    ngOnInit() {
        this.config.src.url = this.url;
        console.log(this.config.src.url);
    }

    zoomIn = () => {

        if (this.config.zoom >= 3.0) {
            return;
        }

        this.config.zoom += 0.2;
    };

    zoomOut = () => {

        if (this.config.zoom <= 0.2) {
            return;
        }

        this.config.zoom -= 0.2;
    };

    rotateRight = () => {
        this.config.rotation = (this.config.rotation + 90) % 360;
    };

    rotateLeft = () => {
        this.config.rotation = (this.config.rotation - 90) % 360;
    };

    // afterLoadComplete = (pdf: PDFDocumentProxy) => {
    //     console.log(pdf);
    // }
    //
    // pageRendered = (page) => {
    //     console.log(page);
    // }
    //
    // onProgress = (event) => {
    //     console.log(event);
    // }

}
