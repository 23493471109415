import {Injectable} from '@angular/core';

@Injectable()
export class StorageService {

    /*
     * STORAGE VARIABLES
     */


    public static readonly VARS = {
        STORAGE: 'local-storage',
        TOKEN_DATA: 'token-data',
        USER_DATA: 'user-data'
    };


    constructor() {
        this.setup();
    }


    public get(key: string) {
        const storage: object = this.getStorage();
        return (storage.hasOwnProperty(key)) ? storage[key] : false;
    }

    public set(key: string, value: any) {
        const storage: object = this.getStorage();
        storage[key] = value;

        this.setStorage(storage);
    }

    private getStorage(): object {
        //        return JSON.parse(this.decrypt(localStorage.getItem(StorageService.VARS.STORAGE)));
        return JSON.parse(localStorage.getItem(StorageService.VARS.STORAGE));
    }

    private setStorage(storage: object): void {
        //        localStorage.setItem(StorageService.VARS.STORAGE, this.encrypt(JSON.stringify(storage)));
        localStorage.setItem(StorageService.VARS.STORAGE, JSON.stringify(storage));
    }

    private encrypt(decryptedStorage: string) {
        return btoa(decryptedStorage);
    }

    private decrypt(encryptedStorage: string) {
        return atob(encryptedStorage);
    }


    public setup() {
        if (localStorage.getItem(StorageService.VARS.STORAGE) === null) {
            this.setStorage({});
        }
    }

    public clear() {
        localStorage.clear();
        this.setup();
    }


}

