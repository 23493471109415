import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';

import {Router} from '@angular/router';
import {BaseComponent} from '../../base.component';
import {FormControl} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {WorkOrder} from '../../../shared/eloquent/models/work-order.model';
import {WorkOrderStatus} from '../../../shared/eloquent/models/work-order-status.model';
import {WorkOrderOperationStatus} from '../../../shared/eloquent/models/work-order-operation-status.model';
import {Pagination, PAGINATION_ORDER} from '../../../shared/eloquent/models/query/pagination.model';
import {Subscription} from 'rxjs';
import {Customer} from '../../../shared/eloquent/models/customer.model';
import {AuthenticationService} from '../../../shared/services/authentication/authentication.service';
import {EloquentManagerService} from '../../../shared/eloquent/providers/eloquent-manager.provider';
import {AuthHttpService} from '../../../shared/services/http/auth-http.service';
import {ToastService} from '../../../shared/services/toast/toast.service';
import {ConfigService} from '../../../shared/services/config/config.service';
import {WorkOrderOperation} from '../../../shared/eloquent/models/work-order-operation.model';
import {LayoutService} from '../../../shared/services/layout/layout.service';
import {LogHistoryComponent} from '../../../shared/components/log-history/log-history.component';
import {
  DynamicComponentComponent,
  DynamicComponentType
} from '../../../shared/components/dynamic-component/dynamic-component.component';
import {UploaderComponent} from '../../../shared/components/uploader/uploader.component';
import {ShipmentHistoryComponent} from '../../../shared/components/shipment-history/shipment-history.component';
import {PurchaseOrderComponent} from '../../../shared/components/purchase-order/purchase-order.component';
import {WorkOrderOperationComponent} from '../work-order-operation/work-order-operation.component';
import {QualityControlConfigComponent} from '../../../shared/components/quality-control-config/quality-control-config.component';
import {StorageService} from '../../../shared/services/storage/storage.service';
import {WorkOrderOperationType} from '../../../shared/eloquent/models/work-order-operation-type.model';

declare const $: any;

@Component({
  selector: 'app-planning',
  templateUrl: 'planning.component.html',
  styleUrls: ['planning.component.scss']
})
export class PlanningComponent extends BaseComponent implements OnInit, AfterViewInit {

  pageTitle = 'Planlama';

  workOrderList: Array<WorkOrder> = [];
  typeList: Array<WorkOrderOperationType>;
  workOrderStatusList: Array<WorkOrderStatus> = [];
  workOrderOperationStatusList: Array<WorkOrderOperationStatus> = [];
  pagination: Pagination = new Pagination();
  paginateSubscription: Subscription;

  searchBarCtrl: FormControl;


  @ViewChild('dynamicComponent', {static: false})
  dynamicComponent: DynamicComponentComponent;

  @ViewChild('orderAccordion', {static: false})
  orderAccordion: ElementRef;

  @ViewChild('confirmModal', {static: false})
  confirmModal: ElementRef;


  isCustomerDropdownOpen = false;
  iStatusDropdownOpen = false;
  isOpTypeDropdownOpen = false;
  isPaginationLoading = true;

  orderList: Array<any> = [
    {
      name: 'Termin Tarihi (Azalan)',
      field: 'DEADLINE',
      value: PAGINATION_ORDER.DESCENDING
    },
    {
      name: 'Termin Tarihi (Artan)',
      field: 'DEADLINE',
      value: PAGINATION_ORDER.ASCENDING
    },
    {
      name: 'Giriş Tarihi (Azalan)',
      field: 'ENTRY_DATETIME',
      value: PAGINATION_ORDER.DESCENDING
    },
    {
      name: 'Giriş Tarihi (Artan)',
      field: 'ENTRY_DATETIME',
      value: PAGINATION_ORDER.ASCENDING
    }

  ];


  private eloquentUpdatedEventSubscription: Subscription;

  constructor(protected router: Router,
              protected auth: AuthenticationService,
              private eloquentManager: EloquentManagerService,
              private toastService: ToastService,
              private authHttp: AuthHttpService,
              private config: ConfigService,
              private layoutService: LayoutService,
              private storageService: StorageService) {
    super(router, auth);
  }

  ngOnInit() {
    super.ngOnInit();

    this.layoutService.setTitle(this.pageTitle);
    this.pagination.order = this.orderList[0];
    const savedPagination = this.storageService.get('planning-pagination');
    if (savedPagination) {
      this.pagination = savedPagination;
    }

    setTimeout(this.paginateWorkOrderList, 100);
    this.getWorkOrderStatuses();
    this.getWorkOrderOperationStatuses();
    this.registerSearchValueChanges();
    this.getWorkOrderOperationTypes();


  }


  ngAfterViewInit(): void {

    $(this.orderAccordion.nativeElement).accordion(
      {
        selector: {
          trigger: '.title .accordion-handle'
        },
        onOpening: function () {
          console.log('onOpening');
        },
        onOpen: (event) => {
          const openIndex = $('.ui.accordion .title.active');

          $('html,body').animate({
              scrollTop: openIndex.offset().top - 75
            },
            450);
        }
      }
    );
  }

  subscribeEloquentEvents = () => {
    this.eloquentUpdatedEventSubscription = this.eloquentManager.onUpdated.subscribe((item) => {

    });
  };

  unsubscribeEloquentEvents = () => {
    this.eloquentUpdatedEventSubscription.unsubscribe();
  };

  getWorkOrderStatuses() {
    this.eloquentManager.list(WorkOrderStatus).subscribe(response => {
      if (response) {
        this.workOrderStatusList = response;
      } else {
        /*
         TODO uyarı
         */
      }
    });
  }

  getWorkOrderOperationStatuses() {
    this.eloquentManager.list(WorkOrderOperationStatus).subscribe(response => {
      if (response) {
        this.workOrderOperationStatusList = response;
      } else {
        /*
         TODO uyarı
         */
      }
    });
  }


  getWorkOrderOperationTypes = () => {

    this.eloquentManager.list(WorkOrderOperationType).subscribe(response => {
      if (response) {
        this.typeList = response;
      } else {
        /*
         TODO uyarı
         */
      }
    });
  };

  paginateWorkOrderList = () => {

    if (this.paginateSubscription) {
      if (!this.paginateSubscription.closed) {
        this.paginateSubscription.unsubscribe();
      }
    }
    this.isPaginationLoading = true;
    this.storageService.set('planning-pagination', this.pagination);
    this.paginateSubscription = this.eloquentManager.paginate(WorkOrder, this.pagination).subscribe((response) => {
      if (response) {

        this.workOrderList = response.data;
        this.pagination.length = response.total;
        $('html, body').animate({scrollTop: 0}, 'slow');
      } else {
        this.toastService.error('Hata Oluştu', 'İş emirleri bilinmeyen bir nedenle yüklenemedi. Lütfen tekrar deneyiniz');
      }

      this.isPaginationLoading = false;
    });
  };

  filterByStatus(status: WorkOrderStatus) {
    this.iStatusDropdownOpen = false;
    this.pagination.filter.status = status;
    this.pagination.pageIndex = 0;
    this.paginateWorkOrderList();

  }

  filterByOpType(type: WorkOrderOperationType) {
    this.isOpTypeDropdownOpen = false;
    this.pagination.filter.work_order_operation_type = type;
    this.pagination.pageIndex = 0;
    this.paginateWorkOrderList();

  }

  filterByCustomer(customer: Customer) {
    this.isCustomerDropdownOpen = false;
    this.pagination.filter.customer = customer;
    this.pagination.pageIndex = 0;
    this.paginateWorkOrderList();
  }


  orderBy(order: any) {
    this.pagination.order = order;
    this.paginateWorkOrderList();
  }


  registerSearchValueChanges() {
    this.searchBarCtrl = new FormControl({
      updateOn: 'blur', // default will be change
    });
    this.searchBarCtrl.setValue(this.pagination.keyword);

    this.searchBarCtrl.valueChanges.pipe(
      debounceTime(500)
    ).subscribe((keyword: string) => {

      this.pagination.keyword = (keyword && keyword.length === 0) ? null : keyword;
      this.pagination.pageIndex = 0;
      this.paginateWorkOrderList();
    });
  }

  searchByProformaId = (proformaID) => {
    this.searchBarCtrl.setValue(proformaID);
  };

  resetSearchValue = () => {
    this.searchBarCtrl.reset();
  };


  searchCustomers = (query) => {
    return new Promise((resolve, reject) => {
      this.eloquentManager.request('POST', this.config.get('apiBaseUrl') + 'customers/get-all-for-autocomplete', {keyword: query})
        .subscribe((response: any) => {
          if (response) {
            const modelList = [];
            const responseArray = response;
            for (let i = 0; i < responseArray.length; i++) {
              modelList.push(new Customer().deserialize(responseArray[i]));
            }
            console.log(modelList);
            resolve(modelList);
          }
          resolve([]);
        });
    });
  };

  onPageChange() {
    this.paginateWorkOrderList();
  }


  openUploader = (order: WorkOrder) => {
    const component: UploaderComponent = (<UploaderComponent>this.dynamicComponent.create(UploaderComponent, DynamicComponentType.SIDEBAR_RIGHT));
    component.title = `Ekleri Yönet | İş Emri: #${order.id}`;
    component.attachable = order;
  };

  openLogHistory = (operation: WorkOrderOperation, order: WorkOrder, addNew = false) => {
    const component: LogHistoryComponent = (<LogHistoryComponent>this.dynamicComponent.create(LogHistoryComponent, DynamicComponentType.SIDEBAR_RIGHT));
    component.operation = operation;
    component.title = addNew ? `Günlük Kaydı Ekle | #${operation.work_order_id} - ${operation.work_order_operation_type.name}` : `Günlük Kayıt Geçmişi | #${operation.work_order_id} - ${operation.work_order_operation_type.name}`;
    component.action = addNew ? LogHistoryComponent.ADD_LOG_HISTORY : LogHistoryComponent.LIST_LOG_HISTORY;
    component.onLogSaved = () => {
      this.eloquentManager.recalculateCompletion(operation);
      this.eloquentManager.recalculateCompletion(order);
    };
  };

  openShipmentHistory = (shippable: any, addNew = false) => {
    const component: ShipmentHistoryComponent = (<ShipmentHistoryComponent>this.dynamicComponent.create(ShipmentHistoryComponent, DynamicComponentType.SIDEBAR_RIGHT));
    component.title = addNew ? 'Sevkiyat Ekle' : `Sevkiyat Geçmişi`;
    component.shippable = shippable;
    component.action = addNew ? ShipmentHistoryComponent.ADD_SHIPMENT_HISTORY : ShipmentHistoryComponent.LIST_SHIPMENT_HISTORY;
    component.onShipmentSaved = () => {
      this.eloquentManager.reload(shippable);
    };
  };

  openPurchaseOrder = (operation: WorkOrderOperation) => {
    const component: PurchaseOrderComponent = (<PurchaseOrderComponent>this.dynamicComponent.create(PurchaseOrderComponent, DynamicComponentType.SIDEBAR_RIGHT));
    component.title = 'Operasyon Siparişi';
    component.purchaseOrderID = operation.purchase_order_id;
    component.action = PurchaseOrderComponent.EDIT_PURCHASE_ORDER;

    component.onPurchaseOrderSaved = () => {
      console.log('onPurchaseOrderSaved');
    };
  };


  openOperation = (operation: WorkOrderOperation = null) => {
    const component: WorkOrderOperationComponent = (<WorkOrderOperationComponent>this.dynamicComponent.create(WorkOrderOperationComponent, DynamicComponentType.SIDEBAR_RIGHT));
    if (operation) {
      component.title = `Operasyon Düzenle`;
      component.operationID = operation.id;
      component.action = WorkOrderOperationComponent.EDIT_OPERATION;
      component.onOperationSaved = (response) => {
        console.log('onoperationsave');
        this.eloquentManager.reload(operation);
      };
    }
  };

  openQualityControlConfig = (order) => {
    const component: QualityControlConfigComponent = (<QualityControlConfigComponent>this.dynamicComponent.create(QualityControlConfigComponent, DynamicComponentType.SIDEBAR_RIGHT));
    component.order = order;
  };

  setOperationStatus = (operation: WorkOrderOperation, status: WorkOrderOperationStatus) => {
    operation.isStatusLoading = true;
    this.eloquentManager.request('POST', this.config.get('apiBaseUrl') + operation.getApiPrefix() + '/' + operation.id + '/set-status/' + status.id).subscribe(response => {
      if (response) {
        operation.work_order_operation_status = status;
      } else {
        /*
         TODO uyarı
         */
      }
      operation.isStatusLoading = false;
    });
  };


  deleteWorkOrder(order: WorkOrder): void {
    $(this.confirmModal.nativeElement)
      .modal({
        closable: false,
        onDeny: () => {
          return true;
        },
        onApprove: () => {
          this.eloquentManager.delete(order).subscribe(response => {
            if (response.ok) {
              // this.notificationService.success('Başarılı', `${attachment.filename} dosyası başarıyla silindi`);
              this.workOrderList.filter((item) => {
                return item.id !== order.id;
              });
            } else {
              // this.notificationService.error('Hata Oluştu', `${attachment.filename} dosyası silinirken bir hata oluştu`);
            }
          });
        }
      })
      .modal('show');
  }


  deleteWorkOrderOperation(order: WorkOrder, operation: WorkOrderOperation): void {
    $(this.confirmModal.nativeElement)
      .modal({
        closable: false,
        onDeny: () => {
          return true;
        },
        onApprove: () => {
          this.eloquentManager.delete(operation).subscribe(response => {
            console.log(response);
            if (response) {
              // this.notificationService.success('Başarılı', `${attachment.filename} dosyası başarıyla silindi`);
              order.work_order_operations = order.work_order_operations.filter((item) => {
                return item.id !== operation.id;
              });
            } else {
              // this.notificationService.error('Hata Oluştu', `${attachment.filename} dosyası silinirken bir hata oluştu`);
            }
          });
        }
      })
      .modal('show');
  }

  recalculate(operation) {
    this.eloquentManager.recalculateCompletion(operation);
  }
}
