import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '../shared/services/authentication/authentication.service';

@Component({
    selector: 'app-base-component',
    template: ''
})
export class BaseComponent implements OnInit, OnDestroy {

    private authLoggedInSubscripton: Subscription;

    constructor(protected router: Router,
                protected authenticationService: AuthenticationService) {
    }

    ngOnInit() {

        /*this.router.events.filter(event => event instanceof NavigationStart).first().subscribe(event => {
            console.log("BaseComponent checklogin");
            this.auth.checkLogin();
        });*/


        /*this.authLoggedInSubscripton = this.auth.isLoggedIn.subscribe((isLoggedIn) => {
            console.log("BaseComponent isLoggedIn: " + isLoggedIn);
            if (isLoggedIn) {
                if (this.router.url === "/login") {
                    this.router.navigateByUrl("/home");
                }
            } else {
                if (this.router.url !== "/login") {
                    this.router.navigateByUrl("/login");
                }
            }
        });*/
    }

    ngOnDestroy(): void {
        // this.authLoggedInSubscripton.unsubscribe();
    }


}
