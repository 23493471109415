import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DirectivesModule} from '../directives/directives.module';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
// import {fuiModule} from 'ng2-semantic-ui';
import {PipesModule} from '../pipes/pipes.module';
// import {TextMaskModule} from 'angular2-text-mask';
import {DragulaModule} from 'ng2-dragula';
import {FomanticUIModule} from '../../../../libs/ngx-fomantic-ui/src';

// import {MomentModule} from 'angular2-moment';

@NgModule({
  imports: [],
  declarations: [],
  exports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DirectivesModule,
    PipesModule,
    // fuiModule,
    FomanticUIModule,
    DragulaModule,
    // TextMaskModule,
    // MomentModule
  ]
})
export class SharedImportsModule {
}
