import {Uploadable} from './uploadable.model';
import {Attachment} from './attachment.model';
import {notEmpty} from '../utils/utils';

export class Attachable {

    public attachments: Array<Attachment> = [];


    public attach = (attachment: Attachment) => {
        this.attachments.push(attachment);
    };

    public detach = (attachment: Attachment) => {
        console.log('attachable detach');
        console.log(attachment);
        console.log(this.attachments);
        console.log(this.attachments);
        this.attachments = this.attachments.filter(item => {
            console.log(item.id, attachment.id);
            return item.id !== attachment.id;
        });
        console.log(this.attachments);
    };

    public uploadableToAttachment = (uploadable: Uploadable) => {
        const attachment = Object.assign(uploadable.attachment);
        console.log(this.attachments);
        this.attachments.unshift(attachment);

    }

    public deserializeAttachments(input: any) {
        this.attachments = [];
        if (notEmpty(input['attachments'])) {
            for (let i = 0; i < input['attachments'].length; i++) {
                this.attachments.push(new Attachment().deserialize(input['attachments'][i]));
            }
        }
    }


}
