import {Component, OnInit} from '@angular/core';
import {EloquentManagerService} from '../../../shared/eloquent/providers/eloquent-manager.provider';
import {LayoutService} from '../../../shared/services/layout/layout.service';
import {User} from '../../../shared/eloquent/models/user.model';
import {UserRole} from '../../../shared/eloquent/models/user-role.model';
import {ConfigService} from '../../../shared/services/config/config.service';

declare const $: any;

@Component({
    selector: 'app-user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

    private pageTitle = 'Kullanıcı Yönetimi';

    userList: Array<User> = [];
    userRoleList: Array<UserRole> = [];
    editingUser: User = new User();

    constructor(private eloquentManager: EloquentManagerService,
                private layoutService: LayoutService,
                private configService: ConfigService) {
        this.layoutService.setTitle(this.pageTitle);
    }

    ngOnInit() {
        this.getUsers();
        this.getUserRoles();
    }

    getUsers = () => {
        this.eloquentManager.list(User).subscribe((response) => {
            if (response) {
                this.userList = response;
            }
        });
    };

    getUserRoles = () => {
        this.eloquentManager.list(UserRole).subscribe((response) => {
            if (response) {
                this.userRoleList = response;
                console.log(this.userRoleList);
            }
        });
    };


    userHasRole = (user: User, role: UserRole) => {

        for (let i = 0; i < user.roles.length; i++) {
            if (user.roles[i].slug === role.slug) {
                return true;
            }
        }
        return false;
    };


    toggleUserRole = (event, user, role) => {
        if (event.target.checked) {
            this.eloquentManager.request('GET', this.configService.get('apiBaseUrl') + user.getApiPrefix() + '/' + user.id + '/attach-role/' + role.id).subscribe((response) => {
                if (response) {
                    this.attachRole(user, role);
                }
            });
        } else {
            this.eloquentManager.request('GET', this.configService.get('apiBaseUrl') + user.getApiPrefix() + '/' + user.id + '/detach-role/' + role.id).subscribe((response) => {
                if (response) {
                    this.detachRole(user, role);
                }
            });
        }
    };


    attachRole = (user, role) => {
        user.roles.push(role);
        console.log(user.roles);
    };

    detachRole = (user, role) => {
        user.roles = user.roles.filter(item => {
            return item.slug !== role.slug;
        });
        console.log(user.roles);
    };


    openUserModal = (user?) => {
        if (user) {
            this.editingUser = user;
        } else {
            this.editingUser = new User();
        }

        $('#userModal').modal({
            closable: false
        }).modal('show');
    };

    saveUser = () => {
        if (this.editingUser.id) {
            this.eloquentManager.update(this.editingUser).subscribe((response) => {
                if (response) {
                    $('#userModal').modal('hide');
                }
            });
        } else {
            this.eloquentManager.save(this.editingUser).subscribe((response) => {
                console.log(response);
                if (response) {
                    this.userList.push(response);
                    $('#userModal').modal('hide');
                }
            });
        }
    };

}
