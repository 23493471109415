import {ILocaleValues} from './interfaces/values';

const enGB: ILocaleValues = {
  datepicker: {
    months: [
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
    ],
    monthsShort: [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ],
    weekdays: [
      'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
    ],
    weekdaysShort: [
      'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'
    ],
    weekdaysNarrow: [
      'S', 'M', 'T', 'W', 'T', 'F', 'S'
    ],
    timesOfDay: [
      'a.m.', 'p.m.'
    ],
    timesOfDayUppercase: [
      'AM', 'PM'
    ],
    timesOfDayLowercase: [
      'am', 'pm'
    ],
    formats: {
      time: 'h:mm A',
      datetime: 'D MMMM YYYY h:mm A',
      date: 'D MMMM YYYY',
      month: 'MMMM YYYY',
      year: 'YYYY'
    },
    firstDayOfWeek: 1
  },
  search: {
    placeholder: 'Search...',
    noResults: {
      header: 'No Results',
      message: 'Your search returned no results.'
    }
  },
  select: {
    noResultsMessage: 'No results',
    single: {
      placeholder: 'Select one'
    },
    multi: {
      placeholder: 'Select...',
      maxSelectedMessage: 'Max #{max} selections',
      selectedMessage: '#{count} selections'
    }
  }
};

export default enGB;
