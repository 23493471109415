import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {WorkOrder} from '../../../shared/eloquent/models/work-order.model';
import {WorkOrderStatus} from '../../../shared/eloquent/models/work-order-status.model';
import {FormControl, ValidationErrors} from '@angular/forms';
import {Customer} from '../../../shared/eloquent/models/customer.model';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../shared/services/authentication/authentication.service';
import {ConfigService} from '../../../shared/services/config/config.service';
import {AuthHttpService} from '../../../shared/services/http/auth-http.service';
import {EloquentManagerService} from '../../../shared/eloquent/providers/eloquent-manager.provider';
import {LayoutService} from '../../../shared/services/layout/layout.service';
import {WorkOrderOperation} from '../../../shared/eloquent/models/work-order-operation.model';
import {ToastService} from '../../../shared/services/toast/toast.service';
import {
  DynamicComponentComponent,
  DynamicComponentType
} from '../../../shared/components/dynamic-component/dynamic-component.component';
import {ShipmentHistoryComponent} from '../../../shared/components/shipment-history/shipment-history.component';
import {WorkOrderOperationComponent} from '../work-order-operation/work-order-operation.component';
import {PurchaseOrderComponent} from '../../../shared/components/purchase-order/purchase-order.component';
import {UploaderComponent} from '../../../shared/components/uploader/uploader.component';
import {QualityControlConfigComponent} from '../../../shared/components/quality-control-config/quality-control-config.component';
import {User} from '../../../shared/eloquent/models/user.model';
import {LogHistoryComponent} from '../../../shared/components/log-history/log-history.component';
import {DragulaService} from 'ng2-dragula';

declare let $: any;

@Component({
  selector: 'app-work-order',
  templateUrl: 'work-order.component.html',
  styleUrls: ['work-order.component.scss']
})
export class WorkOrderComponent implements OnInit, AfterViewInit, OnDestroy {

  order: WorkOrder = new WorkOrder();
  isNewOrder = false;
  pageTitle: string;
  workOrderStatusList: Array<WorkOrderStatus> = [];
  customerAutoCompleteCtrl: FormControl;
  customerList: Array<Customer> = [];
  customerSearchSubscription: Subscription;
  customerNoResult = false;
  isCustomerChanging = false;
  newCustomer: Customer = new Customer();

  user: User = new User();
  isAdmin = false;
  userSubscription: Subscription;


  STATUS_NEW = WorkOrderStatus.NEW;
  STATUS_PLANNED = WorkOrderStatus.PLANNED;

  // readonly numberMask = createNumberMask({
  //         prefix: '',
  //         thousandsSeparatorSymbol: '.',
  //         allowDecimal: true,
  //         decimalSymbol: ','
  //     }
  // );

  @ViewChild('workOrderDynamicComponent', {static: true})
  dynamicComponent: DynamicComponentComponent;


  @ViewChild('confirmModal', {static: true})
  confirmModal: ElementRef;


  dragulaDropModelSubscription: Subscription;


  constructor(private router: Router,
              private auth: AuthenticationService,
              private config: ConfigService,
              private authHttp: AuthHttpService,
              private activatedRoute: ActivatedRoute,
              private eloquentManager: EloquentManagerService,
              private layoutService: LayoutService,
              private toastService: ToastService,
              private dragulaService: DragulaService) {


  }

  setTitle(title: string) {
    this.pageTitle = title;
    this.layoutService.setTitle(this.pageTitle);
  }

  ngOnInit() {
    this.getWorkOrderStatuses();
    this.getWorkOrderById();
    this.subscribeUser();

    this.setDisabledAttributes();
  }


  ngAfterViewInit(): void {
    // this.initUI();
  }


  ngOnDestroy(): void {
    this.unsubscribeUser();
    this.unsubscribeDragulaEvents();
  }

  subscribeUser = () => {
    this.userSubscription = this.auth.user.subscribe((value) => {
      this.user = value;
      this.isAdmin = this.auth.userHasRole([AuthenticationService.ROLES.ADMIN]);
      this.setDisabledAttributes();
    });

  };

  unsubscribeUser = () => {
    this.userSubscription.unsubscribe();
  };

  setDisabledAttributes = () => {
    console.log(this.order.id);
    console.log(this.isAdmin);
    if (this.isAdmin) {
      console.log('admin lan!');
      this.order.form_group.get('proforma_price').enable();
      this.order.form_group.get('proforma_id').enable();
    } else {
      if (this.order.id) {
        console.log('disabled');
        this.order.form_group.get('proforma_price').disable();
        this.order.form_group.get('proforma_id').disable();
      } else {
        console.log('enabled');
        this.order.form_group.get('proforma_price').enable();
        this.order.form_group.get('proforma_id').enable();
      }
    }

  };

  getWorkOrderById() {
    this.activatedRoute.params.subscribe(params => {
      const orderId = params['id'];

      if (orderId) {
        this.isNewOrder = false;
        this.eloquentManager.getById(WorkOrder, orderId).subscribe(response => {
          if (response) {
            this.order = response;
            console.log(this.order);
            this.order.form('customer').setValue(this.order.customer);
            this.setTitle('İş Emri Düzenle: #' + orderId);
            this.setDisabledAttributes();
            this.subscribeDragulaEvents();
          } else {
            /*
             TODO uyarı (BULUNAMADI)
             */
            this.router.navigate(['/work-order']);
          }
        });

      } else {
        this.isNewOrder = true;
        this.setTitle('Yeni İş Emri Girişi');
      }
    });
  }


  getWorkOrderStatuses() {
    this.eloquentManager.list(WorkOrderStatus).subscribe(response => {
      if (response) {
        this.workOrderStatusList = response;
      } else {
        /*
         TODO uyarı
         */
      }
    });
  }

  searchCustomers = (query) => {
    this.customerNoResult = false;
    return new Promise((resolve, reject) => {
      this.eloquentManager.request('POST', this.config.get('apiBaseUrl') + 'customers/get-all-for-autocomplete', {keyword: query})
        .subscribe((response: any) => {
          if (response) {
            const modelList = [];
            const responseArray = response;
            for (let i = 0; i < responseArray.length; i++) {
              modelList.push(new Customer().deserialize(responseArray[i]));
            }
            if (modelList.length === 0) {
              this.customerNoResult = true;
            }
            resolve(modelList);
          }
          resolve([]);
        });
    });
  };

  selectCustomer = (customer) => {
    this.order.customer = customer;
    this.order.form('customer').setValue(customer);
    this.changeCustomer(false);
  };

  changeCustomer = (value) => {
    this.isCustomerChanging = value;
  };


  customerDisplayFn(customer): string {
    return customer ? customer.title : customer;
  }

  openCustomerModal = () => {
    this.newCustomer = new Customer();
    $('#addNewCustomerModal').modal({
      closable: false,
      onApprove: this.saveNewCustomer

    }).modal('show');
  };

  saveNewCustomer = () => {
    this.newCustomer.revalidate();
    if (this.newCustomer.form_group.invalid) {
      return false;
    }
    this.eloquentManager.save(this.newCustomer).subscribe((response) => {
      if (response) {
        this.selectCustomer(response);
        this.customerNoResult = false;
        $('#addNewCustomerModal').modal('hide');
      }
    });
  };

  logCurrentOrder() {
    console.log(this.order);
  }

  saveOrUpdateOrder = () => {
    this.order.revalidate();

    if (this.order.form_group.invalid) {

      Object.keys(this.order.form_group.controls).forEach(key => {

        const controlErrors: ValidationErrors = this.order.form_group.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
        }
      });


      return;
    }


    if (this.order.id) {
      this.eloquentManager.update(this.order).subscribe(response => {
        if (response) {
        }
      });
    } else {
      this.eloquentManager.save(this.order).subscribe(response => {
        if (response) {
          this.router.navigate(['/work-order', response.id]);
        }
      });
    }

  };


  openUploader = () => {
    const component: UploaderComponent = (<UploaderComponent>this.dynamicComponent.create(UploaderComponent, DynamicComponentType.SIDEBAR_RIGHT));
    component.title = `Ekleri Yönet | İş Emri: #${this.order.id}`;
    component.attachable = this.order;
  };

  openOperation = (operation: WorkOrderOperation = null) => {
    const component: WorkOrderOperationComponent = (<WorkOrderOperationComponent>this.dynamicComponent.create(WorkOrderOperationComponent, DynamicComponentType.SIDEBAR_RIGHT));
    if (operation) {
      component.title = `Operasyon Düzenle`;
      component.operationID = operation.id;
      component.action = WorkOrderOperationComponent.EDIT_OPERATION;
      component.onOperationSaved = (response) => {
        console.log('onoperationsave');
        this.eloquentManager.reload(operation);
      };
    } else {
      component.title = 'Operasyon Ekle';
      component.workOrder = this.order;
      component.action = WorkOrderOperationComponent.ADD_OPERATION;
    }


  };

  openLogHistory = (operation: WorkOrderOperation, order: WorkOrder, addNew = false) => {
    console.log(`[this.dynamicComponent] `, this.dynamicComponent);
    const component: LogHistoryComponent = (<LogHistoryComponent>this.dynamicComponent.create(LogHistoryComponent, DynamicComponentType.SIDEBAR_RIGHT));
    component.operation = operation;
    component.title = addNew ? `Günlük Kaydı Ekle | #${operation.work_order_id} - ${operation.work_order_operation_type.name}` : `Günlük Kayıt Geçmişi | #${operation.work_order_id} - ${operation.work_order_operation_type.name}`;
    component.action = addNew ? LogHistoryComponent.ADD_LOG_HISTORY : LogHistoryComponent.LIST_LOG_HISTORY;
    component.onLogSaved = () => {
      this.eloquentManager.recalculateCompletion(operation);
      this.eloquentManager.recalculateCompletion(order);
    };
  };

  openShipmentHistory = (shippable: any, addNew = false) => {
    const component: ShipmentHistoryComponent = (<ShipmentHistoryComponent>this.dynamicComponent.create(ShipmentHistoryComponent, DynamicComponentType.SIDEBAR_RIGHT));
    component.title = addNew ? 'Sevkiyat Ekle' : `Sevkiyat Geçmişi`;
    component.shippable = shippable;
    component.action = addNew ? ShipmentHistoryComponent.ADD_SHIPMENT_HISTORY : ShipmentHistoryComponent.LIST_SHIPMENT_HISTORY;
    component.onShipmentSaved = () => {
      this.eloquentManager.reload(shippable);
    };
  };

  openPurchaseOrder = (operation: WorkOrderOperation) => {
    const component: PurchaseOrderComponent = (<PurchaseOrderComponent>this.dynamicComponent.create(PurchaseOrderComponent, DynamicComponentType.SIDEBAR_RIGHT));
    component.title = 'Operasyon Siparişi';
    component.purchaseOrderID = operation.purchase_order_id;
    component.action = PurchaseOrderComponent.EDIT_PURCHASE_ORDER;

    component.onPurchaseOrderSaved = () => {
      console.log('onPurchaseOrderSaved');
      this.eloquentManager.reload(operation);
    };
  };

  openQualityControlConfig = () => {
    const component: QualityControlConfigComponent = (<QualityControlConfigComponent>this.dynamicComponent.create(QualityControlConfigComponent, DynamicComponentType.SIDEBAR_RIGHT));
    component.order = this.order;
  };

  setWorkOrderStatus = (status) => {
    const processingToast = this.toastService.processing();
    this.eloquentManager.request('POST', this.config.get('apiBaseUrl') + this.order.getApiPrefix() + '/' + this.order.id + '/set-status-by-slug', {slug: status})
      .subscribe((response: any) => {
        if (response) {
          this.toastService.processed(processingToast);
          this.order.work_order_status.deserialize(response);
        } else {

          this.toastService.saveFailed(processingToast);
        }
      });
  };


  subscribeDragulaEvents = () => {
    this.dragulaService.setOptions('operation-bag', {
      revertOnSpill: true,
      moves: function (el, container, handle) {
        return handle.className.includes('operation-drag-handle');
      }
    });

    this.dragulaDropModelSubscription = this.dragulaService.dropModel.subscribe((value) => {
      console.log(this.order.work_order_operations);
      this.seqWOrkOrderOperations();
    });
  };

  unsubscribeDragulaEvents = () => {
    if (this.dragulaDropModelSubscription) {
      this.dragulaDropModelSubscription.unsubscribe();

      const bag: any = this.dragulaService.find('operation-bag');
      if (bag !== undefined) {
        this.dragulaService.destroy('operation-bag');
      }

    }
  };


  seqWOrkOrderOperations = () => {
    const operationList = [];

    for (let i = 0; i < this.order.work_order_operations.length; i++) {
      operationList.push({id: this.order.work_order_operations[i].id, seq_num: i});
    }

    const seqToast = this.toastService.info('Yeniden Sıralanıyor', 'Lütfen Bekleyiniz');
    this.eloquentManager.request('POST', this.config.get('apiBaseUrl') + 'work-order-operations/reorder', operationList).subscribe((response) => {

      this.toastService.success('Başarılı', 'Yeniden sıralama başarıyla yapıldı', seqToast);

    });
  };


  deleteWorkOrderOperation(order: WorkOrder, operation: WorkOrderOperation): void {
    $(this.confirmModal.nativeElement)
      .modal({
        closable: false,
        onDeny: () => {
          return true;
        },
        onApprove: () => {
          this.eloquentManager.delete(operation).subscribe(response => {
            if (response) {
              // this.notificationService.success('Başarılı', `${attachment.filename} dosyası başarıyla silindi`);
              order.work_order_operations = order.work_order_operations.filter((item) => {
                return item.id !== operation.id;
              });
            } else {
              // this.notificationService.error('Hata Oluştu', `${attachment.filename} dosyası silinirken bir hata oluştu`);
            }
          });
        }
      })
      .modal('show');
  }

}




