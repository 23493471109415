<div class="ui container padding-bottom-75 content-background very padded basic segment">
    <table class="ui very basic celled table fluid">
        <thead>
        <tr>
            <th>Kullanıcı</th>
            <th>Kullanıcı Adı</th>
            <th>E-posta</th>
            <th>Roller</th>
            <th>İşlemler</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let user of userList">
            <td>
                {{user.name}}
            </td>
            <td>
                {{user.username}}
            </td>
            <td>
                {{user.email}}
            </td>
            <td>
                <ng-template let-popup #popupTemplate>
                    <div class="header">Roller</div>
                    <div class="content">
                        <div class="ui form">
                            <div class="grouped fields">
                                <div class="field" *ngFor="let role of userRoleList">
                                    <div class="ui checkbox">
                                        <input
                                                type="checkbox"
                                                [checked]="userHasRole(user, role)"
                                                (change)="toggleUserRole($event, user, role)"
                                        >
                                        <label>{{role.name}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </ng-template>
                <button class="ui icon button basic"
                        fuiPopup
                        [popupTemplate]="popupTemplate"
                        popupTrigger="outsideClick"
                        popupPlacement="bottom">
                    Roller
                </button>
            </td>
            <td>
                <button class="ui button yellow small" (click)="openUserModal(user)">Düzenle</button>
            </td>
        </tr>
        </tbody>
    </table>
</div>


<button class="circular ui icon button fab bottom right red huge"
        fuiPopup
        popupText="Yeni Kullanıcı Ekle"
        popupPlacement="left"
        [popupInverted]="true"
        (click)="openUserModal()"
>
    <i class="icon plus"></i>
</button>


<!--MODALS-->
<div class="ui small modal" id="userModal">
    <div class="header">
        {{editingUser?.id ? 'Kullanıcıyı Düzenle' : 'Yeni Kullanıcı Ekle'}}
    </div>
    <div class="scrolling content">
        <form class="ui form" [formGroup]="editingUser.form_group">
            <div class="field"
                 [ngClass]="{'error': editingUser.form('name').hasError('required') && (editingUser.form('name').dirty || editingUser.form('name').touched)}"
            >
                <label>Ad - Soyad</label>
                <input
                        type="text"
                        placeholder="Ad - Soyad"
                        formControlName="name"
                        [(ngModel)]="editingUser.name"
                >
                <div class="ui pointing red basic label"
                     *ngIf="editingUser.form('name').hasError('required') && (editingUser.form('name').dirty || editingUser.form('name').touched)">
                    Boş Bırakılamaz
                </div>
            </div>

            <div class="field"
                 [ngClass]="{'error': editingUser.form('username').hasError('required') && (editingUser.form('username').dirty || editingUser.form('username').touched)}"
            >
                <label>Kullanıcı Adı</label>
                <input
                        type="text"
                        placeholder="Kullanıcı Adı"
                        formControlName="username"
                        [(ngModel)]="editingUser.username"
                >
                <div class="ui pointing red basic label"
                     *ngIf="editingUser.form('username').hasError('required') && (editingUser.form('username').dirty || editingUser.form('username').touched)">
                    Boş Bırakılamaz
                </div>
            </div>


            <div class="field"
                 [ngClass]="{'error': editingUser.form('email').hasError('required') && (editingUser.form('email').dirty || editingUser.form('email').touched)}"
            >
                <label>E-posta</label>
                <input
                        type="text"
                        placeholder="E-posta"
                        formControlName="email"
                        [(ngModel)]="editingUser.email"
                >
                <div class="ui pointing red basic label"
                     *ngIf="editingUser.form('email').hasError('required') && (editingUser.form('email').dirty || editingUser.form('email').touched)">
                    Boş Bırakılamaz
                </div>
            </div>

        </form>
    </div>
    <div class="actions">
        <div class="ui red cancel inverted button">
            <i class="remove icon"></i>
            Vazgeç
        </div>
        <div class="ui green inverted button" (click)="saveUser()">
            <i class="checkmark icon"></i>
            Kaydet
        </div>
    </div>
</div>
