<div class="ui grid fluid">
    <div class="four wide column" *ngFor="let station of workStationList">

        <div class="work-station-container">
            <div class="ui top attached segment"
                 [ngClass]="{
                 'green':  station.open_log,
                 'inverted':  station.open_log,
                 'no-work':  !station.open_log
                 }"
                 style="border: 1px solid #D4D4D5 !important;"

            >
                {{station.code}} | {{station.name}}
            </div>
            <div class="ui segment fluid attached">

                <div class="text-center work-details" *ngIf="!station.open_log">
                    <h5 class="ui icon header grey margin-top-25 margin-bottom-0 padding-0">
                        <i class="warning circle icon"></i>
                        <div class="content">
                            Mevcut İş Yok
                        </div>
                    </h5>
                </div>


                <div class="ui grid work-details" *ngIf="station.open_log">
                    <div class="eight wide column">
                        <div class="order-id">
                            #{{station.open_log.work_order_operation.work_order.id}}
                        </div>
                        <div class="customer-title margin-top-5 truncate">
                            {{station.open_log.work_order_operation.work_order.customer.title}}
                        </div>
                        <div class="part-name margin-top-5">
                            {{station.open_log.work_order_operation.work_order.part_name}}
                        </div>
                    </div>
                    <div class="eight wide column text-center">
                        <div class="ui grid">
                            <div class="eight wide column">
                                <div class="operation-detail-label">Operatör
                                </div>
                                <div class="stats">
                                    {{station.open_log.logged_by_display_name}}
                                </div>
                            </div>
                            <div class="eight wide column">
                                <div class="operation-detail-label">Toplam
                                </div>
                                <div class="stats">
                                    {{station.open_log.work_order_operation.total_num_of_parts}} Adet
                                </div>
                            </div>
                            <div class="eight wide column">
                                <div class="operation-detail-label">Tamamlanan
                                </div>
                                <div class="stats completed">
                                    <i class="check small icon margin-right-5"></i>{{station.open_log.work_order_operation.completed_num_of_parts}}
                                </div>
                            </div>
                            <div class="eight wide column">
                                <div class="operation-detail-label">Fire Verilen
                                </div>
                                <div class="stats wasted">
                                    <i class="remove small icon margin-right-5"></i>{{station.open_log.work_order_operation.wasted_num_of_parts}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>

            </div>
            <fui-progress class="bottom attached indicating"
                          [value]="station.open_log.work_order_operation.completion_percentage"
                          *ngIf="station.open_log">
            </fui-progress>
        </div>
    </div>
</div>
