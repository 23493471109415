import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MeComponent} from './me.component';
import {SharedModule} from '../../../shared/modules/shared.module';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        MeComponent
    ],
    exports: [
        MeComponent
    ]
})
export class MeModule {
}
