import {AfterViewChecked, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthenticationService} from '../../../shared/services/authentication/authentication.service';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {User} from '../../../shared/eloquent/models/user.model';
import {LayoutService} from '../../../shared/services/layout/layout.service';
import {WebSocketService} from '../../../shared/services/web-socket/web-socket.service';

declare let $: any;

@Component({
  selector: 'app-authenticated-wrapper',
  templateUrl: './authenticated-wrapper.component.html',
  styleUrls: ['./authenticated-wrapper.component.scss']
})
export class AuthenticatedWrapperComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('sidebar', {static: false})
  sidebar: ElementRef;

  user: User;
  pageTitle = 'İmalat Planlama';

  ROLES = AuthenticationService.ROLES;

  private userSubscripton: Subscription;
  private routerSubscripton: Subscription;
  private pageTitleSubscripton: Subscription;

  constructor(private authenticationService: AuthenticationService,
              private router: Router,
              private layoutService: LayoutService,
              private socketService: WebSocketService) {


  }

  ngOnInit(): void {
    this.subscribeRouterEvents();
    this.subscribeUser();
    setTimeout(() => this.subscribePageTitle(), 0)

  }

  ngAfterViewInit(): void {
    $(this.sidebar.nativeElement).sidebar({
      transition: 'overlay'
    });
  }


  ngOnDestroy(): void {
    this.unsubscribeRouterEvents();
    this.unsubscribeUser();
    this.unsubscribePageTitle();
  }

  toggleSidebar = () => {
    $(this.sidebar.nativeElement).sidebar('toggle');
  };

  public userHasRole(roles: Array<number>): boolean {
    return false;
    // return this.authenticationService.userHasRole(roles);
  }

  private subscribeRouterEvents() {
    this.routerSubscripton = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((val) => {
      $(this.sidebar.nativeElement).sidebar('hide');
      $('html, body').animate({scrollTop: 0}, 'slow');
    });
  }

  private subscribeUser() {
    this.userSubscripton = this.authenticationService.user.subscribe((user) => {
      this.user = user;
    });
  }

  private subscribePageTitle() {
    this.pageTitleSubscripton = this.layoutService.title.subscribe((title) => this.pageTitle = title);
  }


  private unsubscribeRouterEvents() {
    this.routerSubscripton.unsubscribe();
  }

  private unsubscribeUser() {
    this.userSubscripton.unsubscribe();
  }

  private unsubscribePageTitle() {

  }


}
