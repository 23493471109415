import {ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

declare const $: any;

@Component({
    selector: 'app-dynamic-component',
    templateUrl: './dynamic-component.component.html',
    styleUrls: ['./dynamic-component.component.scss'],
    animations: [
        trigger('sidebarAnimationState', [
            state('void', style({width: '0px'})),
            state('enter', style({width: '*'})),
            state('exit', style({width: '0px'})),
            transition('enter => exit', animate('300ms ease-in-out')),
            transition('void => enter', animate('300ms ease-in-out')),
            transition('exit => enter', animate('300ms ease-in-out')),
        ])
    ],
})
export class DynamicComponentComponent implements OnInit {

    COMPONENT_TYPES: typeof DynamicComponentType = DynamicComponentType;
    type: DynamicComponentType = null;

    @ViewChild('viewRefSidebarRight', {static: false, read: ViewContainerRef})
    viewRefSidebarRight: ViewContainerRef;

    @ViewChild('sidebarPageDimmer', {static: false})
    sidebarPageDimmer: ElementRef;

    sidebarAnimationState = 'void';

    @ViewChild('viewRefModal', {static: false, read: ViewContainerRef})
    viewRefModal: ViewContainerRef;

    @ViewChild('viewRefConfirmModal', {static: false, read: ViewContainerRef})
    viewRefConfirmModal: ViewContainerRef;

    @ViewChild('modal', {static: false}) modal: ElementRef;

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
                private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit() {
    }


    public create = (instaceClass: any, type: DynamicComponentType) => {
        this.type = type;
        this.changeDetector.detectChanges();

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(instaceClass);

        let component;
        switch (this.type) {
            case DynamicComponentType.SIDEBAR_RIGHT: {
                this.sidebarAnimationState = 'enter';
                $(this.sidebarPageDimmer.nativeElement).dimmer({
                    closable: false
                }).dimmer('show');
                component = this.viewRefSidebarRight.createComponent(componentFactory).instance;
                component.close = this.closeSidebar;
                break;
            }
            case DynamicComponentType.MODAL: {
                component = this.viewRefModal.createComponent(componentFactory).instance;
                setTimeout(() => {
                    this.openModal();
                }, 50);
                break;
            }
            case DynamicComponentType.CONFIRM: {
                component = this.viewRefConfirmModal.createComponent(componentFactory).instance;
                break;
            }
        }
        return component;

    };


    private openModal = () => {

        $(this.modal.nativeElement).modal({
            closable: false,
            inverted: true,
            onDeny: () => {
                this.clear();
            },
            onApprove: () => {
                this.clear();
            },
            onHidden: () => {
                this.clear();
            }
        }).modal('show');
    };

    private closeSidebar = () => {
        this.sidebarAnimationState = 'exit';
        $(this.sidebarPageDimmer.nativeElement).dimmer('hide');
        this.clear();
    };

    public clear = () => {
        switch (this.type) {
            case DynamicComponentType.SIDEBAR_RIGHT: {
                this.viewRefSidebarRight.clear();
                break;
            }
            case DynamicComponentType.MODAL: {
                this.viewRefModal.clear();
                break;
            }
        }
    };


    onSidebarAnimationEvent = (event) => {
        if (event.toState === 'exit') {
            this.closeSidebar();
        }
    };
}

export enum DynamicComponentType {
    SIDEBAR_RIGHT = 'sidebar-right',
    MODAL = 'modal',
    CONFIRM = 'confirm'
}
