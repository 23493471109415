import {Component, OnInit, Inject, OnDestroy, Renderer2, ViewChild, ElementRef, AfterViewInit, Input, Output} from '@angular/core';
import {Attachable} from '../../eloquent/models/attachable.model';
import {Attachment} from '../../eloquent/models/attachment.model';
import {EloquentManagerService} from '../../eloquent/providers/eloquent-manager.provider';
import {Uploadable} from '../../eloquent/models/uploadable.model';
import {ConfigService} from '../../services/config/config.service';
import {ToastService} from '../../services/toast/toast.service';
import {Customer} from '../../eloquent/models/customer.model';
import {WorkOrder} from '../../eloquent/models/work-order.model';
import {PurchaseOrder} from '../../eloquent/models/purchase-order.model';

declare var $: any;
declare var Dragster: any;
declare const PDFJS: any;

@Component({
    selector: 'app-uploader',
    templateUrl: 'uploader.component.html',
    styleUrls: ['uploader.component.scss'],

})
export class UploaderComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input('attachable')
    attachable: WorkOrder | PurchaseOrder = null;

    @Input('title')
    title = 'Ekleri Yönet';

    private attachmentList: Array<Attachment>;
    uploadableList: Array<Uploadable> = [];
    isAttachmentsLoading = true;

    isDragging = false;
    dragster: any;
    bodyDragster: any;

    selectedAttachments: Array<Attachment> = [];

    canUpload = true;

    @ViewChild('dropArea', {static: false})
    dropArea;

    @ViewChild('confirmModal', {static: false})
    confirmModal: ElementRef;


    @ViewChild('uploaderForm', {static: false})
    uploaderForm: ElementRef;


    previewURL = null;
    previewTitle = null;


    extensions: any[] = [
        {type: 'image/png', icon: 'file image outline'},
        {type: 'image/jpg', icon: 'file image outline'},
        {type: 'image/jpeg', icon: 'file image outline'},
        {type: 'image/gif', icon: 'file image outline'},
        {type: 'application/x-zip-compressed', icon: 'file archive outline'},
        {type: 'application/x-compressed', icon: 'file archive outline'},
        {type: 'application/zip', icon: 'file archive outline'},
        {type: 'multipart/x-zip', icon: 'file archive outline'},
        {type: 'application/x-rar-compressed', icon: 'file archive outline'},
        {type: 'application/pdf', icon: 'file pdf outline'},
        {type: 'application/msword', icon: 'file word outline'},
        {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', icon: 'file word outline'},
        {type: 'application/vnd.ms-excel', icon: 'file excel outline'},
        {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', icon: 'file excel outline'},
        {type: 'application/x-step', icon: 'file outline'},

    ];

    @Output('close') close = () => {
        return false;
    };


    constructor(private eloquentManager: EloquentManagerService,
                private renderer: Renderer2,
                private configService: ConfigService,
                private toastService: ToastService) {

    }


    ngOnInit() {
        this.getAttachments();
    }


    ngAfterViewInit(): void {
        const dropAreaNative = this.dropArea.nativeElement;


        this.bodyDragster = new Dragster(document.body);

        this.renderer.listen(document.body, 'dragster:enter', (evt) => {
            this.isDragging = true;
        });


        this.renderer.listen(document.body, 'drop', (evt) => {
            this.isDragging = false;
        });


        this.renderer.listen(document.body, 'dragster:leave', (evt) => {
            this.isDragging = false;
        });
    }

    ngOnDestroy(): void {
        // this.dragster.removeListeners();
        this.bodyDragster.removeListeners();
    }

    getAttachments = () => {
        this.eloquentManager.request('GET', this.configService.get('apiBaseUrl') + this.attachable.getApiPrefix() + '/' + this.attachable.id + '/get-attachments').subscribe((response) => {
            if (response) {
                const modelList = [];
                const responseArray = response;
                for (let i = 0; i < responseArray.length; i++) {
                    modelList.push(new Attachment().deserialize(responseArray[i]));
                }
                this.attachmentList = modelList;
            }

            this.isAttachmentsLoading = false;
        });
    };

    onFileInputChange(e: any) {
        this.upload(e.target.files);
        // this.uploaderForm.nativeElement.reset();
    }

    onDragOver(e: any) {
        e.stopPropagation();
        e.preventDefault();
    }

    onDrop(e: any) {
        e.stopPropagation();
        e.preventDefault();
        this.isDragging = false;
        if (this.canUpload) {
            this.upload(e.dataTransfer.files);
        } else {
            this.toastService.error('Yetkiniz Yok', 'Dosya Yükleme Yetkiniz Bulunmamaktadır.');
        }
    }

    upload(files: Array<File>) {

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (!this.validateFile(file)) {
                // this.notificationService.error('Desteklenmeyen Dosya Türü', `${file.name} dosyası desteklenmiyor`);
                this.toastService.error('Desteklenmeyen Dosya Biçimi', `${file.name} dosyasının biçimi desteklenemiyor`);
                return;
            }

            this.uploadableList.push(new Uploadable(this.eloquentManager, this.attachable, file, this));
        }
    }


    validateFile(file: File): boolean {
        return this.extensions.some((item) => {
            return item.type === file.type;
        });
    }


    detach = (attachment: Attachment): void => {

        $(this.confirmModal.nativeElement)
            .modal({
                closable: false,
                onDeny: () => {
                    return true;
                },
                onApprove: () => {

                    this.eloquentManager.detach(attachment).subscribe(response => {
                        if (response.ok) {
                            // this.notificationService.success('Başarılı', `${attachment.filename} dosyası başarıyla silindi`);
                            this.attachable.detach(attachment);
                        } else {
                            // this.notificationService.error('Hata Oluştu', `${attachment.filename} dosyası silinirken bir hata oluştu`);
                        }
                    });
                }
            })
            .modal('show');
    };


    onUploadComplete = (uploadable: Uploadable) => {
        this.attachable.uploadableToAttachment(uploadable);
        this.uploadableList = this.uploadableList.filter((item) => {
            return item !== uploadable;
        });
        this.attachmentList = this.attachable.attachments;
    };

    onUploadAborted = (uploadable: Uploadable) => {
        this.uploadableList = this.uploadableList.filter((item) => {
            return item !== uploadable;
        });
    };


    getIconClass(extension: string) {
        let iconClass = '';
        const item = this.extensions.filter(ext => {
            return ext.type === extension;
        });

        if (item.length === 0) {
            iconClass += 'file outline';
        } else {
            iconClass += item[0].icon;
        }


        return iconClass;
    }

    preview = (event, attachment: Attachment) => {

        if (attachment.extension === 'pdf') {
            event.preventDefault();
            event.stopPropagation();
            this.previewURL = attachment.full_url;
            this.previewTitle = attachment.filename;
            $('#previewContainer').show();
        }

    };

    closePreview = () => {
        $('#previewContainer').hide();
        this.previewURL = null;
        this.previewTitle = null;
    };


}

