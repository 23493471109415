<div class="dynamic-sidebar"
     [@sidebarAnimationState]="sidebarAnimationState"
     (@sidebarAnimationState.done)="onSidebarAnimationEvent($event)"
>
    <div class="dynamic-sidebar-close-button clearfix">
        <button class="circular ui icon margin-right-20 margin-top-20 margin-bottom-15  button basic black"
                (click)="sidebarAnimationState = 'exit'">
            <i class="icon close"></i>
        </button>
    </div>
    <div class="dynamic-sidebar-content">
        <div #viewRefSidebarRight
             *ngIf="type == COMPONENT_TYPES.SIDEBAR_RIGHT"

        >
        </div>
    </div>
    <div class="ui page dimmer inverted" #sidebarPageDimmer>
    </div>
</div>

<div class="ui modal" #modal *ngIf="type == COMPONENT_TYPES.MODAL">
    <i class="close icon black"></i>
    <div class="scrolling content">
        <div #viewRefModal></div>
    </div>
</div>


<div #confirmModal #viewRefConfirmModal *ngIf="type == COMPONENT_TYPES.CONFIRM"></div>