import {BaseModel} from './base.model';
import {EloquentSerializable} from '../interfaces/interfaces';
import {Personnel} from './personnel.model';
import {PurchaseOrderType} from './purchase-order-type.model';
import {notEmpty} from '../utils/utils';


export class WorkOrderOperationType extends BaseModel implements EloquentSerializable<WorkOrderOperationType> {


    public static readonly API_PREFIX: string = 'work-order-operation-types';
    public id: number = null;
    public name = '';
    public description = '';
    public purchase_order_type = '';
    public purchase_order_types: Array<PurchaseOrderType> = [];
    public is_internal = true;

    public validation: Array<any>;

    public getApiPrefix(): string {
        return WorkOrderOperationType.API_PREFIX;
    }


    public deserialize(input: Object): WorkOrderOperationType {
        this.id = input['id'];
        this.name = input['name'];
        this.description = input['description'];
        this.purchase_order_type = input['purchase_order_type'];
        this.is_internal = input['is_internal'];

        if (notEmpty(input['purchase_order_types'])) {
            for (let i = 0; i < input['purchase_order_types'].length; i++) {
                this.purchase_order_types.push(new PurchaseOrderType().deserialize(input['purchase_order_types'][i]));
            }
        }

        return this;
    }


}
