import {BaseModel} from './base.model';
import {EloquentSerializable} from '../interfaces/interfaces';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HolosenValidators} from '../validators/validators';

export class Log extends BaseModel implements EloquentSerializable<Log> {


    public static readonly API_PREFIX: string = 'logs';


    public id = null;
    public created_by = null;
    public created_at: Date = new Date();
    public completed_num_of_parts = null;
    public wasted_num_of_parts = null;
    public additional_notes = '';
    public logged_by = null;


    form_group = new FormGroup({
        completed_num_of_parts: new FormControl(
            this.completed_num_of_parts,
            Validators.compose([
                HolosenValidators.requiredNumber
            ])
        ),
        wasted_num_of_parts: new FormControl(
            this.wasted_num_of_parts,
            Validators.compose([
                HolosenValidators.requiredNumber,
            ])
        ),
        additional_notes: new FormControl(
            this.additional_notes,
            Validators.compose([
                HolosenValidators.requiredIfNumberGreaterThan('wasted_num_of_parts', 0, Validators.required)
            ])
        )
    });

    public getApiPrefix(): string {
        return Log.API_PREFIX;
    }

    deserialize(input: JSON): Log {
        this.id = input['id'];
        this.created_by = input['created_by'];
        this.created_at = new Date(input['created_at']);
        this.completed_num_of_parts = input['completed_num_of_parts'];
        this.wasted_num_of_parts = input['wasted_num_of_parts'];
        this.additional_notes = input['additional_notes'];
        this.logged_by = input['logged_by'];

        return this;
    }


}
