import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild} from '@angular/router';
import {AuthenticationService} from '../services/authentication/authentication.service';
import {map, catchError} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable()
export class Gatekeeper implements CanActivate, CanActivateChild {

    constructor(private authenticationService: AuthenticationService,
                private router: Router) {
    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise((resolve, reject) => {
            this.authenticationService.checkLogin().subscribe((response) => {
                if (response.ok) {


                    if (state.url === '/') {
                        if (this.authenticationService.userHasRole([AuthenticationService.ROLES.OPERATOR])) {
                            this.router.navigate(['/operator']);
                        } else if (this.authenticationService.userHasRole([AuthenticationService.ROLES.ADMIN, AuthenticationService.ROLES.PLANNER])) {
                            this.router.navigate(['/planning']);

                        }
                    }
                    resolve(true);
                } else {
                    this.router.navigate(['/login']);
                    resolve(false);
                }
            });
        });

    }


    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise((resolve, reject) => {

            this.authenticationService.checkLogin().subscribe((response) => {
                    if (response.ok) {
                        if (this.authenticationService.getUser().is_first_login && state.url !== '/me') {
                            this.router.navigate(['/me']);
                        }


                        const roles = route.data['roles'];


                        if (roles instanceof Array) {
                            resolve(this.authenticationService.userHasRole(roles));
                            return;
                        }

                        resolve(true);
                    } else {
                        this.router.navigate(['/login']);
                        resolve(false);
                    }
                }
            );
        })
            ;
    }
}
