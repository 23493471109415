<div class="toast-wrapper"
     [ngClass]="styleClass"
     [@toastState]="state"
     (@toastState.done)="onAnimationEnd($event)"
     (click)="close()">
    <div class="ui segment basic">
        <div class="ui grid ">
            <div class="row">
                <div class="twelve wide column">
                    <div class="toast-message">
                        <div class="toast-title">{{toast.title}}</div>
                        <div class="toast-content">{{toast.content}}</div>
                    </div>
                </div>
                <div class="four wide column">
                    <div class="toast-icon">
                        <i class="big icon" [ngClass]="styleIcon"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>