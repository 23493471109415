import {NgModule} from '@angular/core';
import {LogHistoryComponent} from './log-history.component';
import {SharedImportsModule} from '../../modules/shared-imports.module';

@NgModule({
    imports: [
        SharedImportsModule
    ],
    declarations: [
        LogHistoryComponent
    ],
    exports: [
        LogHistoryComponent
    ]
})
export class LogHistoryModule {
}
