import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Pagination} from '../../eloquent/models/query/pagination.model';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

    @Input('pagination') pagination: Pagination;

    @Output('pageChange') pageChangeEvent = new EventEmitter<any>();

    _isOnLastPage = true;
    _isOnFirstPage = true;

    constructor() {
    }

    ngOnInit() {
        this.refrshArrows();

    }

    previousPage() {
        if (this.isOnFirstPage()) {
            return;
        }

        this.pagination.pageIndex--;
        this.onPageChange();
        this.refrshArrows();
    }

    nextPage() {
        if (this.isOnLastPage()) {
            return;
        }

        this.pagination.pageIndex++;
        this.onPageChange();
        this.refrshArrows();
    }


    calculateIndex() {
        const startIndex = this.pagination.length === 0 ? 0 : ((this.pagination.pageIndex * this.pagination.pageSize) + 1);
        let endIndex = (this.pagination.pageIndex + 1) * this.pagination.pageSize;

        if (endIndex > this.pagination.length) {
            endIndex = this.pagination.length;
        }

        return startIndex + ' - ' + endIndex + ' / ' + this.pagination.length;
    }

    setPageSize(pageSize) {
        this.pagination.pageIndex = 0;
        this.pagination.pageSize = pageSize;
        this.onPageChange();
    }

    onPageChange() {
        this.pageChangeEvent.emit();
    }

    calculateLastPage() {
        console.log('calculateLastPage', (Math.ceil((this.pagination.length / this.pagination.pageSize))));
        return Math.ceil((this.pagination.length / this.pagination.pageSize));
    }

    refrshArrows = () => {
        this.isOnFirstPage();
        this.isOnLastPage();
    };

    isOnFirstPage() {
        this._isOnFirstPage = this.pagination.pageIndex === 0;
        return this._isOnFirstPage;
    }

    isOnLastPage() {

        if (this.pagination.length === 0) {
            this._isOnLastPage = true;
        } else if (this.pagination.pageIndex === (this.calculateLastPage() - 1)) {
            this._isOnLastPage = true;
        } else {
            this._isOnLastPage = false;
        }
        return this._isOnLastPage;
    }


}
