<app-dynamic-component #dynamicComponent></app-dynamic-component>

<div class="ui container padding-bottom-75 content-background very padded basic segment">
    <div class="ui attached top">
        <div class="ui grid divided">
            <div class="ui row">
                <div class="four wide column">
                    <div class="ui icon input fluid">
                        <input type="text"
                               placeholder="İş Emri Numarası adı ya da Parça Adı..."
                               [formControl]="searchBarCtrl"
                               [value]="pagination.keyword"
                               #searchBarInput
                        >
                        <i class="search icon"
                           *ngIf="paginateSubscription.closed && (searchBarInput.value.length == 0)"></i>
                        <i class="refresh loading icon"
                           *ngIf="!paginateSubscription.closed"></i>
                        <i class="circular remove link icon"
                           *ngIf="paginateSubscription.closed && (searchBarInput.value.length > 0)"
                           (click)="resetSearchValue()"></i>

                    </div>
                </div>

                <div class="three wide column">
                    <div class="ui dropdown basic button fluid" fuiDropdown [(isOpen)]="isSupplierDropdownOpen">
                        <div class="text truncate">
                            {{pagination.filter.supplier ? (pagination.filter.supplier.title | slice: 0:7) :
                            'Tedarikçi'}}
                        </div>
                        <i class="dropdown icon"></i>
                        <div class="menu" fuiDropdownMenu>
                            <div class="padding-15">
                                <button class="ui button basic red fluid" (click)="filterBySupplier(null);">
                                    Filtreyi Kaldır
                                    <i class="remove icon"></i>
                                </button>
                            </div>
                            <div class="padding-15">
                                <fui-search
                                        class="ui fluid input"
                                        placeholder="Yazmaya başlayın..."
                                        [optionsLookup]="searchSuppliers"
                                        optionsField="title"
                                        (resultSelected)="filterBySupplier($event)"
                                        [retainSelectedResult]="false"
                                >
                                </fui-search>
                            </div>
                        </div>
                    </div>
                </div>

                <!--<div class="three wide column">-->
                <!--<div class="ui dropdown basic button fluid" fuiDropdown [(isOpen)]="iStatusDropdownOpen">-->
                <!--<div class="text truncate">-->
                <!--<div class="status-dropdown-color-box margin-right-5"-->
                <!--*ngIf="pagination.filter.status"-->
                <!--[style.background-color]="pagination.filter.status.color"></div>-->
                <!--{{pagination.filter.status ? (pagination.filter.status.name | slice: 0:4) :-->
                <!--'Durum'}}-->
                <!--</div>-->
                <!--<i class="dropdown icon"></i>-->
                <!--<div class="menu" fuiDropdownMenu>-->
                <!--<div class="padding-15">-->
                <!--<button class="ui button basic red fluid" (click)="filterByStatus(null);">-->
                <!--Filtreyi Kaldır-->
                <!--<i class="remove icon"></i>-->
                <!--</button>-->
                <!--</div>-->
                <!--<div class="item" *ngFor="let status of workOrderStatusList"-->
                <!--(click)="filterByStatus(status)">-->
                <!--<div class="status-dropdown-color-box margin-right-20"-->
                <!--[style.background-color]="status.color"></div>-->
                <!--{{status.name}}-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->


                <div class="two wide column">
                    <div class="ui dropdown basic button fluid" fuiDropdown>
                        Sırala
                        <i class="dropdown icon"></i>
                        <div class="menu" fuiDropdownMenu>
                            <div class="item" *ngFor="let orderType of orderList" (click)="orderBy(orderType)"
                                 [ngClass]="{'active': pagination.order === orderType}">
                                {{orderType.name}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="four wide column">
                    <app-pagination [pagination]="pagination" (pageChange)="onPageChange()"></app-pagination>
                </div>
            </div>
        </div>

    </div>
    <div class="ui basic segment " style="min-height: 500px">
        <fui-dimmer class="inverted" [(isDimmed)]="isPaginationLoading" [isClickable]="false">
            <div class="ui text loader">Yükleniyor</div>
        </fui-dimmer>
        <ng-container *ngIf="purchaseOrderList.length === 0 && !isPaginationLoading">
            <div class="ui grid centered margin-top-75 margin-bottom-75">
                <h2 class="ui icon header grey">
                    <i class="warning circle icon"></i>
                    <div class="content">
                        Kayıt Bulunamadı
                        <div class="sub header margin-top-50">
                            Filtreleri değiştirerek yeniden deneyiniz.
                        </div>
                    </div>
                </h2>
            </div>
        </ng-container>
        <div class="ui accordion fluid" #orderAccordion>
            <ng-container *ngFor="let order of purchaseOrderList">
                <div class="title padding-bottom-30">
                    <div class="margin-bottom-15">
                        <a class="ui ribbon label" [ngClass]="order.shippable_status_name.cssClass"
                        >
                            {{order.shippable_status_name.display_name}}

                        </a>

                        <span class="proforma-id" *ngIf="order.work_order_operation"
                              (click)="searchByWorkOrderID(order.work_order_operation.work_order_id)">
                            İş Emri #{{order.work_order_operation.work_order_id}} | {{order.work_order_operation.work_order_operation_type.name}}
                        </span>
                        <div class="ui icon buttons right floated">
                            <button class="ui button basic red" (click)="createPDF(order)">
                                <i class="icon file pdf outline"></i>
                                PDF
                            </button>
                            <div class="ui basic green button dropdown" fuiDropdown>
                                <div class="text">
                                    <i class="in truck flipped icon"></i> Sevkiyat
                                </div>
                                <div class="menu" fuiDropdownMenu>
                                    <div class="item" (click)="openShipmentHistory(order)">
                                        <i class="unhide icon"></i>
                                        Kayıtları Gör
                                    </div>
                                    <div class="item" (click)="openShipmentHistory(order, true, null)">
                                        <i class="add icon"></i>
                                        Yeni Kayıt Ekle
                                    </div>
                                </div>
                            </div>
                            <div class="ui button basic grey dropdown" fuiDropdown>
                                <i class="ellipsis vertical icon"></i>
                                <div class="menu" fuiDropdownMenu>
                                    <div class="item"
                                         (click)="openPurchaseOrder(order)">
                                        <i class="edit icon"></i>
                                        Düzenle
                                    </div>
                                    <div class="item error"
                                         (click)="deletePurchaseOrder(order)">
                                        <i class="remove icon"></i>
                                        Sil
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="ui grid clearing padding-top-15 padding-bottom-20">
                        <div class="nine wide column">
                            <div class="order-id">
                                #{{order.id}}
                                <span class="order-customer" *ngIf="order.supplier.id">
                                    {{order.supplier.title}}
                                </span>
                                <div class="ui label basic red tiny" *ngIf="!order.supplier.id">
                                    <i class="icon warning"></i>
                                    Tedarikçi Eklenmemiş
                                </div>
                            </div>

                            <div class="order-part-name">
                                {{order.name}}
                            </div>
                            <div class="order-part-number">
                                {{order.quantity}} {{order.unit == 'kg' ? 'kg' : (order.unit == 'pcs' ? 'adet' : '')}}
                            </div>
                            <div class="order-part-number">
                                {{order.work_order_operation?.work_order_operation_type?.name}}
                            </div>
                        </div>

                        <div class="three wide column right floated">
                            <div class="padding-5">
                                <div class="order-detail-label">Sevkiyat Termin Tarihi</div>
                                <div>
                                    {{order.shipment_deadline | date: 'dd.MM.yyyy'}}
                                </div>
                            </div>
                            <div class="padding-5 margin-top-5">
                                <div class="order-detail-label">Termin Tarihi</div>
                                <div>
                                    {{order.deadline | date: 'dd.MM.yyyy'}}
                                </div>
                            </div>
                        </div>
                        <div class="one wide column accordion-handle">
                            <i class="chevron down icon"></i>
                        </div>
                    </div>
                    <div class="ui grid  equal width">
                        <div class="column">
                            <fui-progress class="indicating active small"
                                          [value]="order.incoming_percentage">
                                <i class="industry icon large grey"></i>
                                <i class="angle double icon left"></i>
                                <i class="truck icon large grey"></i>
                                Gelen Sevkiyatlar
                            </fui-progress>
                        </div>
                        <div class="column" *ngIf="!(order.purchase_order_type === 'material' || order.purchase_order_type === 'consumable')">
                            <fui-progress class="indicating active small"
                                          [value]="order.outgoing_percentage">
                                <i class="truck icon margin-right-5"></i>
                                <i class="industry icon large grey"></i>
                                <i class="angle double icon left"></i>
                                <i class="truck icon large grey flipped"></i>
                                Giden Sevkiyatlar
                            </fui-progress>
                        </div>
                    </div>

                </div>
                <div class="content">
                    <div class="content-inner">
                        <div class="ui large header grey margin-top-20 margin-bottom-20">Açıklama</div>
                        <div class="ui segment ver padded">
                            {{order.description}}
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="ui grid">
        <div class="four wide column right floated">
            <app-pagination [pagination]="pagination" (pageChange)="onPageChange()"></app-pagination>
        </div>
    </div>
</div>


<!--MODALS-->

<div class="ui small basic modal" #confirmModal>
    <div class="ui icon header">
        <i class="remove from calendar icon"></i>
        Satınalma Siparişini Sil
    </div>
    <div class="content" style="text-align: center !important">
        Bu işlem geri alınamaz. Siparişi silmek istediğinize emin misiniz?
    </div>
    <div class="actions">
        <div class="ui red basic cancel inverted button">
            <i class="remove icon"></i>
            Vazgeç
        </div>
        <div class="ui green ok inverted button">
            <i class="checkmark icon"></i>
            Devam Et
        </div>
    </div>
</div>


<div class="ui small basic modal" id="cannotCreatePdfModal">
    <div class="ui icon header">
        <i class="file pdf outline icon"></i>
        PDF Oluşturulamıyor
    </div>
    <div class="content" style="text-align: center !important">
        Siparişin tedarikçisi belirtilmediği için PDF oluşturulamıyor. Tedarikçiyi ekleyip yeniden deneyin.
    </div>
    <div class="actions">
        <div class="ui green ok inverted button">
            <i class="checkmark icon"></i>
            Tamam
        </div>
    </div>
</div>


<div class="ui small basic modal" id="cannotAddShipmentModal">
    <div class="ui icon header">
        <i class="truck flipped icon"></i>
        Sevkiyat Eklenemiyor
    </div>
    <div class="content" style="text-align: center !important">
        Siparişin tedarikçisi belirtilmediği için sevkiyat eklenemiyor. Tedarikçiyi ekleyip yeniden deneyin.
    </div>
    <div class="actions">
        <div class="ui green ok inverted button">
            <i class="checkmark icon"></i>
            Tamam
        </div>
    </div>
</div>
