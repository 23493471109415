import {BaseModel} from './base.model';
import {UserRole} from './user-role.model';
import {EloquentSerializable} from '../interfaces/interfaces';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HolosenValidators} from '../validators/validators';
import {notEmpty} from '../utils/utils';

export class User extends BaseModel implements EloquentSerializable<User> {


    public static readonly API_PREFIX: string = 'users';

    public id: number = null;
    public name = '';
    public email = '';
    public username = '';
    public roles: Array<UserRole> = [];
    public is_first_login = false;

    public has_open_start_stop_log = true;

    public validation: Array<any>;


    form_group = new FormGroup({
        name: new FormControl(
            this.name,
            Validators.compose([
                Validators.required
            ])
        ),
        email: new FormControl(
            this.email,
            Validators.compose([
                Validators.required
            ])
        ),
        username: new FormControl(
            this.username,
            Validators.compose([
                Validators.required,
            ])
        )
    });


    public getApiPrefix(): string {
        return User.API_PREFIX;
    }


    deserialize(input: Object): User {
        this.id = input['id'];
        this.name = input['name'];
        this.username = input['username'];
        this.email = input['email'];
        this.has_open_start_stop_log = input['has_open_start_stop_log'];
        this.is_first_login = input['is_first_login'];

        this.roles = [];
        if (notEmpty(input['roles'])) {
            for (let i = 0; i < input['roles'].length; i++) {
                this.roles.push(new UserRole().deserialize(input['roles'][i]));
            }
        }


        return this;
    }


}
