import {EloquentSerializable} from '../interfaces/interfaces';
import {notEmpty} from '../utils/utils';
export class RequiredDocuments implements EloquentSerializable<RequiredDocuments> {
    public document_list: Array<any> = [
        {
            name: 'Sertlik Raporu',
            required: false
        },
        {
            name: 'Pürüzlülük Raporu',
            required: false
        },
        {
            name: 'Malzeme Sertifikası',
            required: false
        },
        {
            name: 'Malzeme Analiz Raporu',
            required: false
        },
    ];

    public other = '';


    deserialize(input: JSON): RequiredDocuments {
        if (notEmpty(input)) {
            this.document_list = input['document_list'];
            this.other = input['other'];
        }
        return this;
    }
}
