import {EloquentSerializable} from '../interfaces/interfaces';
import {User} from './user.model';
import {QualityControlGroup} from './quality-control-group.model';
import {HolosenValidators} from '../validators/validators';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BaseModel} from './base.model';
import {notEmpty} from '../utils/utils';

export class QualityControl extends BaseModel implements EloquentSerializable<QualityControl> {


  public static readonly API_PREFIX: string = 'quality-controls';

  public id = null;
  public created_at = new Date();
  public section = '';
  public measurement = null;
  public tolerance = 0.1;
  public tolerance_upper = 0.1;
  public tolerance_lower = -0.1;
  public dimension = null;
  public measured_at = new Date();
  public measured_by = null;
  public measured_with = null;
  public is_out_of_tolerance = false;


  form_group = new FormGroup({
    measurement: new FormControl(
      this.measurement,
      Validators.compose([
        HolosenValidators.requiredNumber
      ])
    ),
    measured_with: new FormControl(
      this.measured_with,
      Validators.compose([
        // Validators.required
      ])
    )
  });


  deserialize(input: JSON): QualityControl {
    if (notEmpty(input)) {
      this.id = input['id'];
      this.created_at = new Date(input['created_at']);
      this.section = input['section'];
      this.measurement = input['measurement'];

      if (notEmpty(input['tolerance'])) {
        this.tolerance = input['tolerance'];
      }

      if (notEmpty(input['tolerance_upper'])) {
        this.tolerance_upper = input['tolerance_upper'];
      }

      if (notEmpty(input['tolerance_lower'])) {
        this.tolerance_lower = input['tolerance_lower'];
      }

      this.dimension = input['dimension'];
      this.measured_with = input['measured_with'];
      this.is_out_of_tolerance = input['is_out_of_tolerance'];
      this.measured_at = new Date(input['measured_at']);
      this.measured_by = input['measured_by'];

    }
    return this;
  }
}
