import {NgModule} from '@angular/core';
import {SharedImportsModule} from '../modules/shared-imports.module';
import {FilterPipe} from './filter.pipe';
import {InArrayPipe} from './in-array.pipe';
import {SafePipe} from './safe.pipe';

@NgModule({
    imports: [
    ],
    declarations: [
        FilterPipe,
        InArrayPipe,
        SafePipe
    ],
    exports: [
        FilterPipe,
        InArrayPipe,
        SafePipe
    ]
})
export class PipesModule {
}
