import {AuthenticationService} from './authentication/authentication.service';
import {ConfigService} from './config/config.service';
import {AuthHttpService} from './http/auth-http.service';
import {AuthHttpInterceptor} from './http/auth-http.interceptor';
import {LayoutService} from './layout/layout.service';
import {StorageService} from './storage/storage.service';
import {ToastService} from './toast/toast.service';
import {WebSocketService} from './web-socket/web-socket.service';


export const sharedServices = [
    AuthenticationService,
    ConfigService,
    StorageService,
    AuthHttpService,
    AuthHttpInterceptor,
    LayoutService,
    ToastService,
    WebSocketService
];


