import {AfterViewInit, Component} from '@angular/core';
// import {FuiLocalizationService} from '@magoniac/ngx-fomantic-ui';
// import {tr} from '../assets/semantic-ui/locales/tr';
declare let $: any;

/*
Harun Yılmaz
 */

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
    title = 'app';

    constructor(
      // public fuiLocalizationService: FuiLocalizationService
    ) {
        // Load the Spanish translations into the Localization Service.
        // fuiLocalizationService.load('tr', tr);
        // Set the current language to Spanish.
        // fuiLocalizationService.setLanguage('tr');


        /*
        Prevent Drop Events
         */
        window.addEventListener('dragover', function (e) {
            e.preventDefault();
            e.stopPropagation();
        }, false);
        window.addEventListener('drop', function (e) {
            e.preventDefault();
            e.stopPropagation();
        }, false);


        /* if(isDevMode()){

             window.console.log = function() {};
             window.console.error = function() {};
         }*/


    }

    ngAfterViewInit(): void {
    }


}
