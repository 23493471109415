import {PlanningComponent} from './planning.component';
import {AuthenticationService} from '../../../shared/services/authentication/authentication.service';


export const PlanningRoutes = [
    {
        path: 'planning',
        component: PlanningComponent,
        data: {
            roles: [
                AuthenticationService.ROLES.ADMIN,
                AuthenticationService.ROLES.PLANNER
            ]
        }
    }
];