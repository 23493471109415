import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ToastService} from '../../../shared/services/toast/toast.service';
import {EloquentManagerService} from '../../../shared/eloquent/providers/eloquent-manager.provider';
import {ConfigService} from '../../../shared/services/config/config.service';
import {AuthHttpService} from '../../../shared/services/http/auth-http.service';
import {saveAs} from 'file-saver';
import {LayoutService} from '../../../shared/services/layout/layout.service';
import {Customer} from '../../../shared/eloquent/models/customer.model';
import {FormControl} from '@angular/forms';
import {Pagination, PAGINATION_ORDER} from '../../../shared/eloquent/models/query/pagination.model';
import {Subscription} from 'rxjs';
import {WorkOrderStatus} from '../../../shared/eloquent/models/work-order-status.model';
import {WorkOrder} from '../../../shared/eloquent/models/work-order.model';
import {debounceTime} from 'rxjs/operators';
import {StorageService} from '../../../shared/services/storage/storage.service';
import {WorkOrderOperation} from '../../../shared/eloquent/models/work-order-operation.model';
import {StartStopLog} from '../../../shared/eloquent/models/start-stop-log.model';
import {
  DynamicComponentComponent,
  DynamicComponentType
} from '../../../shared/components/dynamic-component/dynamic-component.component';
import {QualityControlComponent} from '../../../shared/components/quality-control/quality-control.component';
import {StartStopLogHistoryComponent} from '../../../shared/components/start-stop-log-history/start-stop-log-history.component';

declare const $: any;

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss']
})
export class ReportingComponent implements OnInit {


  pageTitle = 'Raporlama';

  qcFilter = {
    mode: 'date-range',
    modes: [
      {
        name: 'Tarih Aralığı',
        value: 'date-range'
      },
      {
        name: 'Müşteri ve Tarih Aralığı',
        value: 'customer'
      },
      {
        name: 'Tekil İş Emri',
        value: 'single'
      }
    ],
    dates: {
      start: new Date().setDate(new Date().getTime() - (60 * 60 * 24 * 7 * 1000)),
      end: new Date()
    },
    customer: null,
    work_order_id: null
  };
  isCustomerChanging = false;


  workOrderList: Array<WorkOrder> = [];
  pagination: Pagination = new Pagination();
  paginateSubscription: Subscription;
  searchBarCtrl: FormControl;
  isCustomerDropdownOpen = false;
  iStatusDropdownOpen = false;
  isPaginationLoading = true;
  orderList: Array<any> = [
    {
      name: 'Termin Tarihi (Azalan)',
      field: 'DEADLINE',
      value: PAGINATION_ORDER.DESCENDING
    },
    {
      name: 'Termin Tarihi (Artan)',
      field: 'DEADLINE',
      value: PAGINATION_ORDER.ASCENDING
    },
    {
      name: 'Giriş Tarihi (Azalan)',
      field: 'ENTRY_DATETIME',
      value: PAGINATION_ORDER.DESCENDING
    },
    {
      name: 'Giriş Tarihi (Artan)',
      field: 'ENTRY_DATETIME',
      value: PAGINATION_ORDER.ASCENDING
    }

  ];

  @ViewChild('operationAccordion', {static: false})
  operationAccordion: ElementRef;

  filteredSum = 0;


  averageTimeRange = 90;

  averageTimes = {
    users: [],
    work_stations: []
  };


  @ViewChild('dynamicComponent', {static: false})
  dynamicComponent: DynamicComponentComponent;

  constructor(private toastService: ToastService,
              private eloquentManager: EloquentManagerService,
              private configService: ConfigService,
              private authHttp: AuthHttpService,
              private layoutService: LayoutService,
              private storageService: StorageService) {
    this.layoutService.setTitle(this.pageTitle);
  }

  ngOnInit() {
    this.pagination.order = this.orderList[0];
    setTimeout(this.paginateWorkOrderList, 100);
    this.registerSearchValueChanges();
    this.getAverageTimes();
  }


  initAccordion = () => {
    if (this.operationAccordion) {

      $(this.operationAccordion.nativeElement).accordion(
        {
          selector: {
            trigger: '.title .accordion-handle'
          },
          onOpening: function () {
            console.log('onOpening');
          },
          onOpen: (event) => {
            const openIndex = $('.ui.accordion .title.active');

            $('html,body').animate({
                scrollTop: openIndex.offset().top - 75
              },
              450);
          }
        }
      );
    }
  };


  createQualityControlReport = () => {


    const creatingToast = this.toastService.processing();


    this.authHttp.authPost(this.configService.get('apiBaseUrl') + 'reporting/quality-control', this.qcFilter, {
      responseType: 'blob'
    }).subscribe((response: any) => {
        const blob = new Blob([response], {type: 'application/pdf'});
        const filename = 'Deneme';
        saveAs(blob, filename);
        this.toastService.processed(creatingToast);
      },
      (err) => {
        this.toastService.saveFailed(creatingToast);
        console.log(err);
      });
  };


  resetSearchValue = () => {
    this.searchBarCtrl.reset();
  };


  searchCustomers = (query) => {
    return new Promise((resolve, reject) => {
      this.eloquentManager.request('POST', this.configService.get('apiBaseUrl') + 'customers/get-all-for-autocomplete', {keyword: query})
        .subscribe((response: any) => {
          if (response) {
            const modelList = [];
            const responseArray = response;
            for (let i = 0; i < responseArray.length; i++) {
              modelList.push(new Customer().deserialize(responseArray[i]));
            }
            resolve(modelList);
          }
          resolve([]);
        });
    });
  };


  customerSelected(customer: Customer) {
    this.qcFilter.customer = customer;
    this.isCustomerChanging = false;
  }

  changeCustomer = (value) => {
    this.isCustomerChanging = value;
  };


  filterByStatus(status: WorkOrderStatus) {
    this.iStatusDropdownOpen = false;
    this.pagination.filter.status = status;
    this.pagination.pageIndex = 0;
    this.paginateWorkOrderList();

  }

  filterByCustomer(customer: Customer) {
    this.isCustomerDropdownOpen = false;
    this.pagination.filter.customer = customer;
    this.pagination.pageIndex = 0;
    this.paginateWorkOrderList();
  }


  orderBy(order: any) {
    this.pagination.order = order;
    this.paginateWorkOrderList();
  }


  paginateWorkOrderList = () => {
    console.log(this.pagination);

    if (this.paginateSubscription) {
      if (!this.paginateSubscription.closed) {
        this.paginateSubscription.unsubscribe();
      }
    }
    this.isPaginationLoading = true;
    this.storageService.set('reporting-pagination', this.pagination);
    this.paginateSubscription = this.eloquentManager.request('POST', this.configService.get('apiBaseUrl') + 'reporting/actual-times', {pagination: this.pagination}).subscribe((response) => {
      if (response) {
        const modelList = [];
        const responseArray = response.pagination.data;

        for (let i = 0; i < responseArray.length; i++) {
          modelList.push(new WorkOrder().deserialize(responseArray[i]));
        }


        this.workOrderList = modelList;
        this.pagination.length = response.pagination.total;
        console.log(this.workOrderList);
        $('html, body').animate({scrollTop: 0}, 'slow');
        this.filteredSum = response.filteredSum;
        setTimeout(this.initAccordion, 50);
      } else {
        this.toastService.error('Hata Oluştu', 'İş emirleri bilinmeyen bir nedenle yüklenemedi. Lütfen tekrar deneyiniz');
      }

      this.isPaginationLoading = false;
    });
  };


  onPageChange = () => {
    this.paginateWorkOrderList();
  };


  registerSearchValueChanges() {
    this.searchBarCtrl = new FormControl({
      updateOn: 'blur', // default will be change
    });

    this.searchBarCtrl.valueChanges.pipe(
      debounceTime(500)
    ).subscribe((keyword: string) => {

      this.pagination.keyword = (keyword && keyword.length === 0) ? null : keyword;
      this.pagination.pageIndex = 0;
      this.paginateWorkOrderList();
    });
  }


  getStartStopLogsByOperation = (operation: WorkOrderOperation) => {
    const component: StartStopLogHistoryComponent = (<StartStopLogHistoryComponent>this.dynamicComponent.create(StartStopLogHistoryComponent, DynamicComponentType.SIDEBAR_RIGHT));
    component.operation = operation;
  };

  isAverageLoading = true;

  getAverageTimes = () => {
    console.log('averageTimeRange', this.averageTimeRange);
    this.isAverageLoading = true;
    this.eloquentManager.request('GET', this.configService.get('apiBaseUrl') + 'reporting/average-times/' + this.averageTimeRange).subscribe((response) => {

      if (response) {
        this.averageTimes = response;
        console.log(this.averageTimes);
      } else {
        this.toastService.error('Hata Oluştu', 'Ortalama Süreler bilinmeyen bir nedenle yüklenemedi. Lütfen tekrar deneyiniz');
      }

      this.isAverageLoading = false;

    });
  };

}
