import {ChangeDetectorRef, Component, Input, OnInit, Output} from '@angular/core';
import {WorkOrderOperation} from '../../eloquent/models/work-order-operation.model';
import {EloquentManagerService} from '../../eloquent/providers/eloquent-manager.provider';
import {ConfigService} from '../../services/config/config.service';
import {Log} from '../../eloquent/models/log.model';
import {Customer} from '../../eloquent/models/customer.model';
import {ValidationErrors} from '@angular/forms';
import {ToastService} from '../../services/toast/toast.service';
import {WorkOrder} from '../../eloquent/models/work-order.model';

@Component({
    selector: 'app-log-history',
    templateUrl: './log-history.component.html',
    styleUrls: ['./log-history.component.scss']
})
export class LogHistoryComponent implements OnInit {

    static readonly LIST_LOG_HISTORY = 'list-log-history';
    static readonly ADD_LOG_HISTORY = 'add-log-history';

    readonly LIST_LOG_HISTORY = LogHistoryComponent.LIST_LOG_HISTORY;
    readonly ADD_LOG_HISTORY = LogHistoryComponent.ADD_LOG_HISTORY;

    @Input('operation') operation: WorkOrderOperation;
    @Input('title') title = 'Günlük Kayıtları';
    @Input('action') action = this.LIST_LOG_HISTORY;


    logList: Array<Log>;
    newLog: Log;
    isLogsLoading = true;

    @Output('close') close = () => {
        return false;
    };

    @Output('onLogSaved') onLogSaved = (): any => {
        return;
    };

    constructor(private eloquentManager: EloquentManagerService,
                private configService: ConfigService,
                private toastService: ToastService) {
    }

    ngOnInit() {


        switch (this.action) {
            case this.ADD_LOG_HISTORY: {
                this.isLogsLoading = false;
                this.newLog = new Log();

                break;
            }
            default:
            case this.LIST_LOG_HISTORY: {
                this.isLogsLoading = true;
                this.eloquentManager.request('GET', this.configService.get('apiBaseUrl') + this.operation.getApiPrefix() + '/' + this.operation.id + '/get-logs').subscribe((response) => {
                    if (response) {
                        const modelList = [];
                        const responseArray = response;
                        for (let i = 0; i < responseArray.length; i++) {
                            modelList.push(new Log().deserialize(responseArray[i]));
                        }
                        this.logList = modelList;
                    }

                    this.isLogsLoading = false;
                });
                break;
            }

        }
    }

    saveLog = () => {
        console.log('saveLog');
        console.log(this.newLog.form_group);
        this.newLog.revalidate();


        if (this.newLog.form_group.invalid) {

            Object.keys(this.newLog.form_group.controls).forEach(key => {

                const controlErrors: ValidationErrors = this.newLog.form_group.get(key).errors;
                if (controlErrors != null) {
                    Object.keys(controlErrors).forEach(keyError => {
                        console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                    });
                }
            });


            return false;
        }

        const savingToast = this.toastService.saving();

        this.eloquentManager.request('POST', `${this.configService.get('apiBaseUrl') + this.operation.getApiPrefix()}/${this.operation.id}/add-log`, this.newLog).subscribe(response => {
            if (response) {
                this.toastService.saved(savingToast);
                this.onLogSaved();
                this.close();
            }
        });
    };

}
